<template>
  <site-section class="news-tovar-podpiska" v-if="certs && certs.length">
    <template v-slot:description>
      <h2>Подарочные сертификаты</h2>
      <p class="pre-flex-header">
        Порадуйте коллег и близких возможностью узнать что-то новое
      </p>
    </template>
    <template v-slot:content>
      <gifts
        v-for="i in Math.ceil(certs.length / 2)"
        v-bind:key="i"
        :data="certs.slice((i - 1) * 2, i * 2)"
      />
    </template>
  </site-section>
</template>

<script>
import SiteSection from "@/components/page/SiteSection";
import Gifts from "@/components/page/certs/gift/Gifts";
export default {
  name: "GiftBlock",
  components: { SiteSection, Gifts },
  props: {
    certs: Array
  }
  // ,data() {
  //   return {
  //     certs: [
  //       {
  //         id: 0,
  //         name: "сертификат",
  //         description: "Более +1000 видео контента",
  //         length: "1 МЕСЯЦ",
  //         list: [
  //           "Статьи и видео по бизнес тематике",
  //           "Лучшее качество записи",
  //           "Просмотр на любом устройстве",
  //           "Отличный подарок"
  //         ],
  //         cover: 1,
  //         price: 990
  //       },
  //       {
  //         id: 1,
  //         name: "сертификат",
  //         description: "Более +1000 видео контента",
  //         length: "3 МЕСЯЦА",
  //         list: [
  //           "Статьи и видео по бизнес тематике",
  //           "Лучшее качество записи",
  //           "Просмотр на любом устройстве",
  //           "Отличный подарок"
  //         ],
  //         cover: 2,
  //         price2: "МЕСЯЦ ЗА 849 ₽",
  //         price: 1999
  //       },
  //       {
  //         id: 2,
  //         name: "сертификат",
  //         description: "Более 1000 видео",
  //         length: "6 МЕСЯЦЕВ",
  //         list: [
  //           "Статьи и видео по бизнес тематике",
  //           "Лучшее качество записи",
  //           "Просмотр на любом устройстве",
  //           "Отличный подарок"
  //         ],
  //         cover: 3,
  //         price2: "Стоимость 1 месяца – 1490 ₽ вместо 1990 ₽",
  //         price: "8950 (11940 - 50%)"
  //       },
  //       {
  //         id: 3,
  //         name: "сертификат",
  //         description: "Более +1000 видео контента",
  //         length: "1 ГОД",
  //         list: [
  //           "Статьи и видео по бизнес тематике",
  //           "Лучшее качество записи",
  //           "Просмотр на любом устройстве",
  //           "Отличный подарок"
  //         ],
  //         cover: 4,
  //         price2: "МЕСЯЦ ЗА 449 ₽",
  //         price: 5999
  //       }
  //     ]
  //   };
  // }
};
</script>

<style scoped></style>
