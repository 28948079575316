<template>
  <router-link :to="path" v-slot="{ href, route, navigate, isActive }">
    <li :class="{ active: isActive, menu: true }">
      <a :href="href" @click="navigate" class="dropdown-toggle">
        <div>
          <slot name="icon"></slot>
          <span v-html="name"></span>
        </div>
      </a>
    </li>
  </router-link>
</template>

<script>
export default {
  name: "SidebarLink",
  props: {
    path: {
      type: [String, Object]
    },
    name: String
  }
};
</script>

<style scoped>
#sidebar ul.menu-categories li.menu.active > .dropdown-toggle {
  color: #fff !important;
}
</style>
