import { render, staticRenderFns } from "./SearchItems.vue?vue&type=template&id=42902bae&scoped=true&"
import script from "./SearchItems.vue?vue&type=script&lang=js&"
export * from "./SearchItems.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../../../../usr/local/lib/node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42902bae",
  null
  
)

export default component.exports