<template>
  <div class="row">
    <div class="col-xl-12 col-lg-12 col-sm-12 w100 layout-spacing">
      <div class="widget-content widget-content-area">
        <h2>Промокоды</h2>
        <table-view
          :heads="heads"
          :items.sync="promos"
          :fields="schema"
          edit-head="Редактировать промокод"
          obtain-method="/promo/get"
          update-method="/promo/update"
          delete-method="/promo/delete"
          @updated="getPromos"
          :form-options="formOptions"
        />
      </div>

      <div class="widget-content widget-content-area">
        <h2>Добавить промокод</h2>
        <create-promo
          :schema="schema"
          :form-options="formOptions"
          @create="getPromos"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CreatePromo from "@/views/admin/promo/CreatePromo";
import { mapGetters, mapActions } from "vuex";
import TableView from "@/components/admin/lists/TableView";
import { uuid } from "vue-uuid";
import { validators } from "vue-form-generator";
export default {
  name: "AdminPromo",
  components: { TableView, CreatePromo },
  data() {
    return {
      heads: {
        id: {
          name: "ID"
        },
        code: {
          name: "Код"
        },
        months: {
          name: "Длительность"
        },
        active: {
          name: "Активирован"
        },
        description: {
          name: "Описание"
        },
        percent: {
          name: "Процент"
        }
      },
      schema: {
        groups: [
          {
            fields: [
              {
                type: "input",
                inputType: "text",
                label: "Промокод",
                model: "code",
                required: true,
                validator: validators.required,
                placeholder: "THELAK",
                id: uuid.v1() + "code"
              },
              {
                type: "input",
                inputType: "text",
                label: "Описание",
                model: "description",
                required: true,
                validator: validators.required,
                id: uuid.v1() + "description"
              },
              {
                type: "input",
                inputType: "number",
                label: "Длительность",
                model: "months",
                required: true,
                validator: validators.required,
                placeholder: 3,
                id: uuid.v1() + "months"
              },
              {
                type: "switch",
                label: "Активирован",
                model: "active",
                required: true,
                validator: validators.required,
                multi: true,
                textOn: "Активен",
                default: true,
                textOff: "Неактивен"
              },
              {
                type: "input",
                inputType: "number",
                label: "Процент скидки при оплате",
                model: "percent",
                required: false,
                placeholder: "10",
                hint: "Введите от 1 до 99%",
                step: 1,
                min: 0,
                max: 99
              },
              {
                type: "vueMultiSelect",
                model: "emails",
                label: "Эл. почты",
                placeholder: "Введите новый Email",
                required: false,
                selectOptions: {
                  multiple: true,
                  taggable: true,
                  clearOnSelect: false,
                  closeOnSelect: false,
                  tagPlaceholder: "Добавьте этот email",
                  onNewTag(newTag, id, options, value) {
                    options.push(newTag);
                    value.push(newTag);
                  }
                },
                onChanged(model, newVal) {
                  console.log("@tag: ", newVal);
                },
                values: []
              }
            ]
          }
        ]
      },
      formOptions: {
        validateAfterLoad: false,
        validateAfterChanged: true,
        validateAsync: true
      }
    };
  },
  computed: {
    ...mapGetters(["promos"])
  },
  methods: {
    ...mapActions(["getPromos"])
  },
  created() {
    this.getPromos();
  }
};
</script>

<style scoped></style>
