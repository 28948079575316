<template>
  <state-item item-class="news-tovar-items-inner">
    <router-link
      :style="{
        background: 'url(' + item.coverUrl + ')'
      }"
      :to="{ name: 'SingleEvent', params: { id: item.id } }"
      class="tovar-items-img video-img"
    ></router-link>
    <tags :tags="item.categories" />
    <h4>
      <router-link :to="{ name: 'SingleEvent', params: { id: item.id } }">
        {{ item.title }}
      </router-link>
    </h4>
    <span
      class="nti-d"
      v-html="item.description"
      v-if="item.description"
    ></span>
    <div class="tovar-items-status" v-if="item.startDate || item.endDate">
      <span class="items-status-date">
        <i class="far fa-calendar"></i>
      </span>
      <span class="items-status-text">
        {{ item.startDate | moment("DD.MM.YY hh:mm") }}
      </span>
      <span class="items-status-text" v-if="item.endDate">-</span>
      <span class="items-status-text" v-if="item.endDate">
        {{ item.endDate | moment("DD.MM.YY hh:mm") }}
      </span>
    </div>
  </state-item>
</template>

<script>
import Tags from "@/components/page/items/tags/Tags";
import StateItem from "@/components/page/slick/StateItem";
export default {
  name: "EventItem",
  components: { StateItem, Tags },
  props: {
    item: Object
  }
};
</script>

<style scoped></style>
