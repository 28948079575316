<template>
  <slick
    ref="slick"
    :options="slickOptions"
    v-if="tiles && tiles.length"
    :class="slickClass"
  >
    <state-item
      v-for="tile in tiles"
      v-bind:key="tile.key"
      :item-class="itemClass"
    >
      <video-tile :data="tile" />
    </state-item>
  </slick>
</template>

<script>
import StateItem from "@/components/page/slick/StateItem";
import Slick from "vue-slick";
import VideoTile from "@/components/page/video/VideoTile";
export default {
  name: "Videos",
  components: { StateItem, Slick, VideoTile },
  props: {
    tiles: Array,
    slidesToShow: {
      type: Number,
      default: 4
    },
    slickClass: {
      type: String,
      default: "slider-news-tovar"
    },
    itemClass: {
      type: String,
      default: "news-tovar-items"
    }
  },
  data() {
    return {
      slickOptions: {
        infinite: true,
        dots: false,
        arrows: true,
        slidesToShow: this.slidesToShow,
        slidesToScroll: 1,
        adaptiveHeight: true,
        rows: 0,
        prevArrow:
          "<div class='slick-prev-action'><img src='" +
          this.arrowUrl("right") +
          "' alt='' /></div>",
        nextArrow:
          "<div class='slick-next-action'><img src='" +
          this.arrowUrl("left") +
          "' alt='' /></div>",
        responsive: [
          {
            breakpoint: 760,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 450,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      }
    };
  },
  methods: {
    arrowUrl(arrow) {
      return require("@/assets/img/arrow-" + arrow + ".svg");
    },
    next() {
      this.$refs.slick.next();
    },
    prev() {
      this.$refs.slick.prev();
    },
    reInit() {
      this.$nextTick(() => {
        this.$refs.slick.reSlick();
      });
    }
  }
};
</script>

<style lang="scss">
.slick-list {
  box-sizing: border-box;
}

.slick-track {
  margin-left: 0;
}
</style>
