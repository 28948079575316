<template>
  <fragment>
    <section class="sotrudnichestvo-wrapper">
      <site-header />
      <div class="sertificate-header-wrapper">
        <div class="inner-content">
          <h4>Сотрудничество</h4>
          <p>Thelak поддерживает инфлюенсеров и малый бизнес</p>
          <a href="#" @click.prevent="showPartnership = true">
            Отправить заявку
          </a>
          <img
            class="absolute-img"
            src="@/assets/img/shesternia.svg"
            alt="Cogs"
          />
        </div>
      </div>
    </section>
    <site-section section-class="preem-section">
      <template v-slot:content>
        <h2>Преимущества партнёрства</h2>
        <div class="preem-section-flex">
          <div>
            <span class="img-preem">
              <img src="@/assets/img/open-graph.svg" alt="" />
            </span>
            <h4>Создавайте и продвигайте</h4>
            <p>
              Создавайте свой качественный развивающий контент и продигайте его
              с помощью сервиса THELAK
            </p>
          </div>
          <div>
            <span class="img-preem">
              <img src="@/assets/img/ionic-ios-people.svg" alt="" />
            </span>
            <h4>Монетизируйте</h4>
            <p>
              Монетизируйте свою аудиторию и привлекайте новых клиентов с
              помощью сервиса THELAK
            </p>
          </div>
          <div>
            <span class="img-preem">
              <img src="@/assets/img/awesome-money-bill-wave-alt.svg" alt="" />
            </span>
            <h4>Зарабатывайте деньги!</h4>
            <p>
              Зарабатывайте комиссионные на каждой покупке интеллектуальных
              продуктов, созданных при Вашем участии
            </p>
          </div>
        </div>
      </template>
    </site-section>
    <site-section section-class="sotrudnichestvo-section">
      <template v-slot:content>
        <div class="sotrudnichestvo-flex">
          <div class="sotrudnichestvo-flex-text left">
            <h4>Продвижение сервиса на других ресурсах/offline площадках</h4>
            <p>
              THELAK активно сотрудничает с онлайн-университетами,
              бизнес-клубами и специалистами в различных сферах деятельности.
              Продвигайте и распространяйте то, что всегда востребовано,
              вызывает интерес и никогда не теряет актуальности
            </p>
            <a href="#" @click.prevent="showPartnership = true">
              Оставить заявку
            </a>
          </div>
          <div class="sotrudnichestvo-flex-img right">
            <img src="@/assets/img/landing_page_flatline.svg" alt="" />
          </div>
        </div>
        <div class="sotrudnichestvo-flex">
          <div class="sotrudnichestvo-flex-img left">
            <img src="@/assets/img/marketing_flatline.svg" alt="" />
          </div>
          <div class="sotrudnichestvo-flex-text right">
            <h4>Продвижение сервиса через личные аккаунты</h4>
            <p>
              Если Вы популярный блоггер или лидер мнений, продвигайте
              возможности и контент уникального сервиса THELAK через собственные
              аккаунты в социальных сетях и получайте выгоду
            </p>
            <a href="#" @click.prevent="showPartnership = true">
              Оставить заявку
            </a>
          </div>
        </div>
        <div class="sotrudnichestvo-flex">
          <div class="sotrudnichestvo-flex-text left">
            <h4>Размещение сервисов для бизнеса</h4>
            <p>
              Если Вы представитель бизнеса, который имеет собственные продукты,
              упрощающие жизнь предпринимателям - оставляйте заявку, мы
              договоримся с Вами о взаимовыгодном сотрудничестве
            </p>
            <a href="#" @click.prevent="showPartnership = true">
              Оставить заявку
            </a>
          </div>
          <div class="sotrudnichestvo-flex-img right">
            <img src="@/assets/img/google_ad_flatline.svg" alt="" />
          </div>
        </div>
      </template>
    </site-section>
    <site-section section-class="voprosi-sotr">
      <template v-slot:content>
        <h4>Остались вопросы?<br />Напишите нам:</h4>
        <p>
          <a class="voprosi-sotr-mail" href="mailto:partners@thelak.com">
            <img src="@/assets/img/email.svg" alt="" /> partners@thelak.com
          </a>
        </p>
      </template>
    </site-section>
    <modal-partnership :show-modal.sync="showPartnership" />
    <site-footer />
  </fragment>
</template>

<script>
import SiteHeader from "@/components/page/SiteHeader";
import SiteFooter from "@/components/page/SiteFooter";
import SiteSection from "@/components/page/SiteSection";
import ModalPartnership from "@/components/page/modals/forms/ModalPartnership";
export default {
  name: "Partnership",
  components: { ModalPartnership, SiteSection, SiteFooter, SiteHeader },
  data() {
    return {
      showPartnership: false
    };
  }
};
</script>

<style scoped></style>
