<template>
  <ul class="bottom-footer-menu">
    <li><router-link to="/certs">Сертификаты</router-link></li>
    <li><router-link to="/sub">Подписка</router-link></li>
    <li><router-link to="/partnership">Сотрудничество</router-link></li>
    <!--    <li><router-link to="/services">Сервисы</router-link></li>-->
    <li><router-link to="/faq">Помощь</router-link></li>
    <li><a>Smart-TV</a></li>
  </ul>
</template>

<script>
export default {
  name: "BottomFooterMenu"
};
</script>

<style scoped></style>
