<template>
  <site-section section-class="main-banner">
    <template v-slot:content>
      <div class="flex-banner-main">
        <div class="banner-main-content">
          <h4>
            Уникальный контент
            <br />
            всего за 990 р / месяц <span class="acsecc-rub">₽</span> в месяц
          </h4>
          <div class="banner-main-text">
            Пробный период
            <br />
            на 1 день за 1 р по промокоду:&nbsp;&nbsp;&nbsp;&nbsp;
            <copy-component />
          </div>
        </div>
        <router-link to="/sub" class="link-podpiska">
          Подключить подписку
        </router-link>
        <img class="banner-main-absolute" src="@/assets/img/phone.png" alt="" />
      </div>
    </template>
  </site-section>
</template>

<script>
import SiteSection from "@/components/page/SiteSection";
import CopyComponent from "@/components/page/items/copy/CopyComponent";
export default {
  name: "MainBannerBlock",
  components: { SiteSection, CopyComponent }
};
</script>

<style scoped></style>
