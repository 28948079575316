import util from "@/store/util";
const moduleArticle = {
  state: {
    articles: []
  },
  getters: {
    articles: state => state.articles.map(util.getters.uuidGetter),
    anarchyArticles: state => id =>
      state.articles.filter(x => x.id !== id).map(util.getters.uuidGetter),
    // state.articles.slice(1).map(util.getters.uuidGetter),
    monarchyArticles: state => id =>
      state.articles.filter(x => x.id === id).map(util.getters.uuidGetter)
    // state.articles.slice(0, 1).map(util.getters.uuidGetter)
  },
  mutations: {
    setArticles: util.mutations.setData,
    appendArticles: util.mutations.appendData
  },
  actions: {
    getArticles({ dispatch }, params) {
      return dispatch("getArticlesWithFilter", {
        method: "/article/list",
        type: "articles",
        commitMethod: "appendArticles",
        params
      });
    },
    getArticlesWithFilter({ commit }, data) {
      if (!data.commitMethod) {
        data.commitMethod = "setArticles";
      }
      return util.methods.getFilteredObjects(commit, data, {
        flushMethod: "setArticles"
      });
    }
  }
};

export default moduleArticle;
