import { render, staticRenderFns } from "./CreateEvent.vue?vue&type=template&id=998d1384&scoped=true&"
import script from "./CreateEvent.vue?vue&type=script&lang=js&"
export * from "./CreateEvent.vue?vue&type=script&lang=js&"
import style0 from "./CreateEvent.vue?vue&type=style&index=0&id=998d1384&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../../usr/local/lib/node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "998d1384",
  null
  
)

export default component.exports