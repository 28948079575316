<template>
  <div :class="classBinding">
    <div class="ballance-bottom-flex">
      <div class="ballance-bottom-flex-left">
        <img src="@/assets/img/shield.svg" alt="guard" />
        <span>Гарантия безопасности</span>
      </div>
      <div class="ballance-bottom-flex-right">
        <slot name="rightBottomFlex"></slot>
      </div>
    </div>
    <input
      class="ballance-bottom-submit"
      type="submit"
      :value="goodNameText"
      v-if="showSubmitCondition"
    />
    <input
      class="ballance-bottom-submit"
      type="submit"
      :value="paymentSystemLoadSubmitText"
      disabled
      v-else
    />
    <p v-if="paymentSystem">
      Платеж будет проведен через сервисы {{ paymentSystem }} и Cloud Payments в
      соответствии с международными стандартами безопасности платежей.
    </p>
    <p v-else>
      Платеж будет проведен через сервис Cloud Payments в соответствии с
      международными стандартами безопасности платежей.
    </p>
  </div>
</template>

<script>
export default {
  name: "SubmitField",
  props: {
    classBinding: {
      type: [String, Object, Array],
      default: "payment-bottom-content"
    },
    paymentSystem: {
      type: String,
      default: null
    },
    goodName: {
      type: String,
      default: ""
    },
    showSubmitCondition: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    paymentSystemLoadSubmitText() {
      return `Загрузка ${this.paymentSystem}...`;
    },
    goodNameText() {
      return `Купить ${this.goodName.toLowerCase()}`;
    }
  }
};
</script>

<style scoped></style>
