import qs from "qs";
import Vue from "vue";
import { uuid } from "vue-uuid";

const util = {
  getters: {
    uuidGetter: item => {
      item.key = item.id + uuid.v1();
      return item;
    }
  },
  mutations: {
    setData(state, payload) {
      state[payload.type] = payload.data || [];
    },
    appendData(state, payload) {
      state[payload.type] = state[payload.type].concat(payload.data || []);
    }
  },
  methods: {
    getFilteredObjects(commit, data, settings) {
      if (!data.params) {
        data.params = {};
      }
      if (data.allItems || data.params.page === 1) {
        commit(settings.flushMethod, {
          type: data.type,
          data: []
        });
      }

      if (!data.allItems && !data.params.size) {
        data.params.size = 16;
      }

      return new Promise(resolve => {
        Vue.axios
          .get(data.method, {
            params: data.params,
            paramsSerializer: params => {
              return qs.stringify(params, { arrayFormat: "repeat" });
            }
          })
          .then(response => {
            commit(data.commitMethod, {
              type: data.type,
              data: response.data
            });
            resolve(response.data);
          });
      });
    }
  }
};

export default util;
