<template>
  <site-section section-class="seftificate-numbers-b">
    <template v-slot:content>
      <div class="sertificate-numbers-flex">
        <div class="numbers-flex-elem wow fadeInUp">
          1000+
          <div class="numbers-flex-absolute">
            <h4>1000+</h4>
            <span>часов видеоконтента</span>
          </div>
        </div>
        <div class="numbers-flex-elem wow fadeInUp">
          300+
          <div class="numbers-flex-absolute">
            <h4>300+</h4>
            <span>Спикеров</span>
          </div>
        </div>
        <div class="numbers-flex-elem wow fadeInUp">
          5000+
          <div class="numbers-flex-absolute">
            <h4>5000+</h4>
            <span>бизнес-материалов</span>
          </div>
        </div>
      </div>
    </template>
  </site-section>
</template>

<script>
import SiteSection from "@/components/page/SiteSection";
export default {
  name: "FiguresBlock",
  components: { SiteSection }
};
</script>

<style scoped></style>
