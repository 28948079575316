<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 3508 2480"
  >
    <defs>
      <clipPath id="clip-path">
        <use
          id="Слой_0_копия"
          data-name="Слой 0 копия"
          xlink:href="#image"
          style="mix-blend-mode: hard-light;isolation: isolate"
        />
      </clipPath>
      <clipPath id="clip-Canvas_5">
        <rect width="100%" height="100%" />
      </clipPath>
      <image
        id="image"
        width="100%"
        height="100%"
        :xlink:href="image(certId)"
      />
    </defs>
    <g id="Canvas_5" data-name="Canvas – 5" clip-path="url(#clip-Canvas_5)">
      <rect width="3508" height="2480" fill="rgba(0,0,0,0)" />
      <g id="_1_год" data-name="1 год">
        <g id="фон">
          <g
            id="Слой_0_копия-2"
            data-name="Слой 0 копия"
            style="mix-blend-mode: hard-light;isolation: isolate"
          >
            <use
              id="Слой_0_копия-3"
              data-name="Слой 0 копия"
              href="#image"
              style="mix-blend-mode: hard-light;isolation: isolate"
            />
            <g id="Clip" clip-path="url(#clip-path)"></g>
          </g>
          <g
            id="Прямоугольник_3"
            data-name="Прямоугольник 3"
            transform="translate(99 99)"
            fill="none"
            stroke="#fff"
            stroke-width="7.41"
            opacity="0.451"
            style="mix-blend-mode: soft-light;isolation: isolate"
          >
            <rect width="3310" height="2282" rx="47" stroke="none" />
            <rect
              x="3.705"
              y="3.705"
              width="3302.59"
              height="2274.59"
              rx="43.295"
              fill="none"
            />
          </g>
        </g>
        <g id="поле">
          <g
            id="Прямоугольник_1"
            data-name="Прямоугольник 1"
            transform="translate(1830 1693.57)"
            fill="#5b5b68"
            stroke="#4b4c71"
            stroke-width="4.68"
            stroke-dasharray="18.72 9.36"
            opacity="0.6"
          >
            <rect width="1479" height="588.43" rx="39" stroke="none" />
            <rect
              x="2.34"
              y="2.34"
              width="1474.32"
              height="583.75"
              rx="36.66"
              fill="none"
            />
          </g>
          <text
            id="ПОЛЕ_ДЛЯ_СООБЩЕНИЯ"
            data-name="ПОЛЕ ДЛЯ СООБЩЕНИЯ"
            transform="matrix(1, 0, 0, 1, 2569.001, 2001.224)"
            fill="#fff"
            font-size="48"
            font-family="Roboto-Bold, Roboto"
            font-weight="700"
            letter-spacing="0.05em"
          >
            <tspan x="0" dy="0" text-anchor="middle">
              {{ comment }}
            </tspan>
          </text>
        </g>
        <g id="левый_блок" data-name="левый блок">
          <g id="промокод">
            <rect
              id="Прямоугольник_2"
              data-name="Прямоугольник 2"
              width="40%"
              height="126"
              rx="39"
              transform="translate(250.04 1688)"
              fill="#5b5b68"
              opacity="0.4"
            />
            <text
              id="ПРОМОКОД-2"
              data-name="ПРОМОКОД"
              transform="matrix(1, 0, 0, 1, 950, 1771.224)"
              fill="#fff"
              font-size="60"
              font-family="Roboto-Bold, Roboto"
              font-weight="700"
              letter-spacing="0.05em"
            >
              <tspan
                x="0"
                y="0"
                text-anchor="middle"
                @click.stop.prevent="copyText"
              >
                {{ promo }}
              </tspan>
            </text>
          </g>
        </g>
        <g id="инфа">
          <text
            id="НА_1_МЕСЯЦ"
            data-name="НА 1 МЕСЯЦ"
            transform="matrix(1, 0, 0, 1, 1700.679, 816.524)"
            fill="#fff"
            font-size="110"
            font-family="Roboto-Bold, Roboto"
            font-weight="700"
            letter-spacing="0.05em"
          >
            <tspan x="0" y="0" text-anchor="middle">{{ length }}</tspan>
          </text>
          <g id="Сгруппировать_3" data-name="Сгруппировать 3">
            <rect
              id="Прямоугольник_4"
              data-name="Прямоугольник 4"
              width="1579.59"
              height="148.57"
              rx="36"
              transform="translate(960.56 938.26)"
              fill="#5b5b68"
              opacity="0.4"
            />
            <text
              id="ПОМИДОРОВУ_ЮРИЮ_ЮРЬЕВИЧУ"
              data-name="ПОМИДОРОВУ ЮРИЮ ЮРЬЕВИЧУ"
              transform="matrix(1, 0, 0, 1, 1750.305, 1039.069)"
              fill="#fff"
              font-size="79.167"
              font-family="Roboto-Medium, Roboto"
              font-weight="500"
              letter-spacing="0.08em"
            >
              <tspan x="0" y="0" text-anchor="middle">
                {{ name }}
              </tspan>
            </text>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "horizontal",
  props: {
    length: {
      type: String,
      default: ""
    },
    certId: {
      type: [Number, String],
      default: 1
    },
    whom: {
      type: String,
      default: "ПОМИДОРОВУ ЮРИЮ ЮРЬЕВИЧУ"
    },
    message: {
      type: String,
      default: ""
    },
    promo: {
      type: String,
      default: "ПРОМО"
    },
    newStyle: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    comment() {
      return this.message ? this.message.toUpperCase() : "";
    },
    name() {
      return this.whom ? this.whom.toUpperCase() : "";
    }
  },
  methods: {
    image(id) {
      return `https://storage.yandexcloud.net/thelak-storage/Certificates/hor/img${id}.jpg`;
    },
    copyText(e) {
      navigator.clipboard
        .writeText(e.target.innerHTML.trim())
        .then(() => {
          // todo show more beautiful popup
          alert("Промокод успешно скопирован!");
        })
        .catch(err => {
          alert("Произошла ошибка: " + err);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@media print {
  @page {
    size: landscape;
  }
}
</style>
