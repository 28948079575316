<template>
  <linked-block
    header="Мероприятия"
    link-to="/events"
    section-class="news-tovar"
  >
    <event :media="data" v-if="data.length" />
  </linked-block>
</template>

<script>
import Event from "@/components/page/slick/Event";
import LinkedBlock from "@/components/page/main/LinkedBlock";
export default {
  name: "EventBlock",
  components: { LinkedBlock, Event },
  props: {
    data: Array
  }
};
</script>

<style scoped></style>
