var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('calendar',{staticClass:"custom-calendar max-w-full wow fadeInUp",attrs:{"masks":_vm.masks,"attributes":_vm.calendarEvents,"locale":"ru","color":"#000"},on:{"update:to-page":_vm.loadMonthEvent},scopedSlots:_vm._u([{key:"day-content",fn:function(ref){
var day = ref.day;
var attributes = ref.attributes;
return _c('div',{staticClass:"flex flex-col h-full z-10 overflow-hidden",style:({
        border: attributes ? 'none' : ''
      })},[_c('span',{staticClass:"day-label text-sm text-gray-900"},[_vm._v(_vm._s(day.day))]),_c('div',{staticClass:"flex-grow max-h-full overflow-y-scroll overflow-x-auto"},_vm._l((attributes),function(attr){return _c('div',{key:attr.key,staticClass:"text-xs leading-tight rounded-sm p-1 mt-0 mb-1",style:({
            background: 'url(' + attr.customData.background + ')',
            border: 'none',
            'border-radius': '4px'
          }),on:{"click":function($event){return _vm.openEvent(attr.customData)}}},[_c('div',{staticClass:"event-item"},[_c('span',{staticClass:"items-status-date"},[_c('i',{staticClass:"far fa-calendar"})]),_c('span',{staticClass:"items-status-text"},[_vm._v(" "+_vm._s(_vm._f("moment")(day.date,"DD.MM.YY"))+" ")])])])}),0)])}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }