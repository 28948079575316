<template>
  <site-section :section-class="blockClass">
    <template v-slot:header>
      <h2>{{ blockHead }}</h2>
    </template>
    <template v-slot:content>
      <videos
        :item-class="itemClass"
        :slick-class="slickClass"
        :tiles="media"
        :slides-to-show="slidesToShow"
      />
    </template>
  </site-section>
</template>

<script>
import Videos from "@/components/page/slick/Videos";
import SiteSection from "@/components/page/SiteSection";
export default {
  name: "VideoSliderBlock",
  components: { SiteSection, Videos },
  props: {
    blockClass: String,
    blockHead: String,
    media: Array,
    slidesToShow: {
      type: Number,
      default: 4
    },
    slickClass: {
      type: String,
      default: "slider-news-tovar"
    },
    itemClass: {
      type: String,
      default: "news-tovar-items"
    }
  }
};
</script>

<style scoped></style>
