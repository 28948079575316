<template>
  <site-section section-class="news-states-inner">
    <template v-slot:description>
      <div class="inner-news-tovar">
        <cover :item="event" :use-views="false" />
      </div>
      <div class="states-inner-content event-content">
        <h2 v-html="event.title"></h2>
        <span
          class="event-description"
          v-html="event.description"
          v-if="event.description"
        ></span>
        <div class="states-content event-state" v-html="event.content"></div>
        <div v-if="event.startDate">
          <p>
            <br />
            Дата: {{ event.startDate | moment("DD.MM.YY hh:mm") }}
            <template v-if="event.endDate">
              - {{ event.endDate | moment("DD.MM.YY hh:mm") }}
            </template>
          </p>
        </div>
      </div>
    </template>
  </site-section>
</template>

<script>
import SiteSection from "@/components/page/SiteSection";
import Cover from "@/components/page/items/cover/Cover";
export default {
  name: "EventContent",
  components: { SiteSection, Cover },
  props: {
    event: Object
  }
};
</script>

<style scoped></style>
