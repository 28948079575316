<template>
  <div id="reg-window2">
    <h2>Восстановление пароля</h2>
    <p class="reg-pre">Введите свой E-mail, на него мы вышлем письмо</p>
    <validation-observer ref="observer">
      <form @submit.prevent="recoverEmail">
        <div id="reg-step2">
          <validation-provider
            name="Email"
            mode="lazy"
            rules="email|required"
            tag="div"
            class="input-reg-area"
            v-slot="{ errors, reset }"
          >
            <input
              type="text"
              placeholder="E-mail"
              v-model.trim="formData.email"
              :class="errors.length ? 'error-input' : ''"
              @click="reset"
            />
            <error-pop-over :reset-function="reset" :errors="errors" />
          </validation-provider>
          <div class="input-reg-submit">
            <input id="new-password" type="submit" value="Отправить" />
          </div>
        </div>
      </form>
    </validation-observer>
    <p class="login-reg">
      Вернуться к <router-link to="/sign/in">входу</router-link>
    </p>
    <modal-alert
      :show-modal.sync="showAlert"
      :action-modal="goToLogin"
      :alert-text="alertText"
      action-text="Продолжить"
    />
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { messages } from "vee-validate/dist/locale/ru.json";
import { email, required } from "vee-validate/dist/rules";
import ErrorPopOver from "@/components/page/items/error/ErrorPopOver";
import ModalAlert from "@/components/page/modals/forms/ModalAlert";
extend("required", {
  ...required,
  message: messages["required"]
});
extend("email", {
  ...email,
  message:
    "В поле E-mail необходимо ввести электронный адрес действительного почтового ящика"
});
export default {
  name: "RecoverEmail",
  data() {
    return {
      showAlert: false,
      alertText: "",
      formData: {
        email: ""
      }
    };
  },
  components: {
    ModalAlert,
    ErrorPopOver,
    ValidationProvider,
    ValidationObserver
  },
  methods: {
    recoverEmail() {
      this.$http
        .get("/auth/restore/request", {
          params: this.formData
        })
        .then(response => {
          const data = response.data;
          this.alertText = data
            ? "Инструкции отправлены на почту!"
            : "Произошла ошибка!";
          this.showAlert = true;
        });
    },
    goToLogin() {
      this.$router.push({ name: "login" });
    }
  }
};
</script>

<style scoped></style>
