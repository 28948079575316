<template>
  <div class="news-tovar-items-inner wow fadeInUp">
    <div class="absolute-mark-wrapper" v-if="favRemove">
      <label class="absolute-mark">
        <input type="checkbox" @click.prevent="unFavVideo" />
        <span>
          <i
            :class="{
              'far fa-bookmark': isNotFavourite,
              'fas fa-bookmark': !isNotFavourite
            }"
          ></i>
        </span>
      </label>
    </div>
    <sub-link
      :to="{ name: 'VideoById', params: { id: data.video.id } }"
      class-list="tovar-items-img video-img"
      :condition="getCondition(data.subscription)"
    >
      <div class="vjs-custom-skin">
        <div class="video-js">
          <div
            class="vjs-poster"
            aria-disabled="false"
            :style="{ background: 'url(' + data.video.poster + ')' }"
          ></div>
          <button
            class="vjs-big-play-button"
            type="button"
            title="Play Video"
            aria-disabled="false"
          >
            <span aria-hidden="true" class="vjs-icon-placeholder"></span>
          </button>
        </div>
      </div>

      <!--      <video-player :is-to-card="isToCard" :data="data.video" :height="180" />-->
      <div class="hidden-video">
        <div class="absolute-vote">
          <i class="fas fa-star"></i> <span>{{ data.video.vote }}</span>
          <!--<img class="video-items-icon" src="@/assets/img/video.svg" />-->
        </div>
        <div class="absolute-hd">
          <span>Full HD</span>
        </div>
        <div class="bottom-videos">
          <div class="videos-clock">
            <i class="far fa-clock"></i> <span>{{ data.video.length }}</span>
          </div>
          <div class="videos-views">
            <img src="@/assets/img/eye.svg" alt="views" />
            <span>{{ data.video.views }}</span>
          </div>
        </div>
      </div>
    </sub-link>
    <tags :tags="data.tags" />
    <h4>
      <sub-link
        :to="{ name: 'VideoById', params: { id: data.video.id } }"
        :condition="getCondition(data.subscription)"
      >
        {{ data.name }}
      </sub-link>
    </h4>
    <div class="tovar-items-status" v-if="!data.hideStatus">
      <span
        :class="['items-status-circle', data.subscription ? 'blue' : 'green']"
      ></span>
      <span class="items-status-text">{{ data.status }}</span>
    </div>
  </div>
</template>

<script>
// import VideoPlayer from "@/components/page/video/VideoPlayer";
import Tags from "@/components/page/items/tags/Tags";
import SubLink from "@/components/page/subscription/links/SubLink";

export default {
  name: "VideoTile",
  components: { SubLink, Tags },
  data() {
    return {
      player: null,
      isNotFavourite: false
    };
  },
  props: {
    favRemove: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default() {
        return {
          tags: ["", ""],
          name: "",
          status: "",
          video: {
            length: "",
            views: 0,
            vote: "",
            id: 1,
            sources: [
              {
                src: "",
                type: "",
                label: "",
                res: 480
              }
            ]
          }
        };
      }
    },
    isToCard: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getCondition(isSubscription) {
      return () => {
        if (!isSubscription) {
          return true;
        }
        const user = this.$auth.user();
        return (
          !!user &&
          user.isSubscribe &&
          new Date(user.subscriptionDate).getTime() > new Date().getTime()
        );
      };
    },
    unFavVideo() {
      if (this.isNotFavourite) {
        this.axios
          .post("/video/favorites/add", null, {
            params: { videoId: this.data.video.id }
          })
          .then(data => {
            this.isNotFavourite = !data.data;
            this.$forceUpdate();
          });
      } else {
        this.axios
          .delete("/video/favorites/delete", {
            params: { videoId: this.data.video.id }
          })
          .then(data => {
            this.isNotFavourite = data.data;
            this.$forceUpdate();
          });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.bottom-videos div {
  &.videos-views {
    display: flex;
    & span {
      padding: 1px;
    }
  }
}

.vjs-poster {
  background-position: inherit !important;
  background-size: cover !important;
  border-radius: 4px;
}

.vjs-poster {
  display: inline-block;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
  background-color: #000000;
  cursor: pointer;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
}

.video-js .vjs-big-play-button {
  font-size: 3em;
  line-height: 1.5em;
  height: 1.63332em;
  width: 3em;
  display: block;
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 0;
  cursor: pointer;
  opacity: 1;
  border: 0.06666em solid #fff;
  background-color: #2b333f;
  background-color: rgba(43, 51, 63, 0.7);
  border-radius: 0.3em;
  transition: all 0.4s;
}

.video-js button {
  background: none;
  border: none;
  color: inherit;
  display: inline-block;
  font-size: inherit;
  line-height: inherit;
  text-transform: none;
  text-decoration: none;
  transition: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.video-js *,
.video-js *:before,
.video-js *:after {
  box-sizing: inherit;
}
</style>
