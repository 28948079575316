<template>
  <state-item>
    <div class="states-wrapper">
      <div class="states-wrapper-background">
        <img :src="tile.photoUrl" :alt="tile.name" v-if="tile.photoUrl" />
        <img src="@/assets/img/user.png" alt="user" v-else />
      </div>
      <div class="states-wrapper-content">
        <router-link
          :to="{ name: 'SingleSpeaker', params: { id: tile.id } }"
          class="spiker-items-img"
        />
        <h4>
          <router-link :to="{ name: 'SingleSpeaker', params: { id: tile.id } }">
            {{ tile.name }}
          </router-link>
        </h4>
        <tags :tags="tile.categories" />
      </div>
      <img
        class="img-flag"
        :src="
          require('@/assets/img/flag/' +
            (tile.countryFlagCode ? tile.countryFlagCode : 'russia') +
            '.svg')
        "
        :alt="tile.country"
      />
    </div>
  </state-item>
</template>

<script>
import StateItem from "@/components/page/slick/StateItem";
import Tags from "@/components/page/items/tags/Tags";
export default {
  name: "SpeakerTile2",
  components: { StateItem, Tags },
  props: {
    tile: Object
  }
};
</script>

<style scoped></style>
