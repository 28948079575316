// import bearer from "@websanova/vue-auth/drivers/auth/bearer";
import bearer from "./bearer";
import axios from "./auth";
// import router from "@websanova/vue-auth/drivers/router/vue-router.2.x";
import router from "./router";

const config = {
  auth: bearer,
  http: axios,
  router: router,
  tokenDefaultKey: "thelak",
  tokenStore: ["localStorage"],
  rolesKey: "roles",
  registerData: {
    url: "auth/signup",
    method: "POST",
    redirect: ""
  },
  loginData: {
    url: "auth/login",
    method: "POST",
    staySignedIn: true,
    fetchUser: true
    // redirect: ""
  },
  logoutData: {
    url: "auth/logout",
    method: "POST",
    redirect: "/",
    makeRequest: false
  },
  fetchData: {
    url: "auth/info",
    method: "GET",
    enabled: true
  },
  refreshData: {
    url: "auth/refresh",
    method: "GET",
    enabled: true,
    interval: 30
  },
  notFoundRedirect: { path: "/" },
  authRedirect: { path: "/sign/in" }
  // authRedirect: { path: "" }
};

export default config;
