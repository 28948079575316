<template>
  <site-section section-class="news-tovar">
    <template v-slot:header>
      <h2>Модульное обучение</h2>
      <a href="/"
        >Все модули
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="13.503"
          height="23.619"
          viewBox="0 0 13.503 23.619"
        >
          <path
            id="Icon_ionic-ios-arrow-back"
            data-name="Icon ionic-ios-arrow-back"
            d="M15.321,18l8.937-8.93a1.688,1.688,0,0,0-2.391-2.384L11.742,16.8a1.685,1.685,0,0,0-.049,2.327L21.86,29.32a1.688,1.688,0,0,0,2.391-2.384Z"
            transform="translate(24.754 29.813) rotate(180)"
          /></svg
      ></a>
    </template>
    <template v-slot:content>
      <module :module-data="tiles" />
    </template>
  </site-section>
</template>

<script>
import SiteSection from "@/components/page/SiteSection";
import Module from "@/components/page/slick/Module";
export default {
  name: "ModuleLearningBlock",
  components: { Module, SiteSection },
  data() {
    return {
      tiles: [
        {
          id: 0,
          tags: ["Маркетинг", "Бизнес"],
          name: "Построение бизнес-стратегии:<br />5 сильных кейсов",
          status: "blue",
          type: "По подписке"
        },
        {
          id: 1,
          tags: ["Маркетинг", "Бизнес"],
          name: "Построение бизнес-стратегии:<br />5 сильных кейсов",
          status: "green",
          type: "Бесплатно"
        },
        {
          id: 2,
          tags: ["Маркетинг", "Бизнес"],
          name: "Построение бизнес-стратегии:<br />5 сильных кейсов",
          status: "green",
          type: "Бесплатно"
        },
        {
          id: 3,
          tags: ["Маркетинг", "Бизнес"],
          name: "Построение бизнес-стратегии:<br />5 сильных кейсов",
          status: "green",
          type: "Бесплатно"
        },
        {
          id: 4,
          tags: ["Маркетинг", "Бизнес"],
          name: "Построение бизнес-стратегии:<br />5 сильных кейсов",
          status: "green",
          type: "Бесплатно"
        }
      ]
    };
  }
};
</script>

<style scoped></style>
