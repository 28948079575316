<template>
  <div class="row">
    <div class="col-xl-12 col-lg-12 col-sm-12 w100">
      <div class="widget-content widget-content-area">
        <h2>Категории</h2>
        <table-view
          :heads="heads"
          :items.sync="categories"
          :fields="schema"
          edit-head="Редактировать категорию"
          obtain-method="/category/get"
          update-method="/category/update"
          delete-method="/category/delete"
          @updated="loadCategories"
          :form-options="formOptions"
          :use-pagination="false"
        />
      </div>
      <div class="widget-content widget-content-area">
        <h2>Добавить категорию</h2>
        <create-category
          :schema="schema"
          :form-options="formOptions"
          @create="loadCategories"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CreateCategory from "@/views/admin/categories/CreateCategory";
import TableView from "@/components/admin/lists/TableView";
import { uuid } from "vue-uuid";
import { validators } from "vue-form-generator";
export default {
  name: "AdminCategories",
  components: { TableView, CreateCategory },
  data() {
    return {
      heads: {
        id: {
          name: "ID"
        },
        title: {
          name: "Имя"
        },
        photoUrl: {
          name: "Фото",
          type: "picture"
        }
      },
      formOptions: {
        validateAfterLoad: false,
        validateAfterChanged: true,
        validateAsync: true
      }
    };
  },
  props: {
    searchParams: {
      type: [String, Object],
      default: ""
    }
  },
  computed: {
    ...mapGetters(["categories"]),
    schema() {
      return {
        groups: [
          {
            legend: "Основное",
            fields: [
              {
                type: "input",
                inputType: "text",
                label: "Название",
                model: "title",
                required: true,
                validator: validators.required,
                placeholder: "Саморазвитие",
                id: uuid.v1() + "title"
              },
              {
                type: "input",
                inputType: "text",
                label: "Фото",
                model: "photoUrl",
                required: false,
                placeholder: "Ссылка",
                id: uuid.v1() + "photoUrl"
              }
            ]
          }
        ]
      };
    }
  },
  methods: {
    ...mapActions(["getCategoriesByTitle"]),
    loadCategories() {
      return this.getCategoriesByTitle(this.searchParams);
    }
  },
  watch: {
    searchParams(n, o) {
      if (n !== o) {
        this.loadCategories();
      }
    }
  },
  created() {
    this.loadCategories();
  }
};
</script>

<style scoped></style>
