<template>
  <div>
    <h2>Регистрация</h2>
    <p class="reg-pre">
      Войдите или зарегистрируйтесь для доступа к контенту
    </p>
    <ValidationObserver ref="observer">
      <form @submit.prevent="signUp">
        <validation-provider
          name="Имя и фамилия"
          rules="required|fullname"
          tag="div"
          class="input-reg-area"
          v-slot="{ errors, reset }"
        >
          <input
            v-model.trim="name"
            type="text"
            placeholder="Имя и фамилия"
            :class="errors.length ? 'error-input' : ''"
            required
            @click="reset"
          />
          <error-pop-over :errors="errors" :reset-function="reset" />
        </validation-provider>
        <validation-provider
          name="E-mail"
          rules="required|email"
          mode="passive"
          tag="div"
          class="input-reg-area"
          v-slot="{ errors, reset }"
        >
          <input
            v-model.trim="email"
            class="mail-form"
            type="email"
            placeholder="E-mail"
            @click="reset"
            required
          />
          <error-pop-over :reset-function="reset" :errors="errors" />
        </validation-provider>
        <div class="input-reg-area">
          <masked-input
            v-model.trim="phone"
            class="phone"
            mask="\+\7 (111) 111-11-11"
            placeholder="Телефон"
            required
          />
        </div>
        <ValidationProvider
          name="Пароль"
          mode="aggressive"
          rules="length:6|confirmed:confirmation|required"
          v-slot="{ errors, reset }"
          tag="div"
          class="input-reg-area"
        >
          <input
            v-model="password"
            :type="togglePassword ? 'text' : 'password'"
            placeholder="Пароль"
            :class="errors.length ? 'error-input' : ''"
          />
          <error-pop-over
            :reset-function="reset"
            :errors="errors"
            default-text="Не менее 6 символов"
          />
          <a
            href="#"
            tabindex="-1"
            @click.prevent="togglePassword = !togglePassword"
          >
            <i class="fas fa-eye"></i>
          </a>
        </ValidationProvider>
        <ValidationProvider
          name="Подтверждение пароля"
          rules="required|length:6"
          mode="aggressive"
          v-slot="{ errors, reset }"
          tag="div"
          class="input-reg-area"
          vid="confirmation"
        >
          <input
            v-model="confirm_password"
            :type="togglePassword ? 'text' : 'password'"
            placeholder="Повторите пароль"
            :class="errors.length ? 'error-input' : ''"
            required
          />
          <error-pop-over
            :reset-function="reset"
            :errors="errors"
            default-text="Не менее 6 символов"
          />
          <a
            href="#"
            tabindex="-1"
            @click.prevent="togglePassword = !togglePassword"
          >
            <i class="fas fa-eye"></i>
          </a>
        </ValidationProvider>

        <div class="input-reg-submit">
          <validation-provider rules="is:1" v-slot="{ errors, reset }">
            <label class="checkbox-reg">
              <input v-model="accept_terms" type="checkbox" required />
              <span>
                Принимаю
                <a
                  href="https://thelak.com/useragreement_thelak"
                  class="agreement-reg"
                  target="_blank"
                  >Пользовательское соглашение</a
                >
                и даю согласие на обработку персональных данных и получение
                информационных сообщений
              </span>
            </label>
            <error-pop-over
              style="top: inherit"
              :errors="errors"
              :reset-function="reset"
            />
          </validation-provider>
          <input
            id="more-reg"
            type="submit"
            value="Зарегистрироваться"
            :disabled="!accept_terms"
          />
        </div>
      </form>
    </ValidationObserver>
    <modal-alert
      :alert-text="errorText"
      action-text="Попробовать заново"
      :action-modal="closeAlert"
      :show-modal.sync="showAlert"
    />
    <p class="login-reg">
      Уже зарегистрированы?
      <router-link to="/sign/in">Войдите в аккаунт</router-link>
    </p>
  </div>
</template>

<script>
import MaskedInput from "vue-masked-input";
import { mapGetters, mapMutations, mapActions } from "vuex";
import {
  required,
  min,
  email,
  confirmed,
  alpha_spaces
} from "vee-validate/dist/rules";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import ErrorPopOver from "@/components/page/items/error/ErrorPopOver";
import ModalAlert from "@/components/page/modals/forms/ModalAlert";

const errors = {
  object_already_exists: "Данный пользователь уже сущетсвует!"
};

extend("fullname", {
  ...alpha_spaces,
  message: 'Поле "{_field_}" может содержать только буквы и пробелы'
});

extend("confirmed", {
  ...confirmed,
  message: 'Поле "{_field_}" не совпадает с полем "{target}"'
});

extend("required", {
  ...required,
  message: 'Поле "{_field_}" обязательно для заполнения'
});

extend("length", {
  ...min,
  message: 'В поле "{_field_}" должно быть не менее {length} символов'
});

extend("email", {
  ...email,
  message: 'В поле "{_field_}" должен быть действительный электронный адрес'
});

extend("is", {
  params: ["target"],
  validate(value, { target }) {
    return value === !!target;
  },
  message: "Подтвердите действие"
});

export default {
  name: "SignUp",
  components: {
    MaskedInput,
    ValidationProvider,
    ValidationObserver,
    ErrorPopOver,
    ModalAlert
  },
  data() {
    return {
      accept_terms: false,
      errorText: "",
      showAlert: false,
      togglePassword: false
    };
  },
  computed: {
    ...mapGetters(["forms"]),
    name: {
      get() {
        return this.forms.name;
      },
      set(value) {
        this.setFormName({ name: value });
      }
    },
    email: {
      get() {
        return this.forms.email;
      },
      set(value) {
        this.setEmail({ email: value });
      }
    },
    phone: {
      get() {
        return this.forms.phone;
      },
      set(value) {
        this.setPhone({ phone: value });
      }
    },
    password: {
      get() {
        return this.forms.password;
      },
      set(value) {
        this.setFormPassword({ password: value });
      }
    },
    confirm_password: {
      get() {
        return this.forms.confirm_password;
      },
      set(value) {
        this.setFormConfirmPassword({ confirm_password: value });
      }
    }
  },
  methods: {
    ...mapMutations([
      "setFormName",
      "setEmail",
      "setPhone",
      "setFormPassword",
      "setFormConfirmPassword"
    ]),
    ...mapActions(["signUpUser"]),
    signUp() {
      this.$refs.observer.validate().then(isValid => {
        if (isValid)
          this.signUpUser()
            .then(path => {
              this.$router.push(path);
            })
            .catch(data => {
              this.showAlert = true;
              this.errorText = errors[data.error];
            });
        else alert("Проверьте правильность заполнения");
      });
    },
    closeAlert() {
      this.showAlert = false;
    }
  }
};
</script>
