<template>
  <div class="wrapper-inner">
    <div class="inner-content">
      <div class="about-wrapper-detail">
        <h1 class="header-big">Отписаться от рассылки</h1>
      </div>
      <div class="pre-flex-header" v-if="success">
        {{ message }}
      </div>
      <p class="pre-flex-header" v-else>
        {{ message ? message : "Загрузка..." }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Unsubscription",
  data() {
    return {
      message: "",
      success: false
    };
  },
  props: {
    item: {
      type: [String, Number, Object]
    }
  },
  methods: {
    requestUnsubscription(email) {
      this.$http
        .post("/auth/unsubscription", { email })
        .then(response => {
          const data = response.data;
          this.success = data.success;
          this.message = data.message;
        })
        .catch(() => {
          this.success = false;
          this.message =
            "Произошла ошибка при отписке от рассылки. Вы будете перенаправлены через 3 секунды";
          setTimeout(() => {
            this.$router.push("/");
          }, 3e3);
        });
    }
  },
  created() {
    this.requestUnsubscription(this.item);
  }
};
</script>

<style scoped></style>
