<template>
  <div class="email-cert" v-if="condition">
    <validation-provider
      name="Email"
      mode="lazy"
      rules="email|required"
      tag="div"
      class="input-block"
      v-slot="{ errors, reset }"
    >
      <label :for="idForEmail">
        Куда отправить сертификат? *
      </label>
      <input
        :id="idForEmail"
        type="email"
        v-model="updateEmail"
        placeholder="Email"
        :class="{ 'error-input': errors.length }"
        required
      />
      <error-pop-over :reset-function="reset" :errors="errors" />
    </validation-provider>
  </div>
  <div v-else></div>
</template>

<script>
import { uuid } from "vue-uuid";
import ErrorPopOver from "@/components/page/items/error/ErrorPopOver";
import { ValidationProvider, extend } from "vee-validate";
import { email, required } from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: "Заполните {_field_}"
});
extend("email", {
  ...email,
  message: "Введите действительный электронный адрес"
});

export default {
  name: "CertEmail",
  components: { ErrorPopOver, ValidationProvider },
  props: {
    emailInput: String,
    idEmail: {
      type: String,
      default: "certEmail"
    },
    condition: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    idForEmail() {
      return this.idEmail + uuid.v4();
    },
    updateEmail: {
      get() {
        return this.emailInput;
      },
      set(n) {
        this.$emit("update:emailInput", n);
      }
    }
  }
};
</script>

<style scoped></style>
