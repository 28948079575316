<template>
  <slick
    ref="slick"
    :options="slickOptions"
    class="slider-news-spikers partners-margin"
  >
    <state-item v-for="tile in tiles" v-bind:key="tile.id">
      <div class="partners-wrapper">
        <img :src="tile.src" :alt="tile.alt" />
      </div>
    </state-item>
  </slick>
</template>

<script>
import Slick from "vue-slick";
import StateItem from "@/components/page/slick/StateItem";
export default {
  name: "Partners",
  data() {
    return {
      tiles: [
        {
          id: 0,
          src: require("@/assets/img/TED.png"),
          alt: "TED"
        },
        {
          id: 1,
          src: require("@/assets/img/Bitrix24.png"),
          alt: "BITRIX24"
        },
        {
          id: 2,
          src: require("@/assets/img/amosrm.png"),
          alt: "amoCRM"
        },
        {
          id: 3,
          src: require("@/assets/img/mind.svg"),
          alt: "MIND"
        },
        {
          id: 4,
          src: require("@/assets/img/TED.png"),
          alt: "TED"
        },
        {
          id: 5,
          src: require("@/assets/img/Bitrix24.png"),
          alt: "BITRIX24"
        },
        {
          id: 6,
          src: require("@/assets/img/amosrm.png"),
          alt: "amoCRM"
        },
        {
          id: 7,
          src: require("@/assets/img/mind.svg"),
          alt: "MIND"
        }
      ],
      slickOptions: {
        infinite: true,
        dots: false,
        arrows: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        rows: 0,
        prevArrow:
          "<div class='slick-prev-states'><img src='" +
          this.arrowUrl("right") +
          "' alt='' /></div>",
        nextArrow:
          "<div class='slick-next-states'><img src='" +
          this.arrowUrl("left") +
          "' alt='' /></div>",
        responsive: [
          {
            breakpoint: 760,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 450,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      }
    };
  },
  components: { StateItem, Slick },
  methods: {
    arrowUrl(arrow) {
      return require("@/assets/img/arrow-" + arrow + ".svg");
    },
    next() {
      this.$refs.slick.next();
    },
    prev() {
      this.$refs.slick.prev();
    },
    reInit() {
      this.$nextTick(() => {
        this.$refs.slick.reSlick();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~slick-carousel/slick/slick.css";
</style>
