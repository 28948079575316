import { render, staticRenderFns } from "./EventBlock.vue?vue&type=template&id=159a8538&scoped=true&"
import script from "./EventBlock.vue?vue&type=script&lang=js&"
export * from "./EventBlock.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../../../usr/local/lib/node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "159a8538",
  null
  
)

export default component.exports