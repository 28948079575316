<template>
  <footer class="footer">
    <div class="inner-content">
      <div class="flex-top-footer">
        <div class="top-footer-left">
          <top-footer-menu />
        </div>
<!--         <div class="top-footer-right">
          <a href="https://vk.com/thelakmedia" target="_blank">
            <i class="fab fa-vk"></i>
          </a>
          <a href="https://instagram.com/thelak.media" target="_blank">
            <i class="fab fa-instagram"></i>
          </a>
          <a
            href="https://www.facebook.com/groups/thelak/?ref=share"
            target="_blank"
          >
            <i class="fab fa-facebook-f"></i>
          </a>
          <a href="https://ok.ru/group/64636085731364" target="_blank">
            <i class="fab fa-odnoklassniki"></i>
          </a>
          <a
            href="https://www.youtube.com/channel/UCzR52WVSbJ-4RkvwRXy_eUQ"
            target="_blank"
          >
            <i class="fab fa-youtube"></i>
          </a>
        </div> -->
      </div>
      <div class="flex-bottom-footer" v-if="!$route.meta.hideBottomFooter">
        <div class="bottom-footer-left">
          <bottom-footer-menu />
          <div class="clear"></div>
          <ul class="bottom-footer-copy">
            <li><a>© 2020 thelak.com</a></li>
            <li>
              <a href="/useragreement_thelak" target="_blank">
                Пользовательское соглашение
              </a>
            </li>
            <li>
              <a href="/privacypolicy_thelak" target="_blank">
                Политика конфиденциальности
              </a>
            </li>
          </ul>
        </div>
        <div class="bottom-footer-right">
          <div class="footer-phone" v-if="false">
            <a href="tel:88009126312">8 800 912 63 12</a>
          </div>
          <div class="footer-mail">
            <a href="mailto:ineed@thelak.com">ineed@thelak.com</a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import TopFooterMenu from "@/components/page/menu/TopFooterMenu";
import BottomFooterMenu from "@/components/page/menu/BottomFooterMenu";
export default {
  name: "SiteFooter",
  components: { BottomFooterMenu, TopFooterMenu }
};
</script>

<style scoped></style>
