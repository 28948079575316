<template>
  <transition name="fade">
    <span class="error-mess" @click="clickHandle" v-if="errors.length">
      {{ errors[0] }}
    </span>
    <span v-html="defaultText" v-else></span>
  </transition>
</template>

<script>
export default {
  name: "ErrorPopOver",
  props: ["errors", "resetFunction", "defaultText"],
  methods: {
    clickHandle() {
      if (this.resetFunction) this.resetFunction();
    }
  }
};
</script>

<style scoped>
.error-mes {
  cursor: pointer;
}

.error-mess {
  color: red;
}
</style>
