<template>
  <validation-observer ref="observerCloud">
    <form
      id="card"
      ref="card"
      class="tab-ballance-card"
      @submit.prevent="pay"
      autocomplete="off"
    >
      <cert-email
        :condition="payment === 'cert'"
        :email-input.sync="updateEmail"
      />
      <div class="ballance-card-relative">
        <div class="card-relative-front">
          <div class="absolute-card-icons">
            <img src="@/assets/img/cards-cr.svg" alt="cr" />
          </div>
          <validation-provider
            ref="cardNumber"
            name="Номер банковской карты"
            mode="lazy"
            rules="required"
            tag="div"
            class="input-card-front"
            v-slot="{ errors, reset }"
          >
            <masked-input
              mask="1111 1111 1111 1111 11"
              placeholder-char=" "
              placeholder="Номер банковской карты"
              v-model.trim="cardNumber"
              data-cp="cardNumber"
              :class="{ 'error-input': errors.length }"
              required
            />
            <error-pop-over :reset-function="reset" :errors="errors" />
          </validation-provider>
          <validation-provider
            ref="name"
            name="Имя и Фамилия держателя карты"
            mode="lazy"
            rules="required"
            tag="div"
            class="input-card-front"
            v-slot="{ errors, reset }"
          >
            <input
              v-model.trim="formData.cardName"
              type="text"
              placeholder="Имя и Фамилия держателя карты"
              data-cp="name"
              :class="{ 'error-input': errors.length }"
              required
            />
            <error-pop-over :reset-function="reset" :errors="errors" />
          </validation-provider>
          <div class="input-card-date">
            <div class="expiring-at">Действительна до</div>
            <div>
              <validation-provider
                ref="expDateMonth"
                mode="lazy"
                name="ММ"
                rules="required"
                tag="div"
                class="input-card-date-input"
                v-slot="{ errors, reset }"
              >
                <masked-input
                  v-model.trim="formData.cardMonth"
                  mask="11"
                  ref="monthCard"
                  placeholder-char=" "
                  placeholder="ММ"
                  data-cp="expDateMonth"
                  @input="monthEnded"
                  @click="reset"
                  :class="{ 'error-input': errors.length }"
                  required
                />
              </validation-provider>
              <span style="margin: 0 5px;">/</span>
              <validation-provider
                ref="expDateYear"
                name="ГГ"
                mode="lazy"
                rules="required"
                tag="div"
                class="input-card-date-input"
                v-slot="{ errors, reset }"
              >
                <masked-input
                  v-model.trim="formData.cardYear"
                  mask="11"
                  ref="yearCard"
                  placeholder-char=" "
                  placeholder="ГГ"
                  :class="{ 'error-input': errors.length }"
                  data-cp="expDateYear"
                  @input="yearEnded"
                  @click="reset"
                  required
                />
              </validation-provider>
            </div>
          </div>
        </div>
        <div class="card-relative-back">
          <div class="relative-back-sep"></div>
          <div class="relative-back-right">
            <validation-provider
              name="CVV"
              ref="cvv"
              rules="required"
              mode="lazy"
              tag="div"
              class="input-card-back"
              v-slot="{ errors, reset }"
            >
              <masked-input
                v-model.trim="formData.cardCode"
                mask="111"
                ref="cvvCard"
                placeholder-char=" "
                placeholder="CVC2/CVV2"
                :class="{ 'error-input': errors.length }"
                data-cp="cvv"
                required
              />
              <error-pop-over :reset-function="reset" :errors="errors" />
            </validation-provider>
            <p>Последние 3 цифры на обратной стороне карты</p>
          </div>
        </div>
      </div>
      <submit-field
        :good-name.sync="good.name"
        class-binding="ballance-bottom-content"
      >
        <template v-slot:rightBottomFlex>
          <img src="@/assets/img/cards-cr.svg" alt="cr" />
        </template>
      </submit-field>
    </form>
  </validation-observer>
</template>

<script>
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import ErrorPopOver from "@/components/page/items/error/ErrorPopOver";
import payment from "@/store/util/payment";
const api = "cloudApi";
import maskedInput from "vue-masked-input";
import CertEmail from "@/components/page/pay/forms/fields/EmailField";
import SubmitField from "@/components/page/pay/forms/fields/SubmitField";
extend("required", {
  ...required,
  message: "Заполните {_field_}"
});
export default {
  name: "CloudPay",
  props: {
    good: Object,
    payment: String,
    certData: Object,
    email: String
  },
  components: {
    SubmitField,
    CertEmail,
    maskedInput,
    ValidationObserver,
    ErrorPopOver,
    ValidationProvider
  },
  data() {
    return {
      formData: {
        cardNumber: "",
        cardMonth: "",
        cardYear: "",
        cardCode: "",
        cardName: ""
      }
    };
  },
  computed: {
    updateEmail: {
      get() {
        return this.email;
      },
      set(n) {
        this.$emit("update:email", n);
      }
    },
    cardNumber: {
      get() {
        return this.formData.cardNumber;
      },
      set(n) {
        this.formData.cardNumber = n.trim();
      }
    }
  },
  methods: {
    monthEnded(e) {
      if (e.trim().length > 1) {
        this.$refs.yearCard.$el.focus();
      }
    },
    yearEnded(e) {
      if (e.trim().length > 1) {
        this.$refs.cvvCard.$el.focus();
      }
    },
    checkout() {
      return new Promise(resolve => {
        payment.getPaymentConfig().then(data => {
          // eslint-disable-next-line no-undef
          const checkOut = new cp.Checkout(data.publicId, this.$refs.card);
          resolve(checkOut);
        });
      });
    },
    loadCloudPayment() {
      payment.loadComponent(api, {
        src: "https://widget.cloudpayments.ru/bundles/checkout"
      });
    },
    unloadCloudPayment() {
      payment.unloadComponent(api);
    },
    acquireReqCert(packet) {
      payment.acquireRequest(
        "/payments/cert/req",
        { cardCryptogramPacket: packet },
        {
          cardName: this.formData.cardName,
          certificateId: this.good.id,
          description: this.certData.addComment,
          fio: this.certData.addName,
          type: this.certData.addType,
          promoId: this.good.promoId,
          email: this.updateEmail
        }
      );
    },
    acquireReq(packet) {
      payment.acquireRequest(
        "/payments/sub/req",
        {
          cardCryptogramPacket: packet
        },
        {
          cardName: this.formData.cardName,
          promoId: this.good.promoId,
          subscriptionId: this.good.id
        }
      );
    },
    pay() {
      try {
        this.checkout().then(paymentClient => {
          const result = paymentClient.createCryptogramPacket();
          if (result.success) {
            switch (this.payment) {
              case "cert":
                this.acquireReqCert(result.packet);
                break;
              default:
                this.acquireReq(result.packet);
            }
          } else if (result.messages) {
            this.applyPayFail(result.messages);
          }
        });
      } catch (e) {
        console.log(e);
      }
    },
    applyPayFail(messages) {
      Object.keys(messages).forEach(item => {
        this.$refs[item].applyResult({
          errors: [].concat(messages[item]),
          valid: false,
          failedRules: {}
        });
      });
    }
  },
  mounted() {
    this.loadCloudPayment();
  },
  beforeDestroy() {
    this.unloadCloudPayment();
  }
};
</script>

<style scoped></style>
