<template>
  <div>
    <div class="wrapper-inner">
      <div class="inner-content">
        <h1 class="header-big wow fadeInUp">Мероприятия</h1>
        <block-calendar :events="eventsFiltered" @loadmonth="loadMonthEvents" />
      </div>
    </div>
    <event-block :data="events" :page="page" :update-events="loadEvents" />
  </div>
</template>

<script>
import { WOW } from "wowjs";
import BlockCalendar from "@/components/page/events/BlockCalendar";
import EventBlock from "@/components/page/events/EventBlock";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Events",
  components: { EventBlock, BlockCalendar },
  data() {
    return {
      page: 1
    };
  },
  computed: {
    ...mapGetters(["events", "eventsFiltered"])
  },
  methods: {
    ...mapActions(["getPageEvents", "getMonthEvents"]),
    loadEvents(sort, sortType, page) {
      if (page) {
        this.page = page;
      }
      return this.getPageEvents(this.page);
    },
    loadMonthEvents(page) {
      return this.getMonthEvents(page);
    }
  },
  mounted() {
    new WOW({ offset: 140, live: false }).init();
  },
  created() {
    this.loadEvents();
  }
};
</script>

<style scoped></style>
