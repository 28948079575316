<template>
  <fragment v-if="$route.meta.noWrapper">
    <site-header />
    <router-view></router-view>
    <site-footer />
  </fragment>
  <div class="wrapper" v-else>
    <site-header />
    <router-view></router-view>
    <site-footer />
  </div>
</template>

<script>
import SiteHeader from "@/components/page/SiteHeader";
import SiteFooter from "@/components/page/SiteFooter";
export default {
  name: "Index",
  components: { SiteFooter, SiteHeader }
};
</script>

<style lang="scss" scoped>
//@import '~responsive-tabs/css/responsive-tabs.css';
</style>
