<template>
  <div class="table-responsive mb-4 mt-4">
    <table id="zero-config" class="table" style="width: 100%">
      <thead>
        <tr>
          <th v-for="(head, key) in heads" v-bind:key="key">
            {{ head.name }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in items" v-bind:key="item.key">
          <td v-for="(head, key) in heads" v-bind:key="key">
            <a
              class="edit-table"
              :href="item[key]"
              v-if="head.type === 'picture'"
              target="_blank"
            >
              Открыть
            </a>
            <span v-else-if="head.type === 'datetime'">
              <b v-if="item[key]">{{ item[key] | moment("DD.MM.YY hh:mm") }}</b>
              <b v-else>Нет</b>
            </span>
            <span v-else>{{ item[key] || 0 }}</span>
          </td>
          <td class="text-center" v-if="editable">
            <ul class="table-controls">
              <li>
                <a
                  href="#"
                  data-toggle="tooltip"
                  data-placement="top"
                  title=""
                  data-original-title="Edit"
                  @click.prevent="editItem(item.id)"
                  ><svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-edit-2"
                  >
                    <path
                      d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"
                    ></path></svg
                ></a>
              </li>
              <li>
                <a
                  href="#"
                  data-toggle="tooltip"
                  data-placement="top"
                  title=""
                  data-original-title="Delete"
                  @click.prevent="deleteItem(item.id)"
                  ><svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-trash-2"
                  >
                    <polyline points="3 6 5 6 21 6"></polyline>
                    <path
                      d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
                    ></path>
                    <line x1="10" y1="11" x2="10" y2="17"></line>
                    <line x1="14" y1="11" x2="14" y2="17"></line></svg
                ></a>
              </li>
            </ul>
          </td>
        </tr>
      </tbody>
    </table>
    <paginate
      :page.sync="currentPage"
      :length="items.length"
      @pageChanged="updateItem"
      v-if="usePagination"
    />
    <modal-admin
      :show-modal.sync="showModal"
      :header="editHead"
      :item-data.sync="editableData"
      :fields="fields"
      :form-options="formOptions"
      :update-method="updateMethod"
      :old-form="oldForm"
      @recordUpdated="updateItem"
      v-if="showModal && !!fields"
    />
  </div>
</template>

<script>
import ModalAdmin from "@/components/page/modals/forms/ModalAdmin";
import Paginate from "@/components/admin/lists/Paginate";

export default {
  name: "TableView",
  data() {
    return {
      showModal: false,
      editableData: {}
    };
  },
  components: { Paginate, ModalAdmin },
  props: {
    heads: Object,
    items: Array,
    fields: {
      type: [Object, Array],
      default() {
        return null;
      }
    },
    formOptions: {
      type: Object,
      default() {
        return {};
      }
    },
    page: {
      type: Number,
      default: 1
    },
    usePagination: {
      type: Boolean,
      default: true
    },
    editHead: String,
    updateMethod: String,
    deleteMethod: String,
    obtainMethod: String,
    editable: {
      type: Boolean,
      default: true
    },
    oldForm: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.page;
      },
      set(n) {
        this.$emit("update:page", n);
      }
    }
  },
  methods: {
    updateItem() {
      this.$emit("updated");
    },
    editItem(item) {
      this.$http
        .get(this.obtainMethod, {
          params: {
            id: item
          }
        })
        .then(response => {
          this.editableData = response.data;
          this.showModal = true;
        });
    },
    deleteItem(item) {
      this.$http
        .delete(this.deleteMethod, {
          params: {
            id: item
          }
        })
        .then(response => {
          console.log(response.data);
          this.updateItem();
        });
    }
  }
};
</script>

<style scoped>
ul li {
  display: inline;
  margin-right: 5px;
  padding: 3px;
}
.mb-4 {
  margin-bottom: 20px;
}
.mt-4 {
  margin-top: 20px;
}
</style>
