<template>
  <ul class="navbar-item flex-row search-ul">
    <li class="nav-item align-self-center search-animated">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="feather feather-search toggle-search"
      >
        <circle cx="11" cy="11" r="8"></circle>
        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
      </svg>
      <form
        class="form-inline search-full form-inline search"
        @submit.prevent=""
        role="search"
      >
        <div class="search-bar">
          <label>
            <input
              @keyup="searchUpdate"
              type="text"
              class="form-control search-form-control  ml-lg-auto"
              placeholder="Search..."
            />
          </label>
        </div>
      </form>
    </li>
  </ul>
</template>

<script>
export default {
  name: "SearchHeader",
  data() {
    return {
      timer: null
    };
  },
  props: {
    searchParams: {
      type: [String, Object]
    }
  },
  computed: {
    searchValue: {
      get() {
        return this.searchParams;
      },
      set(n) {
        this.$emit("update:searchParams", n);
      }
    }
  },
  methods: {
    searchUpdate(e) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      setTimeout(() => {
        this.searchValue = e.target.value;
      }, 800);
    }
  }
};
</script>

<style scoped></style>
