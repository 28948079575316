<template>
  <transition name="modal">
    <div class="modal-mask" :class="classList">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <slot name="header"></slot>
            <span @click="$emit('close')">
              <i class="fas fa-times"></i>
            </span>
          </div>

          <div class="modal-body">
            <slot name="body">
              default body
            </slot>
          </div>

          <div class="modal-footer">
            <slot name="footer">
              default footer
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "ModalSkeleton2",
  props: {
    classList: {
      type: [Array, Object, String],
      default: ""
    }
  }
};
</script>

<style lang="scss">
.absolute-modal {
  position: absolute !important;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.22);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-header {
  display: flex;
  justify-content: flex-end;
  & span {
    cursor: pointer;
    color: #fff;
  }
}

.modal-footer {
  display: flex;
  justify-content: center;
}

.modal-container {
  height: auto;
  margin: 0 auto;
  padding: 20px 30px;
  background-color: #46494e;
  border-radius: 13px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-body {
  margin: 20px 0;
  text-align: center;
  font-size: 28px;
  font-weight: 400;
  color: #fff !important;
  & h3 {
    color: #fff !important;
  }
}

@media screen and (max-width: 768px) {
  .modal-container {
    width: 100%;
  }
}

@media screen and (min-width: 769px) {
  .modal-container {
    width: 500px;
  }
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
