<template>
  <fragment>
    <site-header />
    <router-view :key="$route.fullPath"></router-view>
    <site-footer />
  </fragment>
</template>

<script>
import SiteHeader from "@/components/page/SiteHeader";
import SiteFooter from "@/components/page/SiteFooter";
export default {
  name: "VideoIndex",
  components: { SiteFooter, SiteHeader }
};
</script>

<style scoped></style>
