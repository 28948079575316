import "es6-promise/auto";
import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";

import moduleVideos from "./videos";
import moduleSignup from "./signup";
import moduleCategory from "./category";
import moduleSpeaker from "./speaker";
import moduleArticle from "./article";
import moduleEvents from "./events";
import moduleCerts from "./certs";

const ls = new SecureLS({ isCompression: false });

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      paths: ["r"],
      storage: {
        getItem: key => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: key => ls.remove(key)
      }
    })
  ],
  modules: {
    v: moduleVideos,
    r: moduleSignup,
    c: moduleCategory,
    s: moduleSpeaker,
    a: moduleArticle,
    e: moduleEvents,
    ce: moduleCerts
  }
});
