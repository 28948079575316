<template>
  <div class="pagination">
    <a href="#" @click.prevent="back" :disabled="currentPage < 1">
      Назад
    </a>
    <a href="#" @click.prevent="forward" :disabled="length < 16">Вперед</a>
    Текущая страница: {{ currentPage }}
  </div>
</template>

<script>
export default {
  name: "Paginate",
  props: {
    page: Number,
    length: {
      type: Number,
      default: 1
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.page;
      },
      set(n) {
        this.$emit("update:page", n);
      }
    }
  },
  methods: {
    back() {
      if (this.currentPage > 0) {
        this.currentPage--;
      }
      if (this.currentPage < 1) {
        this.currentPage = 1;
      }
      this.$emit("pageChanged");
    },
    forward() {
      if (this.length >= 16) {
        this.currentPage++;
      } else {
        this.currentPage = 1;
      }
      this.$emit("pageChanged");
    }
  }
};
</script>

<style scoped>
.pagination {
  margin-top: 10px;
  padding: 5px;
}
</style>
