<template>
  <state-item item-class="news-podpiska-items">
    <div
      :class="['news-podpiska-wrapper', 'bg-podpiska' + data.cover.toString()]"
    >
      <h4>Подписка<br />{{ data.type }}</h4>
      <div class="news-podpiska-pre" v-html="data.pre"></div>
      <list :data="data.list" />
      <div class="news-podpiska-price">
        {{ data.price }} ₽ / <strong>{{ data.type }}</strong>
        <span v-if="data.next">{{ data.next }}</span>
      </div>
      <div class="news-podpiska-link">
        <router-link
          :to="{ name: 'Payment', query: { goodId: data.id, payment: 'sub' } }"
          class="link-podpiska"
        >
          Подключить подписку
        </router-link>
      </div>
    </div>
  </state-item>
</template>

<script>
import StateItem from "@/components/page/slick/StateItem";
import List from "@/components/page/items/list/List";
export default {
  name: "SubItem",
  components: { StateItem, List },
  props: {
    data: Object
  }
};
</script>

<style scoped></style>
