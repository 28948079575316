<template>
  <show-more-block
    head-name="Ближайшие мероприятия"
    :show-more="showMore"
    :update-list="updateEvents"
    :page="page"
    section-class="wrapper-color"
    :init-size="data.length"
    v-if="data.length"
  >
    <events :media="data" />
    <div class="clear" />
  </show-more-block>
</template>

<script>
import ShowMoreBlock from "@/components/page/items/list/ShowMoreBlock";
import Events from "@/components/page/events/Events";
export default {
  name: "EventBlock",
  components: { Events, ShowMoreBlock },
  props: {
    data: Array,
    showMore: {
      type: Boolean,
      default: true
    },
    page: {
      type: Number,
      default: 1
    },
    updateEvents: Function
  }
};
</script>

<style scoped></style>
