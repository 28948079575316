<template>
  <div class="fields">
    <vue-form-generator
      :schema="fields"
      :model="formData"
      :options="formOptions"
    />
    <modal-alert
      :alert-text="errorText"
      action-text="Продолжить"
      :action-modal="closeAlert"
      :show-modal.sync="showAlert"
    />
  </div>
</template>

<script>
import { component as VueFormGenerator } from "vue-form-generator";
import ModalAlert from "@/components/page/modals/forms/ModalAlert";

export default {
  name: "CreateCert",
  components: { VueFormGenerator, ModalAlert },
  props: {
    schema: Object,
    formOptions: Object
  },
  data() {
    return {
      showAlert: false,
      errorText: "",
      formData: {
        name: "СЕРТИФИКАТ",
        cover: 1,
        list: []
      }
    };
  },
  computed: {
    fields() {
      return {
        groups: [
          ...this.schema.groups,
          {
            fields: [
              {
                type: "submit",
                buttonText: "Создать сертификат",
                onSubmit: this.createCert,
                validateBeforeSubmit: true
              }
            ]
          }
        ]
      };
    }
  },
  methods: {
    createCert() {
      this.$http
        .post("/certificate/create", this.formData)
        .then(response => {
          const data = response.data;
          this.$emit("create");
          console.log(data);
        })
        .catch(error => {
          console.log(error);
          this.errorText = "Введите корректные данные!";
          this.showAlert = true;
        });
    },
    closeAlert() {
      this.showAlert = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~vue-form-generator/dist/vfg.css";
@import "~vue-multiselect/dist/vue-multiselect.min.css";
</style>

<style lang="scss">
.multiselect__option--highlight {
  background: #4849ea;
  outline: none;
  color: #fff;

  &:after {
    background: #4849ea;
  }
}

.multiselect__tag {
  background: #4849ea;
}

.multiselect__tag-icon {
  &:hover {
    background: #2b3bff;
  }
  &:after {
    color: #fff;
  }
}
</style>
