<template>
  <div class="search-result">
    <h4>Результаты поиска</h4>
    <search-item v-for="item in data" :item="item" v-bind:key="item.id" />
    <router-link
      class="more-search-result"
      :to="{ name: 'SearchResult', params: { data: searchWord } }"
    >
      Показать все результаты
    </router-link>
  </div>
</template>

<script>
import SearchItem from "@/components/page/search/items/SearchItem";
export default {
  name: "SearchItems",
  components: { SearchItem },
  props: {
    data: Array,
    searchWord: String
  }
};
</script>

<style scoped></style>
