<template>
  <div id="app">
    <router-view></router-view>
    <vue-progress-bar />
    <modal-alert
      alert-text="Контент доступен только по подписке"
      action-text="Подключить подписку"
      :action-modal="subscribe"
      :show-modal.sync="showModals"
    />
  </div>
</template>

<script>
import ModalAlert from "@/components/page/modals/forms/ModalAlert";
export default {
  name: "App",
  components: { ModalAlert },
  data() {
    return {
      showModals: false,
      modalClass: ""
    };
  },
  provide() {
    const alertModalInject = {};
    Object.defineProperty(alertModalInject, "show", {
      enumerable: true,
      get: () => this.showModals,
      set: n => {
        this.showModals = n;
      }
    });
    Object.defineProperty(alertModalInject, "class", {
      enumerable: true,
      get: () => this.modalClass,
      set: n => {
        this.modalClass = n;
      }
    });
    return { alertModalInject };
  },
  methods: {
    subscribe() {
      this.$router.push({ name: "Subscriptions" }).then(() => {
        this.showModals = false;
      });
    }
  }
};
</script>

<style lang="scss">
@import "./assets/style.css";
</style>
