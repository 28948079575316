<template>
  <div class="ballance-wrapper">
    <slot name="header"></slot>
    <slot name="top"></slot>
    <div class="ballance-tab-nav" role="tablist">
      <a
        v-show="tab.isVisible"
        v-for="(tab, key) in tabs"
        v-bind:key="key"
        :href="tab.hash"
        :aria-selected="tab.isVisible"
        :class="{ active: tab.isActive, disabled: tab.isDisabled }"
        :aria-controls="tab.hash"
        class="tab-nav-card"
        role="tab"
        @click="selectTab(tab.hash, $event)"
      >
        <img
          :id="tab.id"
          :class="{ 'tab-image': !tab.nameVisible }"
          :src="tab.image"
          :alt="tab.id"
        />
        <span v-if="tab.nameVisible" v-html="tab.header"></span>
      </a>
    </div>
    <div class="wrapper-tab-ballance" :class="activeTabClassWrapper">
      <slot name="content"></slot>
    </div>
    <div class="wrapper-tab-promo">
      <slot name="promo"></slot>
    </div>
  </div>
</template>

<script>
import tabCache from "@/store/util/tabCache";
export default {
  name: "Tabs",
  props: {
    cacheLifeTime: {
      default: 5
    },
    options: {
      type: Object,
      required: false,
      default: () => ({
        useUrlFragment: true,
        defaultTabHash: null
      })
    }
  },
  data() {
    return {
      tabs: [],
      activeTabHash: "",
      activeTabIndex: 0,
      activeTabClassWrapper: "",
      lastActiveTabHash: ""
    };
  },
  computed: {
    storageKey() {
      return `thelak-component.cache.${window.location.host}${window.location.pathname}`;
    }
  },
  created() {
    this.tabs = this.$children;
  },
  mounted() {
    window.addEventListener("hashchange", () =>
      this.selectTab(window.location.hash)
    );

    if (this.findTab(window.location.hash)) {
      this.selectTab(window.location.hash);
      return;
    }

    const previousSelectedTabHash = tabCache.get(this.storageKey);

    if (this.findTab(previousSelectedTabHash)) {
      this.selectTab(previousSelectedTabHash);
      return;
    }

    if (
      this.options.defaultTabHash !== null &&
      this.findTab("#" + this.options.defaultTabHash)
    ) {
      this.selectTab("#" + this.options.defaultTabHash);
      return;
    }

    if (this.tabs.length) {
      this.selectTab(this.tabs[0].hash);
    }
  },
  methods: {
    findTab(hash) {
      return this.tabs.find(tab => tab.hash === hash);
    },
    getTabIndex(hash) {
      const tab = this.findTab(hash);
      return this.tabs.indexOf(tab);
    },
    selectTab(selectedTabHash, event) {
      if (event && !this.options.useUrlFragment) {
        event.preventDefault();
      }
      const selectedTab = this.findTab(selectedTabHash);
      if (!selectedTab) {
        return;
      }

      if (selectedTab.isDisabled) {
        event.preventDefault();
        return;
      }

      if (this.lastActiveTabHash === selectedTab.hash) {
        this.$emit("clicked", { tab: selectedTab });
        return;
      }

      this.tabs.forEach(tab => {
        tab.isActive = tab.hash === selectedTab.hash;
      });
      this.$emit("changed", { tab: selectedTab });
      this.activeTabHash = selectedTab.hash;
      this.activeTabIndex = this.getTabIndex(selectedTabHash);
      this.activeTabClassWrapper = selectedTab.classWrapper;
      this.lastActiveTabHash = this.activeTabHash = selectedTab.hash;
      tabCache.set(this.storageKey, selectedTab.hash, this.cacheLifeTime);
    },
    setTabVisible(hash, visible) {
      const tab = this.findTab(hash);

      if (!tab) {
        return;
      }

      tab.isVisible = visible;

      if (tab.isActive) {
        tab.isActive = visible;

        this.tabs.every(tab => {
          if (tab.isVisible) {
            tab.isActive = true;
            return false;
          }

          return true;
        });
      }
    },
    getTabHash(index) {
      const tab = this.tabs.find(tab => this.tabs.indexOf(tab) === index);

      if (!tab) {
        return;
      }

      return tab.hash;
    },
    getActiveTab() {
      return this.findTab(this.activeTabHash);
    },
    getActiveTabIndex() {
      return this.getTabIndex(this.activeTabHash);
    }
  }
};
</script>

<style scoped></style>
