<template>
  <slick ref="slick" :options="slickOptions" :class="moduleClass">
    <state-item
      v-for="tile in moduleData"
      item-class="news-tovar-items"
      v-bind:key="tile.id"
    >
      <a
        :style="{
          background: 'url(' + require('@/assets/img/podpiska1.png') + ')'
        }"
        href="/"
        class="tovar-items-img"
      ></a>
      <tags :tags="tile.tags" />
      <h4>
        <a href="/" v-html="tile.name"></a>
      </h4>
      <div class="tovar-items-status">
        <span :class="['items-status-circle', tile.status]"></span>
        <span class="items-status-text">{{ tile.type }}</span>
      </div>
    </state-item>
  </slick>
</template>

<script>
import StateItem from "@/components/page/slick/StateItem";
import Slick from "vue-slick";
import Tags from "@/components/page/items/tags/Tags";

export default {
  name: "Module",
  props: {
    moduleClass: {
      type: String,
      default: "slider-news-modul"
    },
    slidesToShow: {
      type: Number,
      default: 3
    },
    moduleData: {
      type: Array
    }
  },
  components: { Tags, StateItem, Slick },
  data() {
    return {
      slickOptions: {
        infinite: true,
        dots: false,
        arrows: true,
        slidesToShow: this.slidesToShow,
        slidesToScroll: 1,
        rows: 0,
        prevArrow:
          "<div class='slick-prev-states'><img src='" +
          this.arrowUrl("right") +
          "' alt='' /></div>",
        nextArrow:
          "<div class='slick-next-states'><img src='" +
          this.arrowUrl("left") +
          "' alt='' /></div>",
        responsive: [
          {
            breakpoint: 760,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 450,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      }
    };
  },
  methods: {
    arrowUrl(arrow) {
      return require("@/assets/img/arrow-" + arrow + ".svg");
    },
    next() {
      this.$refs.slick.next();
    },
    prev() {
      this.$refs.slick.prev();
    },
    reInit() {
      this.$nextTick(() => {
        this.$refs.slick.reSlick();
      });
    }
  }
};
</script>

<style scoped></style>
