<template>
  <linked-block
    header="Спикеры"
    section-class="news-states"
    link-to="/speakers"
    v-if="data && data.length"
  >
    <speakers :speakers="data" />
  </linked-block>
</template>

<script>
import Speakers from "@/components/page/slick/Speakers";
import LinkedBlock from "@/components/page/main/LinkedBlock";
export default {
  name: "SpeakerBlock",
  components: { LinkedBlock, Speakers },
  props: {
    data: Array
  }
};
</script>

<style scoped></style>
