import util from "@/store/util";
const moduleEvents = {
  state: {
    events: [],
    eventsFiltered: []
  },
  getters: {
    events: state => state.events.map(util.getters.uuidGetter),
    eventsFiltered: state => state.eventsFiltered.map(util.getters.uuidGetter)
  },
  mutations: {
    setEvents: util.mutations.setData,
    appendEvents: util.mutations.appendData
  },
  actions: {
    getEvents({ dispatch }) {
      return dispatch("getEventsWithFilter", {
        method: "/event/list",
        type: "events",
        allItems: true
      });
    },
    getPageEvents({ dispatch }, page) {
      return dispatch("getEventsWithFilter", {
        method: "/event/list",
        type: "events",
        commitMethod: "appendEvents",
        params: {
          size: 10,
          page
        }
      });
    },
    getMonthEvents({ dispatch }, payload) {
      return dispatch("getEventsWithFilter", {
        method: "/event/list",
        type: "eventsFiltered",
        commitMethod: "appendEvents",
        allItems: payload.allItems,
        params: {
          startDate: new Date(
            payload.data.year,
            payload.data.month - 1,
            1,
            0,
            0,
            0
          ).toISOString(),
          endDate: new Date(
            payload.data.year,
            payload.data.month,
            1,
            0,
            0,
            0
          ).toISOString(),
          size: 1000
        }
      });
    },
    getEventsWithFilter({ commit }, data) {
      if (!data.commitMethod) {
        data.commitMethod = "setEvents";
      }
      return util.methods.getFilteredObjects(commit, data, {
        flushMethod: "setEvents"
      });
    }
  }
};

export default moduleEvents;
