<template>
  <site-section :section-class="sectionClass">
    <template v-slot:header v-if="headName">
      <h2>{{ headName }}</h2>
    </template>
    <template v-slot:description>
      <slot></slot>
    </template>
    <template v-if="showMoreButton" v-slot:content>
      <a
        @click.prevent="updateListData"
        href="#"
        class="link-more-tovar-other wow fadeInUp"
      >
        Показать еще
      </a>
    </template>
  </site-section>
</template>

<script>
import SiteSection from "@/components/page/SiteSection";
export default {
  name: "ShowMoreBlock",
  components: { SiteSection },
  data() {
    return {
      hasShowMore: true,
      dataLength: this.size
    };
  },
  props: {
    headName: {
      type: String,
      default: ""
    },
    sectionClass: {
      type: [Object, Array, String],
      default: "news-mediateka-inner"
    },
    additionalFilter: {
      type: Object,
      default: () => ({})
    },
    showMore: {
      type: Boolean,
      default: true
    },
    size: {
      type: Number,
      default: 10
    },
    initSize: {
      type: Number
    },
    page: {
      type: Number,
      default: 1
    },
    updateList: Function,
    sort: {
      type: String,
      default: ""
    },
    sortType: {
      type: String,
      default: ""
    }
  },
  computed: {
    showMoreButton() {
      return this.showMore && this.hasShowMore;
    }
  },
  methods: {
    updateListData() {
      this.updateList(
        this.sort,
        this.sortType,
        this.page + 1,
        this.additionalFilter
      )
        .then(data => {
          this.dataLength = data.length;
        })
        .catch(() => {
          this.hasShowMore = false;
          this.dataLength = 0;
        });
    }
  },
  updated() {
    this.hasShowMore =
      this.initSize >= this.size && this.dataLength >= this.size;
  }
};
</script>

<style scoped></style>
