<template>
  <linked-block
    section-class="news-states"
    link-to="/articles"
    header="Статьи"
    v-if="data.length"
  >
    <articles :articles-to-show="articlesToShow" :tiles="data" />
  </linked-block>
</template>

<script>
import Articles from "@/components/page/slick/Articles";
import LinkedBlock from "@/components/page/main/LinkedBlock";
export default {
  name: "ArticlesBlock",
  components: { LinkedBlock, Articles },
  props: {
    data: {
      type: Array,
      default() {
        return [];
      }
    },
    articlesToShow: {
      type: Number,
      default: 4
    }
  }
};
</script>

<style scoped></style>
