import util from "@/store/util";
const moduleCerts = {
  state: {
    certs: [],
    subs: [],
    promos: []
  },
  getters: {
    certs: state => state.certs.map(util.getters.uuidGetter),
    subs: state => state.subs.map(util.getters.uuidGetter),
    promos: state => state.promos.map(util.getters.uuidGetter)
  },
  mutations: {
    setCerts: util.mutations.setData,
    setSubs: util.mutations.setData,
    setPromos: util.mutations.setData
  },
  actions: {
    getPromos({ dispatch }) {
      return dispatch("getTariffsWithFilter", {
        method: "/promo/list",
        type: "promos",
        commitMethod: "setPromos",
        flushMethod: "setPromos",
        allItems: true
      });
    },
    getCerts({ dispatch }) {
      return dispatch("getTariffsWithFilter", {
        method: "/certificate/list",
        type: "certs",
        commitMethod: "setCerts",
        flushMethod: "setCerts",
        allItems: true
      });
    },
    getSubs({ dispatch }) {
      return dispatch("getTariffsWithFilter", {
        method: "/subscription/list",
        type: "subs",
        commitMethod: "setSubs",
        flushMethod: "setSubs",
        allItems: true
      });
    },
    getTariffsWithFilter({ commit }, data) {
      return util.methods.getFilteredObjects(commit, data, {
        flushMethod: data.flushMethod
      });
    }
  }
};

export default moduleCerts;
