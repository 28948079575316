const SVS_B = (eAmt, where) => {
  switch (where) {
    case "center":
    case "":
      window.scrollBy(0, eAmt / 2);
      break;
    case "top":
      window.scrollBy(0, eAmt);
  }
};

const isSafari = () => {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
};

export const smoothVerticalScroll = (e, time, where) => {
  if (isSafari()) {
    const eTop = e.getBoundingClientRect().top;
    const eAmt = eTop / 100;
    let curTime = 0;
    while (curTime <= time) {
      window.setTimeout(SVS_B, curTime, eAmt, where);
      curTime += time / 100;
    }
  } else {
    window.scrollTo({ top: e.offsetTop - 20, left: 0, behavior: "smooth" });
  }
};
