<template>
  <section :class="sectionClass">
    <div class="inner-content">
      <div :class="[headerClass, 'wow', 'fadeInUp']" v-if="showHead">
        <slot name="header"></slot>
      </div>
      <slot name="description"></slot>
      <slot name="content"></slot>
    </div>
  </section>
</template>

<script>
export default {
  name: "SiteSection",
  props: {
    sectionClass: String,
    headerClass: {
      type: String,
      default: "flex-header"
    },
    showHead: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<style scoped></style>
