<template>
  <site-section section-class="slideshow-main">
    <template v-slot:content v-if="data && data.length">
      <slide-show :media="data" />
    </template>
  </site-section>
</template>

<script>
import SiteSection from "@/components/page/SiteSection";
import SlideShow from "@/components/page/slick/SlideShow";
export default {
  name: "SlideShowBlock",
  components: { SlideShow, SiteSection },
  props: {
    data: Array
  }
};
</script>

<style scoped></style>
