<template>
  <site-section section-class="news-tovar">
    <template v-slot:header>
      <h2>Подписка</h2>
    </template>
    <template v-slot:description>
      <p class="pre-flex-header wow fadeInUp">
        Более тысячи часов развивающего видеоконтента в Full HD качестве
        <br />и статьи по бизнес-тематике по стоимости дешевле, чем посещение
        одного мероприятия.
      </p>
    </template>
    <template v-slot:content>
      <subscription :tiles="tiles" v-if="tiles && tiles.length" />
    </template>
  </site-section>
</template>

<script>
import Subscription from "@/components/page/slick/Subscription";
import SiteSection from "@/components/page/SiteSection";

export default {
  name: "SubscriptionBlock",
  components: { Subscription, SiteSection },
  props: {
    tiles: Array
  }
  // data() {
  //   return {
  //     tiles: [
  //       {
  //         id: 0,
  //         type: "1 день",
  //         price: 1,
  //         pre: "Более 1000+ видео контента",
  //         next: "Далее 990 ₽ в месяц",
  //         list: [
  //           "Статьи и видео по бизнес тематике",
  //           "Лучшее качество записи",
  //           "Просмотр на любом устройстве",
  //           "Отличный подарок"
  //         ],
  //         cover: 1
  //       },
  //       {
  //         id: 1,
  //         type: "1 месяц",
  //         price: 990,
  //         pre: "Более 1000+ видео контента",
  //         next: "1 день за 1 ₽ / далее 990 ₽ в месяц",
  //         list: [
  //           "Статьи и видео по бизнес тематике",
  //           "Лучшее качество записи",
  //           "Просмотр на любом устройстве",
  //           "Отличный подарок"
  //         ],
  //         cover: 1
  //       },
  //       {
  //         id: 2,
  //         type: "1 год",
  //         price: 5990,
  //         pre: "Более 1000+ видео контента",
  //         next: "Месяц за 430 ₽ / – 50%",
  //         list: [
  //           "Статьи и видео по бизнес тематике",
  //           "Лучшее качество записи",
  //           "Просмотр на любом устройстве",
  //           "Отличный подарок"
  //         ],
  //         cover: 1
  //       },
  //       {
  //         id: 3,
  //         type: "1 день",
  //         price: 1,
  //         pre: "Более 1000+ видео контента",
  //         next: "Далее 990 ₽ в месяц",
  //         list: [
  //           "Статьи и видео по бизнес тематике",
  //           "Лучшее качество записи",
  //           "Просмотр на любом устройстве",
  //           "Отличный подарок"
  //         ],
  //         cover: 1
  //       },
  //       {
  //         id: 4,
  //         type: "1 день",
  //         price: 1,
  //         pre: "Более 1000+ видео контента",
  //         next: "Далее 990 ₽ в месяц",
  //         list: [
  //           "Статьи и видео по бизнес тематике",
  //           "Лучшее качество записи",
  //           "Просмотр на любом устройстве",
  //           "Отличный подарок"
  //         ],
  //         cover: 1
  //       }
  //     ]
  //   };
  // }
};
</script>

<style scoped></style>
