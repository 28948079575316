<template>
  <ul>
    <li><router-link to="/promocode">Промокод</router-link></li>
    <li><router-link to="/novelty">Новинки</router-link></li>
    <li><router-link to="/library">Медиатека</router-link></li>
    <li><router-link to="/events">Мероприятия</router-link></li>
    <li><router-link to="/articles">Статьи</router-link></li>
  </ul>
</template>

<script>
export default {
  name: "TopFooterMenu"
};
</script>

<style scoped></style>
