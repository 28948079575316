import { render, staticRenderFns } from "./SlideShowBlock.vue?vue&type=template&id=68b7efbf&scoped=true&"
import script from "./SlideShowBlock.vue?vue&type=script&lang=js&"
export * from "./SlideShowBlock.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../../../usr/local/lib/node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68b7efbf",
  null
  
)

export default component.exports