<template>
  <div class="search-result-flex">
    <sub-link
      :to="{ name: 'VideoById', params: { id: item.id } }"
      class-list="result-item-link"
      :condition="getCondition(item.subscription)"
    >
      <img class="result-item" :src="item.coverUrl" :alt="item.title" />
    </sub-link>
    <sub-link
      class-list="result-item-link"
      :to="{ name: 'VideoById', params: { id: item.id } }"
      :condition="getCondition(item.subscription)"
    >
      {{ item.title }}
      <span>
        <i
          class="fas fa-star"
          v-for="i in item.rating"
          v-bind:key="i + 'v'"
        ></i>
        <i
          class="fas fa-star active"
          v-for="i in 10 - item.rating"
          v-bind:key="i + 'c'"
        ></i>
      </span>
    </sub-link>
  </div>
</template>

<script>
import SubLink from "@/components/page/subscription/links/SubLink";
export default {
  name: "SearchItem",
  components: { SubLink },
  props: {
    item: Object
  },
  methods: {
    getCondition(isSubscription) {
      return () => {
        if (!isSubscription) {
          return true;
        }
        const user = this.$auth.user();
        return (
          !!user &&
          user.isSubscribe &&
          new Date(user.subscriptionDate).getTime() > new Date().getTime()
        );
      };
    }
  }
};
</script>

<style scoped>
.result-item {
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 5px;
  padding: 5px;
}
.result-item-link {
  opacity: 1;
  font-size: 1rem;
  transition: all 0.5s ease;
}
</style>
