<template>
  <div class="fields">
    <vue-form-generator
      :schema="fields"
      :model="formData"
      :options="formOptions"
    />
    <modal-alert
      :alert-text="errorText"
      action-text="Продолжить"
      :action-modal="closeAlert"
      :show-modal.sync="showAlert"
    />
  </div>
</template>

<script>
import ModalAlert from "@/components/page/modals/forms/ModalAlert";
import { component as VueFormGenerator } from "vue-form-generator";
export default {
  name: "SendUsers",
  props: {
    schema: Object,
    formOptions: Object
  },
  data() {
    return {
      showAlert: false,
      errorText: "",
      formData: {}
    };
  },
  computed: {
    fields() {
      return {
        groups: [
          ...this.schema.groups,
          {
            fields: [
              {
                type: "submit",
                buttonText: "Сделать рассылку",
                onSubmit: this.sendEmails,
                validateBeforeSubmit: true
              }
            ]
          }
        ]
      };
    }
  },
  components: { ModalAlert, VueFormGenerator },
  methods: {
    sendEmails() {
      this.$http
        .post("/email/all", this.formData)
        .then(response => {
          const data = response.data;
          this.showAlert = true;
          this.errorText = data
            ? "Рассылка успешно отправлена!"
            : "Введите корректные данные!";
        })
        .catch(error => {
          console.log(error);
          this.errorText = "Произошла ошибка при рассылке";
          this.showAlert = true;
        });
    },
    closeAlert() {
      this.showAlert = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~vue-form-generator/dist/vfg.css";
@import "~vue-multiselect/dist/vue-multiselect.min.css";
</style>
