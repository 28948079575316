<template>
  <div id="reg-window3">
    <h2>Восстановление пароля</h2>
    <p class="reg-pre">Введите новый пароль</p>
    <validation-observer ref="observer">
      <form @submit.prevent="recoverPassword">
        <div id="reg-step3">
          <validation-provider
            name="Пароль"
            rules="length:6|required|confirmed:confirmation"
            tag="div"
            class="input-reg-area"
            v-slot="{ errors, reset }"
          >
            <input
              :type="togglePassword ? 'text' : 'password'"
              v-model="formData.password"
              placeholder="Пароль"
              :class="errors.length ? 'error-input' : ''"
              @click="reset"
              required
            />
            <error-pop-over
              :reset-function="reset"
              :errors="errors"
              default-text="Не менее 6 символов"
            />
            <a
              href="#"
              tabindex="-1"
              @click.prevent="togglePassword = !togglePassword"
            >
              <i class="fas fa-eye"></i>
            </a>
          </validation-provider>
          <validation-provider
            name="Подтверждение пароля"
            rules="required|length:6"
            tag="div"
            class="input-reg-area"
            vid="confirmation"
            v-slot="{ errors, reset }"
          >
            <input
              :type="togglePassword ? 'text' : 'password'"
              v-model="formData.confirm_password"
              placeholder="Повторите пароль"
              :class="errors.length ? 'error-input' : ''"
              required
              @click="reset"
            />
            <error-pop-over
              :reset-function="reset"
              :errors="errors"
              default-text="Не менее 6 символов"
            />
            <a
              href="#"
              tabindex="-1"
              @click.prevent="togglePassword = !togglePassword"
            >
              <i class="fas fa-eye"></i>
            </a>
          </validation-provider>
          <div class="input-reg-submit">
            <input type="submit" value="Восстановить" />
          </div>
        </div>
      </form>
    </validation-observer>
    <modal-alert
      :show-modal.sync="showAlert"
      :action-modal="goToLogin"
      :alert-text="alertText"
      action-text="Продолжить"
    />
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { messages } from "vee-validate/dist/locale/ru.json";
import { confirmed, required, min } from "vee-validate/dist/rules";
import ErrorPopOver from "@/components/page/items/error/ErrorPopOver";
import ModalAlert from "@/components/page/modals/forms/ModalAlert";

extend("required", {
  ...required,
  message: messages["required"]
});

extend("confirmed", {
  ...confirmed,
  message: messages["confirmed"]
});

extend("length", {
  ...min,
  message: messages["min"]
});

export default {
  name: "RecoverPassword",
  components: {
    ValidationObserver,
    ValidationProvider,
    ErrorPopOver,
    ModalAlert
  },
  props: ["uuid"],
  data() {
    return {
      togglePassword: false,
      showAlert: false,
      alertText: "",
      formData: {
        password: "",
        confirm_password: ""
      }
    };
  },
  methods: {
    recoverPassword() {
      this.$http
        .post("/auth/restore/confirm", {
          ...this.formData,
          uuid: this.uuid
        })
        .then(response => {
          const data = response.data;
          this.alertText = data
            ? "Пароль успешно восстановлен!"
            : "Произошла ошибка";
          this.showAlert = true;
        });
    },
    goToLogin() {
      this.$router.push({ name: "login" });
    }
  },
  created() {
    if (!this.uuid) {
      this.goToLogin();
    }
  }
};
</script>

<style scoped></style>
