<template>
  <div class="radio-btn-group">
    <div class="radio" v-for="(item, key) in items" v-bind:key="key">
      <input
        type="radio"
        :name="item.name"
        :id="item.name + key"
        :value="item.value"
        v-model="radioModel"
      />
      <label :for="item.name + key">
        {{ item.text }}
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: "RadioBtnGroup",
  props: {
    model: {
      type: [Number, String, Object]
    },
    items: Array
  },
  computed: {
    radioModel: {
      get() {
        return this.model;
      },
      set(n) {
        this.$emit("update:model", n);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.radio-btn-group {
  display: flex;
  width: 100%;
  margin: 0 0 25px;
  overflow: auto;

  .radio {
    margin-right: 0.5rem;
    flex: 0 1 auto;
    label {
      background: transparent;
      border: 1px solid #46494e;
      padding: 0.75rem 1.5rem;

      @media screen and (max-width: 321px) {
        padding: 0.75rem 0.8rem;
      }

      @media screen and (max-width: 376px) {
        padding: 0.75rem 1.35rem;
      }

      @media screen and (max-width: 415px) {
        padding: 0.8rem 1.75rem;
      }

      border-radius: 5px;
      white-space: nowrap;
      text-transform: lowercase;
      cursor: pointer;
      color: #fff;
      transition: box-shadow 400ms ease;
    }
    input[type="radio"] {
      display: none;
    }
    input[type="radio"]:checked + label {
      background: #46494e;
      color: #fff;
      border-color: #46494e;
    }
  }
}
</style>
