<template>
  <div class="row">
    <div class="col-xl-12 col-lg-12 col-sm-12 w100">
      <div class="widget-content widget-content-area">
        <h2>Статьи</h2>
        <table-view
          :heads="heads"
          :items.sync="articles"
          :fields="schema"
          edit-head="Редактировать статью"
          obtain-method="/article/get"
          update-method="/article/update"
          delete-method="/article/delete"
          @updated="loadArticles"
          :form-options="formOptions"
          :page.sync="page"
        />
      </div>

      <div class="widget-content widget-content-area">
        <h2>Добавить статью</h2>
        <create-article
          :schema="schema"
          :form-options="formOptions"
          @create="loadArticles"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CreateArticle from "@/views/admin/articles/CreateArticle";
import TableView from "@/components/admin/lists/TableView";
import { uuid } from "vue-uuid";
import { validators } from "vue-form-generator";

export default {
  name: "AdminArticles",
  data() {
    return {
      heads: {
        id: {
          name: "ID"
        },
        title: {
          name: "Название"
        },
        coverUrl: {
          name: "Обложка",
          type: "picture"
        },
        viewsCount: {
          name: "Число просмотров"
        }
      },
      categoryTimeout: null,
      formOptions: {
        validateAfterLoad: false,
        validateAfterChanged: true,
        validateAsync: true
      },
      page: 1
    };
  },
  props: {
    searchParams: {
      type: [String, Object],
      default: ""
    }
  },
  components: { TableView, CreateArticle },
  computed: {
    ...mapGetters(["articles", "categories"]),
    schema() {
      return {
        groups: [
          {
            legend: "Основная",
            fields: [
              {
                type: "input",
                inputType: "text",
                label: "Название",
                model: "title",
                required: true,
                validator: validators.required,
                placeholder: "Awesome article",
                id: `${uuid.v1()}title`
              },
              {
                type: "textArea",
                label: "Описание",
                model: "description",
                required: true,
                validator: validators.required,
                placeholder: "Обычное описание к статье"
              },
              {
                type: "textArea",
                label: "Контент",
                model: "content",
                required: true,
                validator: validators.required,
                placeholder: "<p>Крутой контент</p>"
              },
              {
                type: "input",
                inputType: "text",
                label: "Автор",
                model: "author",
                required: true,
                validator: validators.required,
                placeholder: "Аркадий Паровозов",
                id: `${uuid.v1()}author`
              },
              {
                type: "input",
                inputType: "text",
                label: "Источник",
                model: "sourceUrl",
                required: true,
                validator: validators.required,
                placeholder: "Ссылка",
                id: `${uuid.v1()}sourceUrl`
              },
              {
                type: "vueMultiSelect",
                label: "Категории",
                model: "categories",
                required: false,
                placeholder: "Введите категорию",
                selectOptions: {
                  trackBy: "id",
                  label: "title",
                  multiple: true,
                  searchable: true,
                  internalSearch: false,
                  clearOnSelect: false,
                  closeOnSelect: false,
                  // eslint-disable-next-line no-unused-vars
                  onSearch: (searchQuery, id, options) => {
                    if (searchQuery.length === 0) {
                      options = [];
                    } else {
                      if (this.categoryTimeout) {
                        clearTimeout(this.categoryTimeout);
                      }
                      this.categoryTimeout = setTimeout(() => {
                        this.loadCategories(searchQuery).then(data => {
                          options = data;
                        });
                      }, 1e3);
                    }
                  }
                },
                onChanged(model, newVal) {
                  model.categories = newVal;
                },
                values: () => {
                  return this.categories;
                }
              }
            ]
          },
          {
            legend: "Фото",
            fields: [
              {
                type: "input",
                inputType: "text",
                label: "Обложка",
                model: "coverUrl",
                required: true,
                validator: validators.required,
                placeholder: "Ссылка",
                id: `${uuid.v1()}coverUrl`
              }
            ]
          }
        ]
      };
    }
  },
  methods: {
    ...mapActions(["getArticlesWithFilter", "getCategoriesByTitle"]),
    loadArticles() {
      this.getArticlesWithFilter({
        method: "/article/search",
        type: "articles",
        params: {
          page: this.page,
          size: 30,
          search: this.searchParams
        }
      });
    },
    loadCategories(query) {
      return this.getCategoriesByTitle(query);
    }
  },
  watch: {
    searchParams(n, o) {
      if (n !== o) {
        this.loadArticles();
      }
    }
  },
  created() {
    this.loadArticles();
    this.loadCategories();
  }
};
</script>

<style scoped></style>
