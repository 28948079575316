<template>
  <div class="row">
    <div class="col-xl-12 col-lg-12 col-sm-12 w100">
      <div class="widget-content widget-content-area">
        <h2>Видео</h2>
        <table-view
          :heads="heads"
          :items.sync="videosTest"
          :fields="schema"
          edit-head="Редактировать видео"
          obtain-method="/video/get"
          update-method="/video/update"
          delete-method="/video/delete"
          :form-options="formOptions"
          @updated="loadVideos"
          :page.sync="page"
        />
      </div>

      <div class="widget-content widget-content-area">
        <h2>Добавить видео</h2>
        <create-video
          :schema="schema"
          :form-options="formOptions"
          @create="loadVideos"
        />
      </div>
    </div>
  </div>
</template>

<script>
// import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import CreateVideo from "@/views/admin/video/CreateVideo";
import TableView from "@/components/admin/lists/TableView";
import { uuid } from "vue-uuid";
import { validators } from "vue-form-generator";

export default {
  name: "AdminVideo",
  components: { TableView, CreateVideo },
  data() {
    return {
      heads: {
        id: {
          name: "ID"
        },
        name: {
          name: "Название"
        },
        status: {
          name: "Подписка"
        }
      },
      page: 1,
      speakerTimeout: null,
      categoryTimeout: null,
      isLoading: false,
      formOptions: {
        validateAfterLoad: false,
        validateAfterChanged: true,
        validateAsync: true
      }
    };
  },
  props: {
    searchParams: {
      type: [String, Object],
      default: ""
    }
  },
  computed: {
    ...mapGetters(["videosTest", "speakers", "categories"]),
    schema() {
      return {
        groups: [
          {
            legend: "Основное",
            fields: [
              {
                type: "input",
                inputType: "text",
                label: "Название",
                model: "title",
                required: true,
                validator: validators.required,
                placeholder: "Awesome BI product distribution",
                id: uuid.v1() + "title"
              },
              {
                type: "vueMultiSelect",
                model: "country",
                label: "Страна",
                placeholder: "Начните писать для поиска",
                required: true,
                validator: validators.required,
                selectOptions: {
                  multiple: false,
                  searchable: true,
                  taggable: true,
                  clearOnSelect: false,
                  closeOnSelect: true,
                  tagPlaceholder: "Добавьте эту страну",
                  onNewTag(newTag, id, options, value) {
                    options.push(newTag);
                    value.push(newTag);
                  }
                },
                onChanged(model, newVal) {
                  console.log("@tag: ", newVal);
                },
                values: ["Россия", "Финляндия", "Португалия"]
              },
              {
                type: "textArea",
                label: "Описание",
                model: "description",
                required: true,
                validator: validators.required,
                placeholder: "Обычное описание к видео"
              },
              {
                type: "input",
                inputType: "number",
                label: "Год видео",
                model: "year",
                required: true,
                min: 1950,
                validator: validators.required,
                placeholder: "2020"
              },
              {
                type: "vueMultiSelect",
                model: "language",
                label: "Язык видео",
                placeholder: "Русский",
                required: true,
                validator: validators.required,
                selectOptions: {
                  multiple: false,
                  searchable: true,
                  taggable: true,
                  clearOnSelect: false,
                  closeOnSelect: true,
                  tagPlaceholder: "Добавьте этот язык",
                  onNewTag(newTag, id, options, value) {
                    options.push(newTag);
                    value.push(newTag);
                  }
                },
                onChanged(model, newVal) {
                  console.log("@tag: ", newVal);
                },
                values: ["Русский", "Английский"]
              },
              {
                type: "input",
                inputType: "number",
                label: "Длительность (в минутах)",
                model: "duration",
                validator: validators.required,
                required: true,
                placeholder: 60
              },
              {
                type: "input",
                inputType: "text",
                label: "Площадка",
                model: "playground",
                validator: validators.required,
                required: true,
                placeholder: "Дом правительства",
                id: uuid.v1() + "playground"
              },
              {
                type: "switch",
                label: "Доступ",
                model: "subscription",
                required: true,
                validator: validators.required,
                multi: true,
                textOn: "По подписке",
                textOff: "Бесплатно",
                default: true
              }
            ]
          },
          {
            legend: "Видео",
            fields: [
              {
                type: "vueMultiSelect",
                model: "sources",
                label: "Исходные",
                placeholder: "Формат ввода '360|ссылка'",
                selectOptions: {
                  multiple: true,
                  trackBy: "res",
                  label: "src",
                  searchable: true,
                  taggable: true,
                  tagPlaceholder: "Добавьте ссылку на видео",
                  customLabel({ label, src }) {
                    const srcN = decodeURI(src);
                    const newLabel = srcN.slice(srcN.lastIndexOf("/") + 1);
                    return `${label} - ...${newLabel.substring(
                      newLabel.length / 2
                    )}`;
                  },
                  onNewTag(newTag, id, options, value) {
                    console.log(newTag, id, options, value);
                    const data = newTag.split("|");
                    if (data.length > 1) {
                      const res = parseInt(data[0].trim());
                      if (!Number.isInteger(res)) {
                        return;
                      }
                      const link = data[1].trim();
                      const tag = {
                        src: link,
                        label: `${res}p`,
                        res: parseInt(res),
                        type: "video/mp4"
                      };
                      console.log(tag);
                      options.push(tag);
                      value.push(tag);
                    }
                  }
                },
                onChanged(model, value) {
                  console.log("@tag: ", value);
                },
                values: []
              },
              {
                type: "vueMultiSelect",
                model: "speaker",
                label: "Спикеры",
                required: false,
                placeholder: "Введите спикера",
                selectOptions: {
                  trackBy: "id",
                  label: "name",
                  multiple: true,
                  searchable: true,
                  internalSearch: false,
                  clearOnSelect: false,
                  closeOnSelect: false,
                  // eslint-disable-next-line no-unused-vars
                  onSearch: (searchQuery, id, options) => {
                    if (searchQuery.length === 0) {
                      options = [];
                    } else {
                      if (this.speakerTimeout) {
                        clearTimeout(this.speakerTimeout);
                      }
                      this.speakerTimeout = setTimeout(() => {
                        this.loadSpeakers(searchQuery).then(data => {
                          options = data;
                        });
                      }, 1e3);
                    }
                  }
                },
                onChanged(model, newVal) {
                  model.speaker = newVal;
                },
                values: () => {
                  return this.speakers;
                }
              },
              {
                type: "input",
                inputType: "text",
                label: "Дата добавления",
                model: "createdDate",
                required: true,
                validator: validators.required,
                placeholder: "2021-01-06T15:16:24.545Z",
                id: `${uuid.v1()}createdDate`
              },
              {
                type: "vueMultiSelect",
                label: "Категории",
                model: "category",
                required: false,
                placeholder: "Введите категорию",
                selectOptions: {
                  trackBy: "id",
                  label: "title",
                  multiple: true,
                  searchable: true,
                  internalSearch: false,
                  clearOnSelect: false,
                  closeOnSelect: false,
                  // eslint-disable-next-line no-unused-vars
                  onSearch: (searchQuery, id, options) => {
                    if (searchQuery.length === 0) {
                      options = [];
                    } else {
                      if (this.categoryTimeout) {
                        clearTimeout(this.categoryTimeout);
                      }
                      this.categoryTimeout = setTimeout(() => {
                        this.loadCategories(searchQuery).then(data => {
                          options = data;
                        });
                      }, 1e3);
                    }
                  }
                },
                onChanged(model, newVal) {
                  model.category = newVal;
                },
                values: () => {
                  return this.categories;
                }
              }
            ]
          },
          {
            legend: "Фото",
            fields: [
              {
                type: "input",
                inputType: "text",
                label: "Основная обложка",
                model: "coverUrl",
                validator: validators.required,
                required: true,
                placeholder:
                  "https://storage.yandexcloud.net/thelak-storage/%D0%9E%D0%B1%D0%BB%D0%BE%D0%B6%D0%BA%D0%B8/%D0%90%D1%82%D0%BB%D0%B0%D0%BD%D1%82%D1%8B_2019_%D0%9E%D0%B1%D0%BB%D0%BE%D0%B6%D0%BA%D0%B8/%D0%A5%D0%B0%D1%80%D1%82%D0%BC%D0%B0%D0%BD%D0%BD%20%D0%9E%D1%81%D0%BA%D0%B0%D1%80.%20Disrupt%20%D0%B1%D0%B8%D0%B7%D0%BD%D0%B5%D1%81-%D0%BC%D0%BE%D0%B4%D0%B5%D0%BB%D0%B8%20%D0%BD%D0%B0%D1%81%D1%82%D0%BE%D1%8F%D1%89%D0%B5%D0%B3%D0%BE%20%D0%B8%D0%BB%D0%B8%20%D0%BA%D0%B0%D0%BA%20%D1%8D%D1%84%D1%84%D0%B5%D0%BA%D1%82%D0%B8%D0%B2%D0%BD%D0%BE%20%D1%82%D1%80%D0%B5%D0%BD%D0%B4%D0%B2%D0%BE%D1%87%D0%B8%D1%82%D1%8C.%20%D0%9E%D0%B1%D0%BB%D0%BE%D0%B6%D0%BA%D0%B0.jpg",
                id: uuid.v1() + "coverUrl"
              },
              {
                type: "input",
                inputType: "text",
                label: "Партнерская обложка",
                model: "partnerLogoUrl",
                required: false,
                placeholder:
                  "https://storage.yandexcloud.net/thelak-storage/%D0%9B%D0%BE%D0%B3%D0%BE%D1%82%D0%B8%D0%BF%D1%8B_%D0%9F%D0%B0%D1%80%D1%82%D0%BD%D1%91%D1%80%D0%BE%D0%B2/%D0%9B%D0%BE%D0%B3%D0%BE%D1%82%D0%B8%D0%BF_%D0%90%D1%82%D0%BB%D0%B0%D0%BD%D1%82%D1%8B_800x800.jpg",
                id: uuid.v1() + "partnerLogoUrl"
              },
              {
                type: "input",
                inputType: "text",
                label: "Постер",
                model: "posterUrl",
                required: false,
                placeholder: "Ссылка",
                id: uuid.v1() + "posterUrl"
              }
            ]
          }
        ]
      };
    }
  },
  methods: {
    ...mapActions([
      "getPageVideosWithFilter",
      "getSpeakersWithFilter",
      "getCategoriesByTitle"
    ]),
    loadVideos() {
      this.getPageVideosWithFilter({
        type: "videosTest",
        method: "/video/search",
        commitMethod: "setVideosTest",
        params: {
          page: this.page,
          size: 30,
          search: this.searchParams
        }
      });
    },
    loadSpeakers(query) {
      return this.getSpeakersWithFilter({
        type: "speakers",
        method: "/speaker/search",
        commitMethod: "setSpeakers",
        params: {
          search: query || ""
        }
      });
    },
    loadCategories(query) {
      return this.getCategoriesByTitle(query);
    }
  },
  watch: {
    searchParams(n, o) {
      if (n !== o) {
        this.loadVideos();
      }
    }
  },
  created() {
    this.loadVideos();
    this.loadCategories();
    this.loadSpeakers();
  }
};
</script>

<style scoped></style>
