<template>
  <div :class="socialStyle.blockClass">
    <ShareNetwork
      network="telegram"
      :url="sharePath"
      :title="title"
      :description="desc"
      :quote="quote"
      :hashtags="tags"
    >
      <i class="fab fa-telegram-plane"></i>
    </ShareNetwork>
    <ShareNetwork
      network="twitter"
      :url="sharePath"
      :title="title"
      :description="desc"
      :quote="quote"
      :hashtags="tags"
    >
      <i class="fab fa-twitter"></i>
    </ShareNetwork>
    <ShareNetwork
      network="facebook"
      :url="sharePath"
      :title="title"
      :description="desc"
      :quote="quote"
      :hashtags="tags"
    >
      <i class="fab fa-facebook-f"></i>
    </ShareNetwork>
    <ShareNetwork
      network="vk"
      :url="sharePath"
      :title="title"
      :description="desc"
      :quote="quote"
      :hashtags="tags"
    >
      <i class="fab fa-vk"></i>
    </ShareNetwork>
    <ShareNetwork
      network="whatsapp"
      :url="sharePath"
      :title="title"
      :description="desc"
      :quote="quote"
      :hashtags="tags"
    >
      <i class="fab fa-whatsapp"></i>
    </ShareNetwork>
    <a
      href="#"
      class="share-network-print"
      @click.prevent="print"
      v-if="printId"
    >
      <i class="fas fa-print"></i>
    </a>
  </div>
</template>

<script>
export default {
  name: "SocialMenu",
  props: {
    socialStyle: {
      type: Object,
      default() {
        return {
          blockClass: "top-footer-right"
        };
      }
    },
    title: {
      type: String,
      default: ""
    },
    description: {
      type: String,
      default: ""
    },
    quote: {
      type: String,
      default: ""
    },
    categories: {
      type: Array,
      default() {
        return [
          {
            title: ""
          }
        ];
      }
    },
    printType: {
      type: String,
      default: "vertical"
    },
    printId: {
      type: String,
      default: ""
    }
  },
  computed: {
    desc() {
      return this.description ? this.description : "";
    },
    sharePath() {
      return (
        window.location.protocol +
        "//" +
        window.location.hostname +
        this.$route.fullPath
      );
    },
    type() {
      return this.printType ? this.printType.toLowerCase() : "vertical";
    },
    tags() {
      return this.categories ? this.categories.map(x => x.title).join(",") : "";
    }
  },
  methods: {
    async print() {
      await this.$htmlToPaper(
        this.printId,
        {
          specs: ["fullscreen=yes", "titlebar=no", "scrollbars=no"],
          styles: [
            `https://thelak.com/css/print_${this.type}.css`,
            "https://fonts.googleapis.com/css?family=Open+Sans&display=swap",
            "https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"
          ]
        },
        () => {
          console.log("Печать завершена или отменена");
        }
      );
    }
  }
};
</script>
