<template>
  <validation-observer
    tag="div"
    ref="promoObserver"
    class="email-cert promo-code"
  >
    <form
      id="promo"
      ref="promo"
      class="tab-ballance-phone"
      style="flex-direction: row !important;"
      @submit.prevent="loadPromo"
      autocomplete="off"
    >
      <validation-provider
        ref="promoField"
        name="promo"
        mode="lazy"
        tag="div"
        class="input-block"
        v-slot="{ errors, reset }"
      >
        <label for="promo-field">
          Введите промокод на скидку
        </label>
        <input
          id="promo-field"
          type="text"
          v-model="formData.promo"
          placeholder="THELAK"
          :class="{ 'error-input': errors.length }"
          :disabled="appliedPromo"
        />
        <error-pop-over :reset-function="reset" :errors="errors" />
      </validation-provider>
      <div class="payment-bottom-content">
        <input
          class="ballance-bottom-submit balance-bottom-submit"
          type="submit"
          value="Отменить"
          v-if="appliedPromo"
        />
        <input
          class="ballance-bottom-submit"
          type="submit"
          value="Применить"
          v-else
        />
      </div>
    </form>
  </validation-observer>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import ErrorPopOver from "@/components/page/items/error/ErrorPopOver";

const errors_promo = {
  object_not_found_error: "Промокод не найден"
};

export default {
  name: "PromoField",
  components: { ErrorPopOver, ValidationObserver, ValidationProvider },
  data() {
    return {
      appliedPromo: false,
      formData: {
        promo: ""
      }
    };
  },
  props: {
    promo: [Object, Array, String]
  },
  computed: {
    updatedPromo: {
      get() {
        return this.promo;
      },
      set(n) {
        this.$emit("update:promo", n);
      }
    }
  },
  methods: {
    loadPromo() {
      if (this.appliedPromo) {
        this.dismissValues(true);
        this.$emit("accepted", false);
      } else {
        this.$http
          .get("/promo/check", {
            params: this.formData
          })
          .then(response => {
            this.updatedPromo = response.data;
            this.$emit("accepted", true);
            this.appliedPromo = true;
          })
          .catch(error => {
            const response = error.response;
            const data = response.data;
            this.applyPromoFail({
              promoField: errors_promo[data.error]
            });
            this.dismissValues(false);
          });
      }
    },
    applyPromoFail(messages) {
      Object.keys(messages).forEach(item => {
        this.$refs[item].applyResult({
          errors: [].concat(messages[item]),
          valid: false,
          failedRules: {}
        });
      });
    },
    dismissValues(formDataDismiss) {
      this.appliedPromo = false;
      this.updatedPromo = null;
      if (formDataDismiss) {
        this.formData = {};
      }
    }
  }
};
</script>

<style scoped></style>
