<template>
  <modal-skeleton
    v-if="modalVisible"
    @forceClose="forceClose"
    @close="requestPartnership"
  >
    <template v-slot:header>
      <h3 class="form-header">Отправьте заявку</h3>
    </template>
    <template v-slot:body>
      <div class="form-block">
        <div class="form-block-wr">
          <input
            type="text"
            name="name"
            placeholder="Как Вас зовут? *"
            v-model="formData.fio"
            required
          />
          <input
            type="text"
            name="phone"
            placeholder="Электронная почта *"
            v-model="formData.email"
            required
          />
          <textarea
            placeholder="Ваше сообщение *"
            v-model="formData.text"
            name="text"
          />
        </div>
      </div>
    </template>
  </modal-skeleton>
</template>

<script>
import ModalSkeleton from "@/components/page/modals/ModalSkeleton";
export default {
  name: "ModalPartnership",
  components: { ModalSkeleton },
  props: ["showModal"],
  data() {
    return {
      formData: {
        email: "",
        fio: "",
        text: ""
      }
    };
  },
  computed: {
    modalVisible: {
      get() {
        return this.showModal;
      },
      set(n) {
        this.$emit("update:showModal", n);
      }
    }
  },
  methods: {
    forceClose() {
      this.modalVisible = false;
    },
    requestPartnership() {
      this.axios
        .post("/email/partner", this.formData)
        .then(response => {
          const data = response.data;
          if (data) {
            this.formData = {};
          } else {
            alert("Не удалось отправить заявку!");
          }
          this.modalVisible = false;
        })
        .catch(() => {
          alert("Не удалось отправить заявку!");
          this.modalVisible = false;
        });
    }
  }
};
</script>

<style scoped>
.form-block {
  width: 100%;
  margin-bottom: 20px;
}
.form-header {
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  font-family: Roboto, sans-serif;
}
</style>
