<template>
  <site-section section-class="news-partners">
    <template v-slot:header>
      <h2>Партнеры</h2>
    </template>
    <template v-slot:content>
      <partners />
    </template>
  </site-section>
</template>

<script>
import Partners from "@/components/page/slick/Partners";
import SiteSection from "@/components/page/SiteSection";
export default {
  name: "PartnerBlock",
  components: { SiteSection, Partners }
};
</script>

<style scoped></style>
