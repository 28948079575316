<template>
  <div class="wrapper-inner">
    <div class="inner-content">
      <div class="pre-flex-header cert-view">
        <div
          class="sertificate-pay-img"
          id="printCert"
          v-if="certificate && certificate.certificateModel"
        >
          <vertical
            :whom="certificate.fio"
            :cert-id="certificate.certificateModel.id"
            :message="certificate.description"
            :length="certificate.certificateModel.length"
            :promo="certificate.uuid"
            v-if="certificate.type === 'VERTICAL'"
          />
          <horizontal
            :whom="certificate.fio"
            :cert-id="certificate.certificateModel.id"
            :message="certificate.description"
            :length="certificate.certificateModel.length"
            :promo="certificate.uuid"
            v-else
          />
        </div>
        <div class="sharing-items">
          <h4>Поделиться</h4>
          <social-menu
            :description="certificate.description"
            title="Thelak - Сертификат"
            print-id="printCert"
            :print-type="certificate.type"
            :quote="certificate.fio"
            :social-style="styleSocial"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Horizontal from "@/components/page/certs/items/horizontal";
import Vertical from "@/components/page/certs/items/vertical";
import SocialMenu from "@/components/page/menu/SocialMenu";

export default {
  name: "CertView",
  components: { SocialMenu, Vertical, Horizontal },
  data() {
    return {
      certificate: {},
      styleSocial: {
        blockClass: "states-inner-social"
      }
    };
  },
  props: {
    uuid: [String, Number]
  },
  methods: {
    getCert() {
      this.$http
        .get("certificate/uuid", {
          params: { uuid: this.uuid }
        })
        .then(response => {
          this.certificate = response.data;
        })
        .catch(() => {
          this.$router.push("/certs");
        });
    }
  },
  created() {
    this.getCert();
  }
};
</script>

<style lang="scss" scoped>
svg {
  width: 100%;
}
.pre-flex-header {
  display: flex;
  align-items: center;
  justify-content: center;
  //flex-wrap: wrap;
  //flex-direction: column;
  margin-top: 30px;
  margin-bottom: -80px;
  width: 100%;
}
.sertificate-pay-img {
  width: 60%;
}

.sharing-items {
  text-align: center;
  width: 40%;
  & h4 {
    color: #fff;
  }
}

.states-inner-social {
  margin-top: 15px;
  width: 100%;
  justify-content: center;
}
</style>
