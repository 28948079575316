<template>
  <ul>
    <li v-for="(datum, key) in data" v-bind:key="key">{{ datum }}</li>
  </ul>
</template>

<script>
export default {
  name: "List",
  props: {
    data: Array
  }
};
</script>
