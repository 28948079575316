<template>
  <div class="wrapper-registration">
    <div class="left-login"></div>
    <div class="right-registration">
      <router-link :to="backTo" class="services-other">
        <img src="@/assets/img/arrow-round-back.svg" alt="" />
        <span>Назад</span>
      </router-link>
      <br /><br /><br /><br />
      <div class="logo-reg">
        <router-link to="/">
          <img src="@/assets/img/logo.svg" alt="Thelak Logo" />
        </router-link>
      </div>
      <transition mode="out-in" name="fade">
        <router-view></router-view>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: "Sign",
  computed: {
    backTo() {
      const route = this.$route;
      if (route.query.from) {
        return { path: route.query.from };
      }

      if (route.query.redirect) {
        return { path: route.query.redirect };
      }
      return { name: route.meta.backTo || "Parent" };
    }
  }
};
</script>

<style scoped></style>
