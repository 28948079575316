<template>
  <div class="wrapper-inner">
    <div class="inner-content">
      <div class="about-wrapper-detail">
        <h1 class="header-big">Подтверждение оплаты</h1>
      </div>
      <div class="pre-flex-header" v-if="success">
        {{ message }}
      </div>
      <p class="pre-flex-header" v-else>
        {{ message ? message : "Проверка подтверждения оплаты..." }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "PayConfirm",
  data() {
    return {
      message: "",
      success: false
    };
  },
  props: {
    MD: {
      type: [String, Number]
    },
    paRes: {
      type: [String, Number],
      default: undefined
    },
    payType: {
      type: [String, Number]
    }
  },
  methods: {
    errorRequest(error) {
      const response = error.response;
      const data = response.data;
      if (data && data.payResponse) {
        const payResponse = data.payResponse;
        if (payResponse.message) {
          this.message = payResponse.message;
        } else if (payResponse.model) {
          const model = payResponse.model;
          this.message = `${model.description} ${model.status}.`;
        } else {
          this.message = "Произошла ошибка...";
        }
        this.success = payResponse.success;
      } else {
        this.success = false;
        this.message = "Произошла ошибка подтверждения оплаты.";
      }
      this.message += " Вы будете перенаправлены через 3 секунды.";
      setTimeout(() => {
        this.$router.push("/");
      }, 3000);
    },
    requestConfirmCert(MD, paRes) {
      this.axios
        .get("/payments/cert/confirm/", {
          params: { MD, PaRes: paRes }
        })
        .then(response => {
          const data = response.data;
          const payResponse = data.payResponse;
          this.message = payResponse.message;
          this.success = payResponse.success;
          const certificate = data.certificate;
          if (!this.success || !certificate) {
            return Promise.reject({ response });
          }
          const uuid = certificate.uuid;
          this.$router.push({ name: "CertView", query: { uuid } });
        })
        .catch(this.errorRequest);
    },
    requestConfirmSub(MD, paRes) {
      this.axios
        .get("/payments/sub/confirm/", {
          params: { MD, PaRes: paRes }
        })
        .then(response => {
          const data = response.data;
          this.message = data.message;
          this.success = data.success;
          this.$forceUpdate();
          if (this.success) {
            this.$router.push("/profile").then(() => {
              location.reload();
            });
          } else if (!this.message) {
            this.$router.push("/");
          }
        })
        .catch(this.errorRequest);
    }
  },
  created() {
    switch (this.payType) {
      case "cert":
        this.requestConfirmCert(this.MD, this.paRes);
        break;
      default:
        if (!this.$auth.check()) {
          this.$router.push({
            name: "login",
            query: {
              redirect: this.$route.fullPath,
              from: this.$router.referer.fullPath
            }
          });
        }
        this.requestConfirmSub(this.MD, this.paRes);
    }
  }
};
</script>

<style scoped>
.pre-flex-header {
  margin-top: 50px;
}
</style>
