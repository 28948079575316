<template>
  <div>
    <calendar
      class="custom-calendar max-w-full wow fadeInUp"
      :masks="masks"
      :attributes="calendarEvents"
      @update:to-page="loadMonthEvent"
      locale="ru"
      color="#000"
    >
      <div
        slot="day-content"
        slot-scope="{ day, attributes }"
        class="flex flex-col h-full z-10 overflow-hidden"
        :style="{
          border: attributes ? 'none' : ''
        }"
      >
        <span class="day-label text-sm text-gray-900">{{ day.day }}</span>
        <div class="flex-grow max-h-full overflow-y-scroll overflow-x-auto">
          <!--          <p-->
          <!--            v-for="attr in attributes"-->
          <!--            :key="attr.key"-->
          <!--            class="text-xs leading-tight rounded-sm p-1 mt-0 mb-1"-->
          <!--            :class="attr.customData.class"-->
          <!--          >-->
          <!--            {{ attr.customData.title }}-->
          <!--          </p>-->
          <div
            v-for="attr in attributes"
            :key="attr.key"
            class="text-xs leading-tight rounded-sm p-1 mt-0 mb-1"
            @click="openEvent(attr.customData)"
            :style="{
              background: 'url(' + attr.customData.background + ')',
              border: 'none',
              'border-radius': '4px'
            }"
          >
            <div class="event-item">
              <span class="items-status-date">
                <i class="far fa-calendar"></i>
              </span>
              <span class="items-status-text">
                {{ day.date | moment("DD.MM.YY") }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </calendar>
  </div>
</template>

<script>
import Calendar from "v-calendar/lib/components/calendar.umd";
export default {
  name: "BlockCalendar",
  components: { Calendar },
  data() {
    return {
      loadedEvents: {},
      masks: {
        weekdays: "WWW",
        title: "MMMM"
      }
    };
  },
  props: {
    events: Array
  },
  methods: {
    loadMonthEvent(e) {
      const key = `${e.year}%${e.month}`;
      if (!this.loadedEvents[key]) {
        const loadedEventsLength = Object.keys(this.loadedEvents).length;
        const refreshEvents = loadedEventsLength > 12;
        if (refreshEvents) {
          this.loadedEvents = {};
        }
        this.$emit("loadmonth", {
          allItems: refreshEvents,
          data: e
        });
        this.$nextTick(() => {
          this.loadedEvents[key] = 1;
        });
      }
    },
    openEvent(day) {
      this.$router.push({ name: "SingleEvent", params: { id: day.id } });
    }
  },
  computed: {
    calendarEvents() {
      return this.events.reduce(
        (acc, curr) =>
          acc.concat({
            key: curr.id,
            customData: {
              title: curr.title,
              background: curr.coverUrl,
              id: curr.id
            },
            dates: curr.endDate
              ? {
                  start: new Date(curr.startDate),
                  end: new Date(curr.endDate)
                }
              : new Date(curr.startDate)
          }),
        []
      );
    }
  }
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 0;
}

::-webkit-scrollbar-track {
  display: none;
}

@mixin calendar-styling(
  $day-height,
  $day-width,
  $day-border-highlight,
  $day-font-size,
  $title-flex,
  $title-padding
) {
  ::v-deep .custom-calendar.vc-container {
    $day-border: none;
    $weekday-bg: none;
    $weekday-border: none;
    background: transparent !important;
    border-radius: 0;
    border: 0 !important;
    & .vc-header {
      background: transparent;
      padding: 10px 0;

      & .vc-title {
        color: #fff;
      }
    }
    & .vc-weeks {
      padding: 0;
    }
    & .vc-weekday {
      background: $weekday-bg;
      border-bottom: $weekday-border;
      border-top: $weekday-border;
      padding: 5px 0;
      font-size: 20px;
      font-weight: 400;
      color: #fff;
      font-family: Roboto, sans-serif;
    }

    & .vc-arrows-container {
      //width: $arrows-width;
      width: auto;
      right: 0;
    }

    & .vc-title-layout {
      //justify-content: flex-end;
      justify-content: $title-flex;
      margin-right: $title-padding;
      //margin-right: 6%;
    }

    & .vc-day {
      padding: 2px;
      text-align: left;
      height: $day-height;
      min-width: $day-width;
      background: transparent;
      cursor: pointer;

      & .h-full {
        border: $day-border-highlight;
        border-radius: 4px;
        height: 100%;

        & .flex-grow {
          flex-grow: 1;
        }

        & .overflow-x-auto {
          overflow-x: auto;
        }

        & .overflow-y-scroll {
          overflow-y: scroll;
        }
      }

      & .day-label {
        display: block;
        position: absolute;
        //right: 20px;
        margin: 5px 0 0 5px;
        color: #fff;
        font-size: $day-font-size;
        font-weight: 600;
        z-index: 10;
      }

      & .leading-tight {
        display: flex;
        position: relative;
        justify-content: flex-start;
        align-items: flex-end;
        background-position: 50% 50% !important;
        background-size: cover !important;
        width: 100%;
        height: 100%;
      }

      &.weekday-1,
      &.weekday-7 {
        background: none;
      }
      &:not(.on-bottom) {
        border-bottom: $day-border;
      }
      &:not(.on-right) {
        border-right: $day-border;
      }
    }
    & .vc-day-dots {
      margin-bottom: 5px;
    }
  }
}

@media screen and (min-width: 769px) {
  @include calendar-styling(
    $day-height: 152px,
    $day-width: calc(14.3%),
    $day-border-highlight: 3px solid #46494e,
    $day-font-size: 18px,
    $title-flex: flex-end,
    $title-padding: 6%
  );
  .event-item {
    margin-left: 5px;
    margin-bottom: 5px;

    & .items-status-text,
    & .items-status-date {
      font-size: 12px;
      color: #fff;
      text-shadow: 1px 1px 1px #2b2b2b;
    }
  }
}

@media screen and (max-width: 768px) {
  @include calendar-styling(
    $day-height: 60px,
    $day-width: 100%,
    $day-border-highlight: 1px solid #46494e,
    $day-font-size: 8px,
    $title-flex: flex-end,
    $title-padding: 10%
  );
  .event-item {
    margin-left: 5px;

    & .items-status-text,
    & .items-status-date {
      font-size: 8px;
      color: #fff;
      text-shadow: 1px 1px 1px #2b2b2b;
    }
  }
}

@media screen and (max-width: 415px) {
  @include calendar-styling(
    $day-height: 60px,
    $day-width: 100%,
    $day-border-highlight: 1px solid #46494e,
    $day-font-size: 8px,
    $title-flex: space-between,
    $title-padding: 14% !important
  );
}

@media screen and (max-width: 376px) {
  @include calendar-styling(
    $day-height: 60px,
    $day-width: 100%,
    $day-border-highlight: 1px solid #46494e,
    $day-font-size: 8px,
    $title-flex: space-between,
    $title-padding: 10% !important
  );
}

@media screen and (max-width: 321px) {
  @include calendar-styling(
    $day-height: 60px,
    $day-width: 100%,
    $day-border-highlight: 1px solid #46494e,
    $day-font-size: 8px,
    $title-flex: space-between,
    $title-padding: 0 !important
  );
}

.max-w-full {
  width: 100%;
}

.max-h-full {
  height: 100%;
}
</style>
