<template>
  <modal-skeleton
    v-if="modalVisible"
    @close="requestUpdate"
    @forceClose="modalVisible = false"
    :show-close-button="false"
  >
    <template v-slot:header>
      <h3 class="form-header">{{ header }}</h3>
    </template>
    <template v-slot:body>
      <div class="form-block">
        <vue-form-generator
          class="form-block-wr"
          :schema="schema"
          :model="formData"
          :options="formOptions"
        />
      </div>
    </template>
  </modal-skeleton>
</template>

<script>
import ModalSkeleton from "@/components/page/modals/ModalSkeleton";
import { component as VueFormGenerator } from "vue-form-generator";
export default {
  name: "ModalAdmin",
  components: { ModalSkeleton, VueFormGenerator },
  props: {
    showModal: Boolean,
    updateMethod: String,
    itemData: Object,
    header: String,
    fields: [Object, Array],
    formOptions: Object
  },
  computed: {
    schema() {
      return {
        groups: [
          ...(this.fields.groups ? this.fields.groups : []),
          {
            fields: [
              {
                type: "submit",
                buttonText: this.header,
                onSubmit: this.requestUpdate,
                validateBeforeSubmit: true
              }
            ]
          }
        ]
      };
    },
    formData: {
      get() {
        return this.itemData;
      },
      set(n) {
        this.$emit("update:itemData", n);
      }
    },
    modalVisible: {
      get() {
        return this.showModal;
      },
      set(n) {
        this.$emit("update:showModal", n);
      }
    }
  },
  methods: {
    requestUpdate() {
      this.$http
        .put(this.updateMethod, this.formData)
        .then(response => {
          const data = response.data;
          if (data) {
            this.formData = {};
          } else {
            alert("Не удалось обновить запись!");
          }
          this.modalVisible = false;
          this.$emit("recordUpdated");
        })
        .catch(() => {
          alert("Не удалось обновить запись!");
          this.modalVisible = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.form-block {
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
}
.form-header {
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  font-family: Roboto, sans-serif;
}
</style>
