<template>
  <validation-observer ref="observerApple" v-if="applePayAvailable">
    <form class="tab-ballance-phone" @submit.prevent="startApplePay">
      <email-field
        id-email="certEmailApple"
        :condition="payment === 'cert'"
        :email-input.sync="updateEmail"
      />
      <submit-field
        :good-name.sync="good.name"
        payment-system="Apple Pay"
        :show-submit-condition.sync="showApplePay"
      />
    </form>
  </validation-observer>
  <div class="tab-payment-unavailable" v-else>
    Выбранный метод оплаты недоступен для данного устройства
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import payment from "@/store/util/payment";
import EmailField from "@/components/page/pay/forms/fields/EmailField";
import SubmitField from "@/components/page/pay/forms/fields/SubmitField";

export default {
  name: "ApplePay",
  data() {
    return {
      showApplePay: false,
      applePayAvailable: false
    };
  },
  components: { SubmitField, EmailField, ValidationObserver },
  props: {
    payment: String,
    email: {
      type: String,
      default: ""
    },
    good: Object,
    certData: Object
  },
  computed: {
    updateEmail: {
      get() {
        return this.email;
      },
      set(n) {
        this.$emit("update:email", n);
      }
    },
    getApplePayDataConfig() {
      return {
        // requiredShippingContactFields: ["email"], //Раскомментируйте, если вам нужен e-mail. Также можно запросить postalAddress, phone, name.
        countryCode: "RU",
        currencyCode: "RUB",
        supportedNetworks: ["visa", "masterCard"],
        merchantCapabilities: ["supports3DS"],
        //Назначение платежа указывайте только латиницей!
        total: { label: "Thelak payment", amount: this.good.price } //назначение платежа и сумма
      };
    }
  },
  methods: {
    startApplePay() {
      const request = this.getApplePayDataConfig;
      // eslint-disable-next-line no-undef
      let session = new ApplePaySession(1, request);
      session.onvalidatemerchant = event => {
        const data = {
          validationUrl: event.validationURL
        };

        this.$http.post("/payments/apple/start", data).then(response => {
          const result = response.data;
          session.completeMerchantValidation(result.Model);
        });
      };

      session.onpaymentauthorized = event => {
        const packet = JSON.stringify(event.payment.token);

        switch (this.payment) {
          case "cert":
            this.acquireReqCert(packet, session);
            break;
          default:
            this.acquireReq(packet, session);
        }
      };
      session.begin();
    },
    appleRequest(session, url, packet, data) {
      return new Promise((resolve, reject) => {
        payment
          .acquireData(url, { cryptogram: packet }, data)
          .then(response => {
            const result = response.data;
            if (result.success) {
              // eslint-disable-next-line no-undef
              session.completePayment(ApplePaySession.STATUS_SUCCESS);
              resolve(result);
            } else {
              // eslint-disable-next-line no-undef
              session.completePayment(ApplePaySession.STATUS_FAILURE);
              reject(result);
            }
          });
      });
    },
    acquireReqCert(packet, session) {
      this.appleRequest(session, "/payments/cert/apple/", packet, {
        certificateId: this.good.id,
        description: this.certData.addComment,
        fio: this.certData.addName,
        promoId: this.good.promoId,
        type: this.certData.addType,
        email: this.updateEmail
      }).then(result => {
        const certificate = result.certificate;
        const uuid = certificate.uuid;
        this.$router.push({ name: "CertView", query: { uuid: uuid } });
      });
    },
    acquireReq(packet, session) {
      this.appleRequest(session, "/payments/sub/apple/", packet, {
        subscriptionId: this.good.id,
        promoId: this.good.promoId
      }).then(result => {
        this.$router.push({
          name: "PaymentConfirm",
          params: { payType: this.payment },
          query: { MD: result.model.transactionId }
        });
      });
    },
    checkout() {
      return new Promise(resolve => {
        payment.getPaymentConfig().then(data => {
          resolve(data.merchantId);
        });
      });
    },
    checkApplePay() {
      if (window.ApplePaySession) {
        // eslint-disable-next-line no-undef
        this.applePayAvailable = ApplePaySession.canMakePayments();
        if (this.applePayAvailable) {
          this.checkout().then(merchantId => {
            // eslint-disable-next-line no-undef
            const applePromise = ApplePaySession.canMakePaymentsWithActiveCard(
              merchantId
            );
            applePromise.then(canMakePayments => {
              this.showApplePay = !!canMakePayments;
              if (!this.showApplePay) {
                this.showApplePay = this.applePayAvailable;
              }
            });
          });
        }
      }
    }
  },
  created() {
    this.checkApplePay();
  }
};
</script>

<style scoped></style>
