<template>
  <div class="row">
    <div class="col-xl-12 col-lg-12 col-sm-12 w100">
      <div class="widget-content widget-content-area">
        <h2>Мероприятия</h2>
        <table-view
          :heads="heads"
          :items.sync="events"
          :fields="schema"
          edit-head="Редактировать мероприятие"
          obtain-method="/event/get"
          update-method="/event/update"
          delete-method="/event/delete"
          @updated="loadEvents"
          :form-options="formOptions"
          :page.sync="page"
        />
      </div>

      <div class="widget-content widget-content-area">
        <h2>Добавить мероприятие</h2>
        <create-event
          :schema="schema"
          :form-options="formOptions"
          @create="loadEvents"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CreateEvent from "@/views/admin/events/CreateEvent";
import TableView from "@/components/admin/lists/TableView";
import { uuid } from "vue-uuid";
import { validators } from "vue-form-generator";
export default {
  name: "AdminEvents",
  components: { TableView, CreateEvent },
  data() {
    return {
      heads: {
        id: {
          name: "ID"
        },
        title: {
          name: "Название"
        }
      },
      page: 1,
      categoryTimeout: null,
      formOptions: {
        validateAfterLoad: false,
        validateAfterChanged: true,
        validateAsync: true
      }
    };
  },
  computed: {
    ...mapGetters(["events", "categories"]),
    schema() {
      return {
        groups: [
          {
            legend: "Основное",
            fields: [
              {
                type: "input",
                inputType: "text",
                label: "Название",
                model: "title",
                required: true,
                placeholder: "New Year",
                validator: validators.required,
                id: uuid.v1() + "title"
              },
              {
                type: "textArea",
                label: "Описание",
                model: "description",
                required: false,
                validator: validators.required,
                placeholder: "Описание к событию"
              },
              {
                type: "textArea",
                label: "Контент",
                model: "content",
                required: true,
                validator: validators.required,
                placeholder: "Информация о событии"
              },
              {
                type: "vueMultiSelect",
                label: "Категория",
                model: "categories",
                required: false,
                placeholder: "Введите категорию",
                selectOptions: {
                  trackBy: "key",
                  label: "title",
                  multiple: true,
                  searchable: true,
                  internalSearch: false,
                  clearOnSelect: false,
                  closeOnSelect: false,
                  // eslint-disable-next-line no-unused-vars
                  onSearch: (searchQuery, id, options) => {
                    if (searchQuery.length === 0) {
                      options = [];
                    } else {
                      if (this.categoryTimeout) {
                        clearTimeout(this.categoryTimeout);
                      }
                      this.categoryTimeout = setTimeout(() => {
                        this.loadCategories(searchQuery).then(data => {
                          options = data;
                        });
                      }, 1e3);
                    }
                  }
                },
                onChanged(model, newVal) {
                  model.categories = newVal;
                },
                values: () => {
                  return this.categories;
                }
              }
            ]
          },
          {
            legend: "Даты",
            fields: [
              {
                type: "input",
                inputType: "text",
                label: "Дата начала",
                required: false,
                model: "startDate",
                id: `${uuid.v1()}startDate`,
                placeholder: "2020-12-22T10:45:01.459Z"
              },
              {
                type: "input",
                inputType: "text",
                label: "Дата окончания",
                required: false,
                model: "endDate",
                id: `${uuid.v1()}endDate`,
                placeholder: "2020-12-22T10:45:01.459Z"
              }
            ]
          },
          {
            legend: "Фото",
            fields: [
              {
                type: "input",
                inputType: "text",
                label: "Обложка",
                model: "coverUrl",
                required: true,
                validator: validators.required,
                id: uuid.v1() + "coverUrl",
                placeholder: "Ссылка"
              }
            ]
          }
        ]
      };
    }
  },
  props: {
    searchParams: {
      type: [String, Object],
      default: ""
    }
  },
  methods: {
    ...mapActions(["getEventsWithFilter", "getCategoriesByTitle"]),
    loadEvents() {
      this.getEventsWithFilter({
        method: "/event/search",
        type: "events",
        params: {
          page: this.page,
          size: 30,
          search: this.searchParams
        }
      });
    },
    loadCategories(query) {
      return this.getCategoriesByTitle(query);
    }
  },
  watch: {
    searchParams(n, o) {
      if (n !== o) {
        this.loadEvents();
      }
    }
  },
  created() {
    this.loadEvents();
    this.loadCategories();
  }
};
</script>

<style scoped></style>
