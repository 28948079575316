import util from "@/store/util";
const moduleCategory = {
  state: {
    categories: []
  },
  getters: {
    categories: state => state.categories.map(util.getters.uuidGetter)
  },
  mutations: {
    setCategories: util.mutations.setData
  },
  actions: {
    getCategories({ dispatch }) {
      return dispatch("getCategoriesWithFilter", {
        method: "/category/list",
        type: "categories",
        allItems: true
      });
    },
    getCategoriesByTitle({ dispatch }, title) {
      return dispatch("getCategoriesWithFilter", {
        method: "/category/search",
        type: "categories",
        params: {
          search: title || ""
        }
      });
    },
    getCategoriesWithFilter({ commit }, data) {
      if (!data.commitMethod) {
        data.commitMethod = "setCategories";
      }
      return util.methods.getFilteredObjects(commit, data, {
        flushMethod: "setCategories"
      });
    }
  }
};

export default moduleCategory;
