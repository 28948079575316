<template>
  <div class="flex-podpiska-sertificate">
    <gift v-for="gift in data" v-bind:key="gift.id" :data="gift" />
  </div>
</template>

<script>
import Gift from "@/components/page/certs/gift/Gift";
export default {
  name: "Gifts",
  components: { Gift },
  props: {
    data: Array
  }
};
</script>

<style scoped></style>
