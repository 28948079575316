<template>
  <site-section section-class="news-tovar-podpiska">
    <template v-slot:description>
      <h2>Выберите подписку</h2>
      <p class="pre-flex-header wow fadeInUp">
        Более тысячи часов развивающего видеоконтента в Full HD качестве
        <br />и статьи по бизнес-тематике по стоимости дешевле, чем посещение
        одного мероприятия.
      </p>
    </template>
    <template v-slot:content v-if="tiles.length">
      <subscription :tiles="tiles" />
    </template>
  </site-section>
</template>

<script>
import SiteSection from "@/components/page/SiteSection";
import Subscription from "@/components/page/slick/Subscription";
export default {
  name: "SubscriptionBlock",
  components: { Subscription, SiteSection },
  props: {
    tiles: Array
  }
};
</script>

<style scoped></style>
