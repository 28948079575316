<template>
  <site-section section-class="ballance-partners" v-if="good.id">
    <template v-slot:content>
      <tabs
        :options="{ useUrlFragment: false, defaultTabHash: 'cardTab' }"
        ref="tabContent"
      >
        <template v-if="promoPaynew != 1" v-slot:header>
          <promo-field :promo.sync="promoUpdated" @accepted="updateGood" />
          <h4 class="header-ballance-top">Выберите способ оплаты</h4>
        </template>
        <template v-else v-slot:header>
          <h4 class="header-ballance-top">Выберите способ оплаты</h4>
        </template>
        <template v-slot:top>
          <div class="ballance-top">
            <div class="tovar-items-status" :class="{ w100 }">
              <span
                class="items-status-circle blue paying-for-circle payment-height"
              ></span>
              <span
                class="items-status-text"
                style="line-height: 22px !important;"
                v-if="promoPaynew == 1"
              >
                Для активации промокода вам необходимо ввести данные банковской
                карты. После верификации карты с вас будет списан и сразу
                возвращён 1 рубль.
              </span>
              <span v-else>
                <span class="items-status-text" v-if="good.old_price">
                  Оплатить {{ good.name.toLowerCase() }} на
                  {{ good.months }} мес. за
                  <u class="color-red" style="text-decoration: none !important;"
                    >{{ good.price }} руб.</u
                  >
                  &nbsp; <s v-html="good.old_price"></s> руб.
                </span>
                <span class="items-status-text" v-else>
                  Оплатить {{ good.name.toLowerCase() }} на
                  {{ good.months }} мес. за {{ good.price }} руб.
                </span>
              </span>
            </div>
            <router-link
              :to="{
                name: 'Payment',
                query: {
                  goodId: 4,
                  payment,
                  ...(payment === 'cert' ? certData : {})
                }
              }"
              v-if="good.id !== 4 && promoPaynew != 1"
            >
              Купить {{ good.name.toLowerCase() }} на 1 год
            </router-link>
          </div>
        </template>
        <template v-slot:content>
          <div v-if="isActiveBlock == false" class="displayblock">
            <div class="modal-mask" v-if="promoPaynew == 1">
              <div class="modal-wrapper">
                <div class="modal-container">
                  <div class="modal-header">
                    <h3 class="form-header">
                      Заполните данные, чтобы продолжить.
                    </h3>
                  </div>

                  <div class="modal-body">
                    <div class="form-block" style="width: 100% !important ;">
                      <div class="form-block-wr">
                        <form ref="anyName" @submit.prevent="sendMail">
                          <input
                            v-model.trim="user.email"
                            class="phone"
                            type="email"
                            placeholder="E-mail"
                            required
                          />
                          <input
                            v-model.trim="user.phone"
                            class="phone___inp"
                            type="tel" v-mask="'+# (###) ###-##-##'" 
                            placeholder="+7 (111) 111-11-11"
                            required
                          />

                          <div class="input-reg-submit">
                            <label class="checkbox-reg">
                              <input
                                v-model="accept_terms"
                                type="checkbox"
                                value="1"
                              />
                              <span>
                                Принимаю
                                <a
                                  href="https://mediateka.pro/useragreement_mediateka"
                                  class="agreement-reg"
                                  target="_blank"
                                  >Пользовательское соглашение</a
                                >
                                и даю согласие на обработку персональных данных
                                и получение информационных сообщений
                              </span>
                            </label>
                            <input
                              id="more-reg"
                              type="submit"
                              value="Продолжить"
                            />
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>

                  <div class="modal-footer" v-if="showCloseButton">
                    <slot name="footer">
                      <button
                        class="modal-default-button"
                        @click="$emit('close')"
                      >
                        Отправить
                      </button>
                    </slot>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <tab id="cardTab" pic="credit-card" name="Банковская карта">
            <cloud-pay
              :good="good"
              :payment="payment"
              :cert-data="certData"
              :email.sync="email"
            />
          </tab>
          <tab
            id="google-pay"
            pic="google-pay-mark_800"
            name="Google Pay"
            :class-wrapper="
              payment === 'cert' ? 'gpay-wrapper-cert' : 'gpay-wrapper'
            "
            :name-visible="false"
          >
            <google-pay
              :cert-data="certData"
              :email.sync="email"
              :payment="payment"
              :good="good"
            />
          </tab>
          <tab
            id="apple-pay"
            pic="apple-pay"
            name="Apple Pay"
            :class-wrapper="
              payment === 'cert' ? 'apple-wrapper-cert' : 'apple-wrapper'
            "
            :name-visible="false"
          >
            <apple-pay
              :payment="payment"
              :good="good"
              :cert-data="certData"
              :email.sync="email"
            />
          </tab>
        </template>
      </tabs>
    </template>
  </site-section>
</template>

<script>
import {mask} from 'vue-the-mask'
import SiteSection from "@/components/page/SiteSection";
import GooglePay from "@/components/page/pay/google/GooglePay";
import ApplePay from "@/components/page/pay/apple/ApplePay";
import Tab from "@/components/page/items/tabs/Tab";
import Tabs from "@/components/page/items/tabs/Tabs";
import CloudPay from "@/components/page/pay/card/CloudPay";
import PromoField from "@/components/page/pay/forms/fields/PromoField";
import { smoothVerticalScroll } from "@/store/util/methods";
import emailjs from "emailjs-com";

export default {
  name: "PaymentBlock",
  directives: {mask},
  components: {
    PromoField,
    CloudPay,
    ApplePay,
    GooglePay,
    SiteSection,
    Tab,
    Tabs
  },
  props: {
    good: {
      type: Object,
      default() {
        return {};
      }
    },
    promo: [Object, Array, String],
    payment: {
      type: String,
      default: "sub"
    },
    password: {
      type: [String, Number],
      default: null
    },
    promoPaynew: {
      type: Number,
      default: 0
    },
    certData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      formData: {
        email: ""
      },
      formNewData: this.$auth.user() || {},
      isActiveBlock: false,
      accept_terms: 0,
      emailsend: "",
      w100: false
    };
  },
  mounted() {
    this.user.email = "";
    this.user.phone = "";
    if (this.promoPaynew == 1) {
      this.w100 = true;
    }
  },
  computed: {
    user() {
      return this.$auth.user() || {};
    },
    promoUpdated: {
      get() {
        return this.promo;
      },
      set(n) {
        this.$emit("update:promo", n);
      }
    },
    email: {
      get() {
        return this.formData.email;
      },
      set(n) {
        this.formData.email = (n || "").trim();
      }
    }
  },
  methods: {
    updateGood(accepted) {
      return new Promise(resolve => {
        this.$emit("goodUpdated");
        this.$nextTick(resolve);
      }).then(() => {
        if (accepted) {
          this.scrollToTabs();
        }
      });
    },
    sendMail() {
      this.axios.post("/auth/update", this.formNewData).then(response => {
        const userData = response.data.data;
        this.$auth.user(userData);
        console.log(userData);
      });
      setTimeout(() => {
        const dataemail = {
          email: this.user.email,
          generetpassord: this.password
        };
        emailjs
          .send(
            "service_oug7s0r",
            "template_7r0w4g5",
            dataemail,
            "user_3y49K3wU7BUgftZ9BHkjT"
          )
          .then(
            () => {
              alert("Данные для входа в аккаунт, отправленны на вашу почту");
              this.isActiveBlock = true;
            },
            error => {
              console.log("FAILED...", error.text);
            }
          );
      }, 1200);
    },
    scrollToTabs() {
      const o = this.$refs.tabContent.$el;
      smoothVerticalScroll(o, 1500, "top");
    }
  },
  created() {
    this.email = this.user.email;
  }
};
</script>

<style scoped>
.form-block {
  width: 100%;
  margin-bottom: 20px;
}
.form-header {
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  margin-top: 18px;
  font-family: Roboto, sans-serif;
}

.modal-header {
  display: flex;
  justify-content: center !important;
}

.phone___inp {
    background: #303339;
    border-radius: 4px;
    width: 100%;
    letter-spacing: 0;
    color: #f0f0f0;
    font-size: 16px;
    border: none;
    margin-bottom: 15px;
    height: 65px;
    padding: 0 20px;
}

.w100 {
  width: 100% !important;
}

.displayblock {
  display: block;
}

.displaynone {
  display: none !important;
}
</style>
