<template>
  <button :class="promoClass" @click.stop.prevent="copyText">{{ text }}</button>
</template>

<script>
export default {
  name: "CopyComponent",
  props: {
    text: {
      type: String,
      default: "THELAK"
    },
    promoClass: {
      type: String,
      default: "promo-kod"
    }
  },
  methods: {
    copyText(e) {
      navigator.clipboard
        .writeText(e.target.innerText)
        .then(() => {
          // todo show more beautiful popup
          alert("Промокод успешно скопирован!");
        })
        .catch(err => {
          alert("Произошла ошибка: " + err);
        });
    }
  }
};
</script>

<style scoped></style>
