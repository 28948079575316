export default {
  init() {
    if (!this.Vue.router) {
      return "vue-router.2.x.js : Vue.router must be set.";
    }
  },

  // bindData: function (data, ctx) {
  //     var error, success;

  //     data = data || {};

  //     error = data.error;
  //     success = data.success;

  //     data.query = ctx.$route.query || {};

  //     if (data.success) { data.success = function (res) { success.call(ctx, res); } }
  //     if (data.error) { data.error = function (res) { error.call(ctx, res); } }

  //     return data;
  // },

  beforeEach(routerBeforeEach, transitionEach, setTransitions, getAuthMeta) {
    let _this = this;
    this.Vue.router.beforeEach(function(transition, location, next) {
      setTransitions(transition);

      routerBeforeEach.call(_this, function() {
        const auth = getAuthMeta(transition);

        transitionEach.call(_this, transition, auth, function(redirect) {
          const userAuth = _this.check();
          let redirectTo;
          if (userAuth) {
            redirectTo = redirect;
          } else {
            redirectTo = redirect;
          }

          if (!redirectTo) {
            (next || transition.next)();
            return;
          }

          // router v2.x
          if (next) {
            next(redirectTo);
          } else {
            this.router._routerReplace.call(this, redirectTo);
          }
        });
      });
    });
  },

  routerReplace(data) {
    const router = this.Vue.router;

    router.replace.call(router, data);
  },

  routerGo(data) {
    const router = this.Vue.router;

    (router.push || router.go).call(router, data).catch(function(err) {
      console.log(err);
    });
  }
};
