import "./bootstrap";
import Vue from "vue";
import VueSocialSharing from "vue-social-sharing";
import Vuelidate from "vuelidate/src";
import VueAuth from "@websanova/vue-auth";
import VueAxios from "vue-axios";
import VueRouter from "vue-router";
import auth from "./auth";
import router from "./router";
import store from "./store";
import VueRouterReferer from "@tozd/vue-router-referer";
import App from "./App";
import VueProgressBar from "vue-progressbar";
import VueMultiselect from "vue-multiselect";
import UUID from "vue-uuid";
import VueHtmlToPaper from "vue-html-to-paper";
import vue_moment from "vue-moment";
import truncate from "@/truncate";
import { Fragment } from "vue-frag";

const pOptions = {
  color: "#4849EA",
  failedColor: "#874b4b",
  thickness: "3px",
  transition: {
    speed: "0.2s",
    opacity: "0.6s",
    termination: 300
  },
  autoRevert: true,
  location: "top",
  inverse: false
};

Vue.use(VueProgressBar, pOptions);
Vue.router = router;
Vue.use(VueRouter);
Vue.use(VueRouterReferer);
Vue.use(VueAxios, window.axios);
window.axios.defaults.baseURL = `https://thelak.com/v1`;
Vue.use(VueAuth, auth);
Vue.use(VueSocialSharing);
Vue.config.productionTip = true;
//todo tovar item
Vue.component("Fragment", Fragment);
Vue.use(Vuelidate);
Vue.use(UUID);
Vue.component("multiselect", VueMultiselect);
const moment = require("moment");
require("moment/locale/ru");

Vue.use(vue_moment, {
  moment
});

Vue.use(VueHtmlToPaper);

Vue.filter("truncate", truncate);

export default new Vue({
  render: h => h(App),
  router,
  store
}).$mount("#app");
