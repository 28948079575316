import Vue from "vue";
import { uuid } from "vue-uuid";
import util from "@/store/util";
const videoStructure = (data, hideStatus) => ({
  tags: data.category,
  name: data.title,
  subscription: data.subscription,
  status: data.subscription ? "По подписке" : "Бесплатно",
  hideStatus: hideStatus || false,
  id: data.id,
  key: data.id + "_" + uuid.v1(),
  video: {
    length: new Date(data.duration * 1000).toISOString().substr(11, 8),
    views: data.viewsCount,
    vote: data.rating + "/10",
    id: data.id,
    poster: data.coverUrl,
    sources: data.sources,
    cover: data.posterUrl
  }
});

const moduleVideos = {
  state: {
    videosTest: [],
    speakerVideos: [],
    playgroundVideos: [],
    viewedVideos: [],
    noveltyVideos: [],
    popularVideos: [],
    watchLaterVideos: [],
    videosSlider: [],
    videos: [],
    searchVideos: [],
    filters: {}
  },
  getters: {
    getFilters: state => state.filters,
    videos: state =>
      state.videos.reduce(
        (acc, curr) =>
          acc.concat({
            tags: curr.category,
            name: curr.title,
            status: curr.subscription ? "По подписке" : "Бесплатно",
            video: {
              length: new Date(curr.duration * 1000)
                .toISOString()
                .substr(11, 8),
              views: curr.viewsCount,
              vote: curr.rating + "/10",
              id: curr.id,
              poster: curr.coverUrl,
              sources: curr.sources
            }
          }),
        []
      ),
    smallVideos: state =>
      state.videos.reduce(
        (acc, curr) =>
          acc.concat({
            tags: curr.category,
            name: curr.title,
            status: curr.subscription ? "По подписке" : "Бесплатно",
            hideStatus: true,
            video: {
              length: new Date(curr.duration * 1000)
                .toISOString()
                .substr(11, 8),
              views: curr.viewsCount,
              vote: curr.rating + "/10",
              id: curr.id,
              poster: curr.coverUrl,
              sources: curr.sources
            }
          }),
        []
      ),
    speakerVideos: state =>
      state.speakerVideos.reduce(
        (acc, curr) => acc.concat(videoStructure(curr)),
        []
      ),
    playgroundVideos: state =>
      state.playgroundVideos.reduce(
        (acc, curr) => acc.concat(videoStructure(curr)),
        []
      ),
    videosTest: state =>
      state.videosTest.reduce(
        (acc, curr) => acc.concat(videoStructure(curr)),
        []
      ),
    viewedVideos: state =>
      state.viewedVideos.reduce(
        (acc, curr) => acc.concat(videoStructure(curr, true)),
        []
      ),
    videosSlider: state =>
      state.videosSlider.reduce(
        (acc, curr) => acc.concat(videoStructure(curr)),
        []
      ),
    watchLaterVideos: state =>
      state.watchLaterVideos.reduce(
        (acc, curr) => acc.concat(videoStructure(curr, true)),
        []
      ),
    popularVideos: state =>
      state.popularVideos.reduce(
        (acc, curr) => acc.concat(videoStructure(curr, false)),
        []
      ),
    noveltyVideos: state =>
      state.noveltyVideos.reduce(
        (acc, curr) => acc.concat(videoStructure(curr, false)),
        []
      ),
    searchVideos: state =>
      state.searchVideos.reduce(
        (acc, curr) => acc.concat(videoStructure(curr, false)),
        []
      )
  },
  mutations: {
    setVideosTest: util.mutations.setData,
    appendVideosTest: util.mutations.appendData,
    setVideos(state, payload) {
      state.videos = payload;
    },
    setFilters(state, payload) {
      state.filters = payload;
    }
  },
  actions: {
    getPageVideos({ dispatch }, data) {
      return dispatch("getPageVideosWithFilter", {
        type: data.type,
        method: data.method,
        commitMethod:
          data.params.page === 1 ? "setVideosTest" : "appendVideosTest",
        params: {
          size: data.params.size || 15,
          page: data.params.page || 1,
          ...data.params
        }
      });
    },
    getViewedVideos({ dispatch }, data) {
      return dispatch("getPageVideosWithFilter", {
        type: "viewedVideos",
        method: "/video/history/list",
        params: data
      });
    },
    getWatchLaterVideos({ dispatch }, data) {
      return dispatch("getPageVideosWithFilter", {
        type: "watchLaterVideos",
        method: "/video/favorites/list",
        params: data
      });
    },
    getNoveltyVideos({ dispatch }, data) {
      return dispatch("getPageVideosWithFilter", {
        type: "noveltyVideos",
        method: "/video/list",
        params: {
          page: data.page || 1,
          size: data.size || 8,
          sort: "NEW",
          sortType: "DESC"
        }
      });
    },
    getSearchVideos({ dispatch }, data) {
      return dispatch("getPageVideosWithFilter", {
        type: "searchVideos",
        method: "/video/search",
        params: {
          page: data.page || 1,
          size: data.size || 15,
          search: data.search
        }
      });
    },
    getPopularVideos({ dispatch }, data) {
      return dispatch("getPageVideosWithFilter", {
        type: "popularVideos",
        method: "/video/list",
        params: {
          page: data.page || 1,
          size: data.size || 8,
          sort: "POPULAR",
          sortType: "DESC"
        }
      });
    },
    getPageVideosWithFilter({ commit }, data) {
      if (!data.commitMethod) {
        data.commitMethod = "appendVideosTest";
      }
      return util.methods.getFilteredObjects(commit, data, {
        flushMethod: "setVideosTest"
      });
    },
    getSliderVideos({ dispatch }) {
      return dispatch("getPageVideosWithFilter", {
        type: "videosSlider",
        method: "/video/slider",
        commitMethod: "setVideosTest"
      });
    },
    getFilters({ commit }) {
      return new Promise(resolve => {
        Vue.axios.get("/video/filter").then(response => {
          commit("setFilters", response.data);
          resolve(response.data);
        });
      });
    }
  }
};

export default moduleVideos;
