import { render, staticRenderFns } from "./Gift.vue?vue&type=template&id=352faa06&scoped=true&"
import script from "./Gift.vue?vue&type=script&lang=js&"
export * from "./Gift.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../../../../usr/local/lib/node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "352faa06",
  null
  
)

export default component.exports