export default {
  request(req, token) {
    this.http.setHeaders.call(this, req, {
      Authorization: "Bearer " + token
    });
  },
  response(res) {
    let headers = this.http.getHeaders.call(this, res),
      token = headers.Authorization || headers.authorization;

    // let token = this.http.httpData.call(this, res);
    // let token = res.data;
    // console.log(res);
    if (token && typeof token === "string") {
      token = token.split(/Bearer:?\s?/i);

      return token[token.length > 1 ? 1 : 0].trim();
    }
  }
};
