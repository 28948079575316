<template>
  <div :class="[itemClass, addClass, 'wow', 'fadeInUp']">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "StateItem",
  props: {
    itemClass: {
      type: String,
      default: "news-states-items"
    },
    addClass: {
      type: String,
      default: ""
    }
  }
};
</script>

<style scoped></style>
