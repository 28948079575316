<template>
  <div class="news-states-items full-states">
    <div class="states-wrapper states-inner">
      <img class="img-event-cover" :src="item.coverUrl" :alt="item.title" />
      <tags :tags="item.categories" :is-inner="true" />
      <div class="states-inner-view" v-if="useViews">
        <img src="@/assets/img/eye.svg" alt="Views" />
        <span>{{ item.viewsCount }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import Tags from "@/components/page/items/tags/Tags";
export default {
  name: "Cover",
  components: { Tags },
  props: {
    item: Object,
    useViews: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<style scoped></style>
