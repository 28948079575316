import util from "@/store/util";
const moduleSpeakers = {
  state: {
    speakers: []
  },
  getters: {
    speakers: state => state.speakers.map(util.getters.uuidGetter)
  },
  mutations: {
    setSpeakers: util.mutations.setData,
    appendSpeakers: util.mutations.appendData
  },
  actions: {
    getSpeakers({ dispatch }, page) {
      return dispatch("getSpeakersWithFilter", {
        method: "/speaker/list",
        type: "speakers",
        commitMethod: "appendSpeakers",
        params: {
          page: page || 1,
          size: 15
        }
      });
    },
    getSpeakersWithFilter({ commit }, data) {
      if (!data.commitMethod) {
        data.commitMethod = "setSpeakers";
      }
      return util.methods.getFilteredObjects(commit, data, {
        flushMethod: "setSpeakers"
      });
    }
  }
};

export default moduleSpeakers;
