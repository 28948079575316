<template>
  <video-slider-block
    :block-head="head"
    block-class="news-tovar"
    slick-class="slider-news-tovar"
    :media="media"
    v-if="media && media.length"
  />
</template>

<script>
import VideoSliderBlock from "@/components/page/media/VideoSliderBlock";
export default {
  name: "PopularBlock",
  components: { VideoSliderBlock },
  props: {
    media: Array,
    head: {
      type: String,
      default: "Популярное"
    }
  }
};
</script>

<style scoped></style>
