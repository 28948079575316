import { render, staticRenderFns } from "./SpeakerBlock.vue?vue&type=template&id=805f5422&scoped=true&"
import script from "./SpeakerBlock.vue?vue&type=script&lang=js&"
export * from "./SpeakerBlock.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../../../usr/local/lib/node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "805f5422",
  null
  
)

export default component.exports