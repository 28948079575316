<template>
  <fragment>
    <section class="sertificate-wrapper">
      <site-header />
      <div class="sertificate-header-wrapper">
        <div class="inner-content">
          <h4>Подарите подписку на лучший бизнес контент</h4>
          <a href="#" @click.prevent="scrollToGift">Выбрать сертификат</a>
        </div>
      </div>
    </section>
    <gift-block ref="gift_block" :certs="certs" />
    <benefits-block />
    <figures-block />
    <popular-block :media="videosTest" head="Всегда есть, что посмотреть" />
    <site-footer />
  </fragment>
</template>

<script>
import { WOW } from "wowjs";
import SiteHeader from "@/components/page/SiteHeader";
import SiteFooter from "@/components/page/SiteFooter";
import GiftBlock from "@/components/page/certs/GiftBlock";
import BenefitsBlock from "@/components/page/certs/BenefitsBlock";
import FiguresBlock from "@/components/page/certs/FiguresBlock";
import PopularBlock from "@/components/page/main/PopularBlock";
import { mapActions, mapGetters } from "vuex";
import { smoothVerticalScroll } from "@/store/util/methods";
export default {
  name: "Certificates",
  components: {
    PopularBlock,
    FiguresBlock,
    BenefitsBlock,
    GiftBlock,
    SiteFooter,
    SiteHeader
  },
  computed: {
    ...mapGetters(["videosTest", "certs"])
  },
  methods: {
    ...mapActions(["getPageVideos", "getCerts"]),
    scrollToGift() {
      const o = this.$refs.gift_block.$el;
      smoothVerticalScroll(o, 750, "top");
    }
  },
  mounted() {
    new WOW({ offset: 140, live: false }).init();
  },
  created() {
    this.getCerts().then(() => {
      this.getPageVideos({
        method: "/video/list",
        type: "videosTest",
        params: {
          page: 1,
          size: 16
        }
      });
    });
  }
};
</script>
