<template>
  <validation-observer ref="observerGoogle" v-if="googlePayAvailable">
    <form class="tab-ballance-phone" @submit.prevent="googlePaymentButtonClick">
      <email-field
        id-email="certEmailGPay"
        :condition="payment === 'cert'"
        :email-input.sync="updateEmail"
      />
      <submit-field
        :good-name.sync="good.name"
        payment-system="Google Pay"
        :show-submit-condition.sync="showGooglePay"
      />
    </form>
  </validation-observer>
  <div class="tab-payment-unavailable" v-else>
    Выбранный метод оплаты недоступен для данного устройства
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import payment from "@/store/util/payment";
import EmailField from "@/components/page/pay/forms/fields/EmailField";
import SubmitField from "@/components/page/pay/forms/fields/SubmitField";
const googleApi = "googleApi";

export default {
  name: "GooglePay",
  components: { SubmitField, EmailField, ValidationObserver },
  data() {
    return {
      publicId: "",
      merchantId: "",
      showGooglePay: false,
      googlePayAvailable: true,
      allowedPaymentMethods: ["CARD", "TOKENIZED_CARD"],
      allowedCardNetworks: ["MASTERCARD", "VISA"]
    };
  },
  props: {
    good: Object,
    email: {
      type: String,
      default: ""
    },
    certData: Object,
    payment: String
  },
  computed: {
    updateEmail: {
      get() {
        return this.email;
      },
      set(n) {
        this.$emit("update:email", n);
      }
    },
    tokenizationParameters() {
      return {
        tokenizationType: "PAYMENT_GATEWAY",
        parameters: {
          gateway: "cloudpayments",
          gatewayMerchantId: this.publicId
        }
      };
    },
    getGooglePaymentDataConfiguration() {
      return {
        merchantId: this.merchantId,
        paymentMethodTokenizationParameters: this.tokenizationParameters,
        allowedPaymentMethods: this.allowedPaymentMethods,
        cardRequirements: {
          allowedCardNetworks: this.allowedCardNetworks
        }
      };
    },
    getGoogleTransactionInfo() {
      return {
        currencyCode: "RUB",
        totalPriceStatus: "FINAL",
        totalPrice: this.good.price
      };
    }
  },
  methods: {
    getGooglePaymentsClient() {
      // eslint-disable-next-line no-undef
      return new google.payments.api.PaymentsClient({
        environment: "PRODUCTION"
      });
    },
    onGooglePayLoaded() {
      const paymentsClient = this.getGooglePaymentsClient();

      paymentsClient
        .isReadyToPay({
          allowedPaymentMethods: this.allowedPaymentMethods
        })
        .then(response => {
          if (response.result) {
            this.showGooglePay = true;
          } else {
            this.googlePayAvailable = false;
          }
        });
    },
    checkout() {
      return new Promise(resolve => {
        payment.getPaymentConfig().then(data => {
          resolve({
            publicId: data.publicId,
            googleId: data.googleId
          });
        });
      });
    },
    googlePaymentButtonClick() {
      this.checkout().then(data => {
        this.publicId = data.publicId;
        this.merchantId = data.googleId;
        let paymentDataRequest = this.getGooglePaymentDataConfiguration;
        paymentDataRequest.transactionInfo = this.getGoogleTransactionInfo;
        const paymentsClient = this.getGooglePaymentsClient();
        paymentsClient
          .loadPaymentData(paymentDataRequest)
          .then(paymentData => {
            this.processPayment(paymentData);
          })
          .catch(error => {
            console.log(error);
          });
      });
    },
    processPayment(paymentData) {
      const packet = JSON.stringify(paymentData.paymentMethodToken.token);
      switch (this.payment) {
        case "cert":
          this.acquireReqCert(packet);
          break;
        default:
          this.acquireReq(packet);
      }
    },
    acquireReqCert(packet) {
      payment.acquireRequest(
        "/payments/cert/req",
        { cardCryptogramPacket: packet },
        {
          cardName: "Google Pay",
          certificateId: this.good.id,
          description: this.certData.addComment,
          fio: this.certData.addName,
          type: this.certData.addType,
          promoId: this.good.promoId,
          email: this.updateEmail
        }
      );
    },
    acquireReq(packet) {
      payment.acquireRequest(
        "/payments/sub/req",
        { cardCryptogramPacket: packet },
        {
          cardName: "Google Pay",
          promoId: this.good.promoId,
          subscriptionId: this.good.id
        }
      );
    },
    loadGooglePay() {
      payment.loadComponent(googleApi, {
        src: "https://pay.google.com/gp/p/js/pay.js",
        async: true,
        onload: this.onGooglePayLoaded,
        body: true
      });
    },
    unloadGooglePay() {
      payment.unloadComponent(googleApi, {
        body: true
      });
    }
  },
  mounted() {
    this.loadGooglePay();
  },
  beforeDestroy() {
    this.unloadGooglePay();
  }
};
</script>
