import app from "@/main.js";
export default {
  init() {
    if (!this.Vue.axios) {
      return "axios.js : Vue.axios must be set.";
    }
  },

  interceptor(req, res) {
    if (req) {
      this.Vue.axios.interceptors.request.use(
        request => {
          app.$Progress.start();
          req.call(this, request);

          return request;
        },
        error => {
          app.$Progress.fail();
          req.call(this, error.request);

          return Promise.reject(error);
        }
      );
    }

    if (res) {
      this.Vue.axios.interceptors.response.use(
        response => {
          app.$Progress.finish();
          res.call(this, response);

          return response;
        },
        error => {
          if (error && error.response) {
            app.$Progress.fail();
            res.call(this, error.response);
          }

          return Promise.reject(error);
        }
      );
    }
  },

  invalidToken(res) {
    if (res.status === 401 || res.status === 403) {
      return true;
    }
  },

  httpData(res) {
    return res.data || {};
  },

  http(data) {
    return this.Vue.axios(data);
  },

  getHeaders(res) {
    return res.headers;
  },

  setHeaders(req, headers) {
    req.headers.common = Object.assign({}, req.headers.common, headers);
  }
};
