<template>
  <div class="inner-news-tovar" v-if="media.length">
    <event-item v-for="event in media" :key="event.key" :item="event" />
  </div>
</template>

<script>
import EventItem from "@/components/page/events/tiles/EventItem";
export default {
  name: "Events",
  components: { EventItem },
  props: {
    media: {
      type: Array,
      default() {
        return [];
      }
    }
  }
};
</script>

<style scoped></style>
