<template>
  <div style="display: flex;">
  <ul class="displaynonemobile"><li><router-link to="/promocode">Промокод</router-link></li></ul>
  <ul>
    <li v-for="(item, key, index) in links" :key="index">
      <router-link :to="'/' + key" v-html="item" @click="goTo" />
    </li>
  </ul>
  
  </div>
</template>

<script>
export default {
  name: "MainMenu",
  data() {
    return {
      links: {
        library: "Медиатека",
        events: "Мероприятия",
        articles: "Статьи",
        certs: "Сертификаты",
      }
    };
  },
  methods: {
    goTo() {
      this.$emit("navigation");
    }
  }
};
</script>

<style scoped></style>
