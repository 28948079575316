<template>
  <div
    :class="[
      'podpiska-sertificate-elem',
      'bg' + data.cover.toString(),
      'wow',
      'fadeInUp'
    ]"
  >
    <h4>{{ data.name }}</h4>
    <span class="podpiska-sertificate-video" v-html="data.description"></span>
    <list :data="data.list" />
    <span class="podpiska-sertificate-price" v-html="data.priceStr"></span>
    <span
      class="podpiska-sertificate-price2"
      v-if="data.priceStr2"
      v-html="data.priceStr2"
    >
    </span>
    <span class="podpiska-sertificate-year" v-html="data.length"></span>
    <router-link :to="{ name: 'CertPay', params: { id: data.id } }">
      Подарить сертификат
    </router-link>
  </div>
</template>

<script>
import List from "@/components/page/items/list/List";
export default {
  name: "Gift",
  components: { List },
  props: {
    data: Object
  }
};
</script>

<style scoped></style>
