<template>
  <site-section :section-class="sectionClass">
    <template v-slot:header>
      <h2>{{ header }}</h2>
      <router-link :to="linkTo">
        Все {{ header.toLowerCase() }}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="13.503"
          height="23.619"
          viewBox="0 0 13.503 23.619"
        >
          <path
            id="Icon_ionic-ios-arrow-back"
            data-name="Icon ionic-ios-arrow-back"
            d="M15.321,18l8.937-8.93a1.688,1.688,0,0,0-2.391-2.384L11.742,16.8a1.685,1.685,0,0,0-.049,2.327L21.86,29.32a1.688,1.688,0,0,0,2.391-2.384Z"
            transform="translate(24.754 29.813) rotate(180)"
          />
        </svg>
      </router-link>
    </template>
    <template v-slot:content>
      <slot></slot>
    </template>
  </site-section>
</template>

<script>
import SiteSection from "@/components/page/SiteSection";
export default {
  name: "LinkedBlock",
  components: { SiteSection },
  props: {
    header: String,
    linkTo: String,
    sectionClass: String
  }
};
</script>

<style scoped></style>
