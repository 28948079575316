<template>
  <slick ref="slick" :options="slickOptions" class="slick-slider-top">
    <state-item
      v-for="tile in media"
      item-class="slider-top-item"
      v-bind:key="tile.key"
      :style="{ background: 'url(' + tile.video.cover + ')' }"
    >
      <div class="slider-top-content">
        <tags :tags="tile.tags" />
        <h4 v-html="tile.name"></h4>
        <sub-link
          :to="{ name: 'VideoById', params: { id: tile.video.id } }"
          class-list="slider-top-links"
          modal-class="absolute-modal"
          :condition="getCondition(tile.subscription)"
        >
          Смотреть
        </sub-link>
      </div>
      <div class="full-hd-absolute">Full HD</div>
    </state-item>
  </slick>
</template>

<script>
import Slick from "vue-slick";
import StateItem from "@/components/page/slick/StateItem";
import Tags from "@/components/page/items/tags/Tags";
import SubLink from "@/components/page/subscription/links/SubLink";

export default {
  name: "SlideShow",
  components: { SubLink, Tags, StateItem, Slick },
  props: {
    media: Array
  },
  data() {
    return {
      slickOptions: {
        infinite: true,
        dots: false,
        arrows: true,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        rows: 0,
        prevArrow:
          "<div class='slick-prev-tovar'><img src='" +
          this.arrowUrl("right") +
          "' alt='' /></div>",
        nextArrow:
          "<div class='slick-next-tovar'><img src='" +
          this.arrowUrl("left") +
          "' alt='' /></div>",
        responsive: [
          {
            breakpoint: 760,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 500,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      }
    };
  },
  methods: {
    getCondition(isSubscription) {
      return () => {
        if (!isSubscription) {
          return true;
        }
        const user = this.$auth.user();
        const pp =
          !!user &&
          user.isSubscribe &&
          new Date().getTime() <= new Date(user.subscriptionDate).getTime();

        if (pp) {
          this.stopPlay();
        }
        return pp;
      };
    },
    arrowUrl(arrow) {
      return require("@/assets/img/arrow-" + arrow + ".svg");
    },
    stopPlay() {
      if (this.$refs.slick) {
        this.$refs.slick.pause();
      }
    },
    next() {
      this.$refs.slick.next();
    },
    prev() {
      this.$refs.slick.prev();
    },
    reInit() {
      this.$nextTick(() => {
        if (this.$refs.slick) {
          this.$refs.slick.reSlick();
        }
      });
    }
  },
  mounted() {
    setTimeout(this.reInit, 15e2);
  }
};
</script>

<style scoped></style>
