<template>
  <div class="wrapper-inner">
    <div class="inner-content">
      <event-content :event="event" />
      <event-block :data="events" />
    </div>
  </div>
</template>

<script>
import EventContent from "@/components/page/events/EventContent";
import { mapGetters, mapActions } from "vuex";
import EventBlock from "@/components/page/main/EventBlock";
export default {
  name: "Event",
  data() {
    return {
      event: {}
    };
  },
  components: { EventBlock, EventContent },
  computed: {
    ...mapGetters(["events"])
  },
  props: ["id"],
  methods: {
    ...mapActions(["getEvents"]),
    getEvent(id) {
      this.$http
        .get("/event/get", {
          params: {
            id
          }
        })
        .then(response => {
          this.event = response.data;
          this.getEvents();
        });
    }
  },
  created() {
    this.getEvent(this.id);
  },
  watch: {
    id(n, o) {
      if (n !== o) {
        this.getEvent(n);
      }
    }
  }
};
</script>

<style scoped></style>
