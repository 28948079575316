<template>
  <div class="row" id="cancel-row">
    <div class="col-xl-12 col-lg-12 col-sm-12 w100 layout-spacing">
      <div class="widget-content widget-content-area">
        <h2>Спикеры</h2>
        <table-view
          :heads="heads"
          :items.sync="speakers"
          :fields="schema"
          edit-head="Редактировать спикера"
          obtain-method="/speaker/get"
          update-method="/speaker/update"
          delete-method="/speaker/delete"
          @updated="loadSpeakers"
          :form-options="formOptions"
          :page.sync="page"
        />
      </div>

      <div class="widget-content widget-content-area">
        <h2>Добавить спикера</h2>
        <create-speaker
          :schema="schema"
          :form-options="formOptions"
          @create="loadSpeakers"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CreateSpeaker from "@/views/admin/speakers/CreateSpeaker";
import TableView from "@/components/admin/lists/TableView";
import { uuid } from "vue-uuid";
import { validators } from "vue-form-generator";
export default {
  name: "AdminSpeakers",
  components: { TableView, CreateSpeaker },
  data() {
    return {
      heads: {
        id: {
          name: "ID"
        },
        name: {
          name: "Имя"
        },
        country: {
          name: "Страна"
        },
        description: {
          name: "Описание"
        },
        photoUrl: {
          name: "Фото",
          type: "picture"
        }
      },
      formOptions: {
        validateAfterLoad: false,
        validateAfterChanged: true,
        validateAsync: true
      },
      page: 1
    };
  },
  computed: {
    ...mapGetters(["speakers"]),
    schema() {
      return {
        groups: [
          {
            legend: "Основное",
            fields: [
              {
                type: "input",
                inputType: "text",
                label: "ФИО",
                model: "name",
                required: true,
                validator: validators.required,
                placeholder: "Иванов Иван Иванович",
                id: uuid.v1() + "name"
              },
              {
                type: "input",
                inputType: "text",
                label: "Короткое описание",
                model: "shortDescription",
                required: true,
                validator: validators.required,
                placeholder: "Описание",
                id: uuid.v1() + "shortDescription"
              },
              {
                type: "textArea",
                label: "Полное описание",
                model: "description",
                required: true,
                validator: validators.required,
                placeholder: "Полное описание"
              },
              {
                type: "vueMultiSelect",
                model: "country",
                label: "Страна",
                placeholder: "Начните писать для поиска",
                required: true,
                validator: validators.required,
                selectOptions: {
                  multiple: false,
                  searchable: true,
                  taggable: true,
                  clearOnSelect: false,
                  closeOnSelect: true,
                  tagPlaceholder: "Добавьте эту страну",
                  onNewTag(newTag, id, options, value) {
                    options.push(newTag);
                    value.push(newTag);
                  }
                },
                onChanged(model, newVal) {
                  console.log("@tag: ", newVal);
                },
                values: [
                  "Russia",
                  "USA",
                  "Great Britain",
                  "Israel",
                  "Australia",
                  "Switzerland",
                  "Armenia",
                  "Netherlands",
                  "France",
                  "India",
                  "Azerbaijan",
                  "Germany",
                  "Venezuela",
                  "Ukraine",
                  "Poland",
                  "Italy"
                ]
              }
            ]
          },
          {
            legend: "Фото",
            fields: [
              {
                type: "input",
                inputType: "text",
                label: "Фотография спикера",
                model: "photoUrl",
                required: true,
                validator: validators.required,
                placeholder: "Ссылка",
                id: uuid.v1() + "photoUrl"
              }
            ]
          }
        ]
      };
    }
  },
  props: {
    searchParams: {
      type: [String, Object],
      default: ""
    }
  },
  methods: {
    ...mapActions(["getSpeakersWithFilter"]),
    loadSpeakers() {
      this.getSpeakersWithFilter({
        method: "/speaker/search",
        type: "speakers",
        params: {
          page: this.page,
          size: 16,
          search: this.searchParams
        }
      });
    }
  },
  watch: {
    searchParams(n, o) {
      if (n !== o) {
        this.loadSpeakers();
      }
    }
  },
  created() {
    this.loadSpeakers();
  }
};
</script>

<style scoped></style>
