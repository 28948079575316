<template>
  <div>
    <router-link v-slot="{ href }" :to="to" custom>
      <a :href="href" :class="classList" @click.prevent.stop="generateLink">
        <slot></slot>
      </a>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "SubLink",
  inject: ["alertModalInject"],
  props: {
    to: [Object, String],
    classList: {
      type: [Object, Array, String],
      default: ""
    },
    condition: Function
  },
  methods: {
    syncAlert() {
      this.alertModalInject.show = true;
    },
    generateLink() {
      if (this.condition()) {
        this.$router.push(
          typeof this.to === "object" ? this.to : { name: this.to }
        );
      } else {
        this.syncAlert();
      }
    }
  }
};
</script>

<style scoped></style>
