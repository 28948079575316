<template>
  <site-section section-class="main-banners wow fadeInUp">
    <template v-slot:content>
      <div class="main-banners-item">
        <div class="main-banners-content">
          <div class="banner-main-content">
            <h4>
              Только уникальный контент по подписке<br />Всего за 2990
              <span class="acsecc-rub">₽</span> в месяц
            </h4>
            <div class="banner-main-text" v-if="false">
              Весь контент на 1 день всего за 1
              <span class="acsecc-rub">₽</span> по
              промокоду:&nbsp;&nbsp;&nbsp;&nbsp;<copy-component />
            </div>
            <br /><br /><br /><br />
            <router-link to="/sub" class="link-podpiska">
              Подключить подписку
            </router-link>
          </div>
          <img
            class="banner-main-absolute"
            src="@/assets/img/phone-banner.png"
            alt=""
          />
        </div>
      </div>
    </template>
  </site-section>
</template>

<script>
import SiteSection from "@/components/page/SiteSection";
import CopyComponent from "@/components/page/items/copy/CopyComponent";
export default {
  name: "MainBannersBlock",
  components: { CopyComponent, SiteSection }
};
</script>

<style scoped></style>
