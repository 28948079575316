<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 2480 3508"
  >
    <defs>
      <clipPath id="clip-path">
        <use
          id="Слой_0_копия"
          data-name="Слой 0 копия"
          transform="translate(-1276)"
          xlink:href="#image"
          style="mix-blend-mode: hard-light;isolation: isolate"
        />
      </clipPath>
      <pattern
        id="pattern"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 0 0"
      >
        <image />
      </pattern>
      <clipPath id="clip-Canvas_9">
        <rect width="2480" height="3508" />
      </clipPath>
      <image
        id="image"
        width="5058"
        height="3576"
        :xlink:href="image(certId)"
      />
    </defs>
    <g id="Canvas_9" data-name="Canvas – 9" clip-path="url(#clip-Canvas_9)">
      <rect width="2480" height="3508" fill="rgba(0,0,0,0)" />
      <g id="_1_год" data-name="1 год">
        <g id="фон">
          <g
            id="Слой_0_копия-2"
            data-name="Слой 0 копия"
            style="mix-blend-mode: hard-light;isolation: isolate"
          >
            <use
              id="Слой_0_копия-3"
              data-name="Слой 0 копия"
              transform="translate(-1276)"
              xlink:href="#image"
              style="mix-blend-mode: hard-light;isolation: isolate"
            />
            <g id="Clip" clip-path="url(#clip-path)">
              <image
                id="Слой_36"
                data-name="Слой 36"
                width="4452"
                height="3508"
                transform="translate(-986)"
                xlink:href="https://storage.yandexcloud.net/thelak-storage/SMTP/vert_sloi36.png"
                style="mix-blend-mode: multiply;isolation: isolate"
              />
            </g>
          </g>
          <g
            id="Прямоугольник_3"
            data-name="Прямоугольник 3"
            transform="translate(99.5 99.5)"
            fill="none"
            stroke="#fff"
            stroke-width="7.41"
            opacity="0.451"
            style="mix-blend-mode: soft-light;isolation: isolate"
          >
            <rect width="2282" height="3310" rx="47" stroke="none" />
            <rect
              x="3.705"
              y="3.705"
              width="2274.59"
              height="3302.59"
              rx="43.295"
              fill="none"
            />
          </g>
        </g>
        <rect id="Слой_39" data-name="Слой 39" fill="url(#pattern)" />
      </g>
      <g id="Сгруппировать_4" data-name="Сгруппировать 4">
        <g id="поле">
          <g
            id="Прямоугольник_1"
            data-name="Прямоугольник 1"
            transform="translate(199 2728.73)"
            fill="#5b5b68"
            stroke="#4b4c71"
            stroke-width="4.68"
            stroke-dasharray="18.72 9.36"
            opacity="0.6"
          >
            <rect width="2082" height="580.27" rx="39" stroke="none" />
            <rect
              x="2.34"
              y="2.34"
              width="2077.32"
              height="575.59"
              rx="36.66"
              fill="none"
            />
          </g>
          <text
            id="ПОЛЕ_ДЛЯ_СООБЩЕНИЯ"
            data-name="ПОЛЕ ДЛЯ СООБЩЕНИЯ"
            transform="matrix(1, 0, 0, 1, 1240.444, 3032.224)"
            fill="#fff"
            font-size="72"
            font-family="Roboto-Bold, Roboto"
            font-weight="700"
          >
            <tspan x="0" y="0" text-anchor="middle">
              {{ comment }}
            </tspan>
          </text>
        </g>
        <g id="левый_блок" data-name="левый блок">
          <g id="промокод" text-anchor="middle">
            <rect
              id="Прямоугольник_2"
              data-name="Прямоугольник 2"
              width="60%"
              height="136.13"
              rx="39"
              transform="translate(500 2001.63)"
              fill="#5b5b68"
              opacity="0.4"
            />
            <text
              id="ПРОМОКОД-2"
              data-name="ПРОМОКОД"
              transform="matrix(1, 0, 0, 1, 1240.37, 2093.297)"
              fill="#fff"
              font-size="70"
              font-family="Roboto-Bold, Roboto"
              font-weight="700"
              letter-spacing="0.05em"
            >
              <tspan x="0" y="0" @click.stop.prevent="copyText">
                {{ promo }}
              </tspan>
            </text>
          </g>
        </g>
        <rect
          id="Прямоугольник_4"
          data-name="Прямоугольник 4"
          width="1928.49"
          height="169.7"
          rx="36"
          transform="translate(275.55 1353.69)"
          fill="#5b5b68"
          opacity="0.4"
        />
        <g id="инфа">
          <text
            id="НА_1_МЕСЯЦ"
            data-name="НА 1 МЕСЯЦ"
            transform="matrix(1, 0, 0, 1, 1239.922, 1145.629)"
            fill="#fff"
            font-size="120"
            font-family="Roboto-Black, Roboto"
            font-weight="800"
            letter-spacing="0.05em"
          >
            <tspan x="0" y="0" text-anchor="middle">{{ length }}</tspan>
          </text>
          <text
            id="ПОМИДОРОВУ_ЮРИЮ_ЮРЬЕВИЧУ"
            data-name="ПОМИДОРОВУ ЮРИЮ ЮРЬЕВИЧУ"
            transform="matrix(1, 0, 0, 1, 1240.428, 1468.945)"
            fill="#fff"
            font-size="90"
            font-family="Roboto-Bold, Roboto"
            font-weight="700"
            letter-spacing="0.06em"
          >
            <tspan x="0" y="0" text-anchor="middle">
              {{ name }}
            </tspan>
          </text>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "vertical",
  props: {
    length: {
      type: String,
      default: ""
    },
    certId: {
      type: [Number, String],
      default: 1
    },
    whom: {
      type: String,
      default: "ПОМИДОРОВУ ЮРИЮ ЮРЬЕВИЧУ"
    },
    message: {
      type: String,
      default: ""
    },
    promo: {
      type: String,
      default: "ПРОМО"
    }
  },
  computed: {
    comment() {
      return this.message ? this.message.toUpperCase() : "";
    },
    name() {
      return this.whom ? this.whom.toUpperCase() : "";
    }
  },
  methods: {
    image(id) {
      return `https://storage.yandexcloud.net/thelak-storage/Certificates/vert/img${id}.jpg`;
    },
    copyText(e) {
      navigator.clipboard
        .writeText(e.target.innerHTML.trim())
        .then(() => {
          // todo show more beautiful popup
          alert("Промокод успешно скопирован!");
        })
        .catch(err => {
          alert("Произошла ошибка: " + err);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@media print {
  @page {
    size: portrait;
  }
}
</style>
