<template>
  <div
    class="tab-pane"
    role="tabpanel"
    v-show="isActive"
    :id="computedId"
    :aria-hidden="!isActive"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: "Tab",
  props: {
    id: {
      default: null
    },
    name: {
      required: true
    },
    prefix: {
      default: ""
    },
    suffix: {
      default: ""
    },
    isDisabled: {
      default: false
    },
    nameVisible: {
      default: true
    },
    pic: {
      default: ""
    },
    classWrapper: {
      default: "card-wrapper"
    }
  },
  data: () => ({
    isActive: false,
    isVisible: true
  }),
  computed: {
    header() {
      return this.prefix + this.name + this.suffix;
    },
    image() {
      return require("@/assets/img/" + this.pic + ".svg");
    },
    computedId() {
      return this.id ? this.id : this.name.toLowerCase().replace(/ /g, "-");
    },
    hash() {
      if (this.isDisabled) {
        return "#";
      }
      return "#" + this.computedId;
    }
  }
};
</script>

<style scoped></style>
