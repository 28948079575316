<template>
  <div class="wrapper-inner">
    <div class="inner-content">
      <a href="#" @click.prevent="$router.go(-1)" class="back-profile">
        <img src="@/assets/img/arrow-round-back.svg" alt="Back" /> Назад
      </a>
      <validation-observer ref="observer" tag="div" class="wrapper-promocode">
        <h2>
          Промокод
        </h2>
        <form class="wrapper-promocode-form" @submit.prevent="promoAccept">
          <div class="promocode-form-flex">
            <validation-provider
              name="промокод"
              ref="promo"
              tag="div"
              rules="required"
              v-slot="{ errors, reset }"
            >
              <input
                class="input-promocode"
                type="text"
                placeholder="Введите промокод"
                :class="{ 'error-input': errors.length }"
                v-model="formData.code"
                required
              />
              <error-pop-over :reset-function="reset" :errors="errors" />
            </validation-provider>
            <div>
              <input type="submit" class="submit-add" value="Активировать" />
            </div>
          </div>
          <p>
            Введите промокод для доступа к развивающему видеоконтенту в высоком
            качестве.
          </p>
        </form>
      </validation-observer>
      <modal-alert
        :alert-text="errorText"
        action-text="Продолжить"
        :action-modal="closeAlert"
        :show-modal.sync="showAlert"
      />
    </div>
  </div>
</template>

<script>
import ModalAlert from "@/components/page/modals/forms/ModalAlert";
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import ErrorPopOver from "@/components/page/items/error/ErrorPopOver";
extend("required", {
  ...required,
  message: 'Введите данные в поле "{_field_}"'
});
export default {
  name: "Promo",
  components: {
    ModalAlert,
    ValidationObserver,
    ValidationProvider,
    ErrorPopOver
  },
  data() {
    return {
      formData: {
        code: ""
      },
      errorText: "",
      showAlert: false,
      success: false
    };
  },
  methods: {
    promoAccept() {
      this.axios
        .post("/payments/promo/enter", null, {
          params: this.formData
        })
        .then(response => {
          const data = response.data;
          this.success = data.success;
          this.showAlert = true;
          this.errorText = this.success
            ? "Промокод успешно применен!"
            : "Неверный промокод!";
        })
        .catch(() => {
          this.applyFail(
            "Такого промокода не существует, попробуйте ввести другой"
          );
        });
    },
    applyFail(message) {
      this.$refs.promo.applyResult({
        errors: [].concat(message),
        valid: false,
        failedRules: {}
      });
    },
    closeAlert() {
      if (this.success) {
        this.$router.push("/profile").then(() => {
          location.reload();
        });
      } else this.showAlert = false;
    }
  }
};
</script>

<style scoped></style>
