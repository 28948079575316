<template>
  <div class="row">
    <div class="col-xl-12 col-lg-12 col-sm-12 w100 layout-spacing">
      <div class="widget-content widget-content-area">
        <h2>Сертификаты</h2>
        <table-view
          :heads="heads"
          :items.sync="certs"
          :fields="schema"
          edit-head="Редактировать сертификат"
          obtain-method="/certificate/get"
          update-method="/certificate/update"
          delete-method="/certificate/delete"
          @updated="getCerts"
          :form-options="formOptions"
        />
      </div>

      <div class="widget-content widget-content-area">
        <h2>Добавить сертификат</h2>
        <create-cert
          :schema="schema"
          :form-options="formOptions"
          @create="getCerts"
        />
      </div>

      <div class="widget-content widget-content-area">
        <h2>Разослать сертификат</h2>
        <send-cert :schema="schemaSend" :form-options="formOptions" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CreateCert from "@/views/admin/certs/CreateCert";
import TableView from "@/components/admin/lists/TableView";
import { validators } from "vue-form-generator";
import { uuid } from "vue-uuid";
import SendCert from "@/views/admin/certs/SendCert";
export default {
  name: "AdminCerts",
  components: { SendCert, TableView, CreateCert },
  data() {
    return {
      heads: {
        id: {
          name: "ID"
        },
        name: {
          name: "Название"
        },
        length: {
          name: "Длительность"
        }
      },
      schema: {
        groups: [
          {
            legend: "Длительность",
            fields: [
              {
                type: "input",
                inputType: "text",
                label: "Длительность (подпись)",
                model: "length",
                required: true,
                validator: validators.required,
                placeholder: "1 ГОД",
                id: uuid.v1() + "length"
              },
              {
                type: "input",
                inputType: "number",
                label: "Длительность в месяцах",
                validator: validators.required,
                model: "months",
                required: true,
                placeholder: "3",
                hint: "Число месяцев"
              }
            ]
          },
          {
            legend: "Стоимость",
            fields: [
              {
                type: "input",
                inputType: "number",
                label: "Стоимость",
                validator: validators.required,
                model: "price",
                required: true,
                placeholder: "999"
              },
              {
                type: "input",
                inputType: "text",
                label: "Подпись стоимости",
                model: "priceStr",
                validator: validators.required,
                required: true,
                placeholder: "999 р / 3 МЕСЯЦА",
                id: uuid.v1() + "priceStr"
              },
              {
                type: "input",
                inputType: "text",
                label: "Нижняя подпись стоимости",
                model: "priceStr2",
                placeholder: "МЕСЯЦ ЗА 200 и тд"
              }
            ]
          },
          {
            legend: "Стиль",
            fields: [
              {
                type: "textArea",
                label: "Описание",
                model: "description",
                required: true,
                validator: validators.required,
                placeholder: "1000+ часов видеоконтента<br />для развития"
              },
              {
                type: "input",
                inputType: "hidden",
                model: "name",
                default: "СЕРТИФИКАТ"
              },
              {
                type: "vueMultiSelect",
                model: "list",
                label: "Выгоды",
                placeholder: "Начните писать для поиска",
                required: true,
                validator: validators.required,
                selectOptions: {
                  multiple: true,
                  searchable: true,
                  taggable: true,
                  clearOnSelect: false,
                  closeOnSelect: false,
                  tagPlaceholder: "Добавьте эту выгоду",
                  onNewTag(newTag, id, options, value) {
                    options.push(newTag);
                    value.push(newTag);
                  }
                },
                onChanged: function(model, newVal) {
                  console.log("@tag: ", newVal);
                },
                values: [
                  "Более 1 000 часов бизнес-контента",
                  "Просмотр на любом устройстве",
                  "Лучшее качество записи",
                  "Отсутствие рекламы",
                  "Выгода по спецпредложению"
                ]
              },
              {
                type: "radios",
                label: "Обложка",
                model: "cover",
                validator: validators.required,
                required: true,
                values: [
                  { name: "Синяя", value: 1 },
                  { name: "Розовая", value: 2 },
                  { name: "Фиолетовая", value: 3 },
                  { name: "Голубая", value: 4 }
                ]
              }
            ]
          }
        ]
      },
      schemaSend: {
        groups: [
          {
            legend: "Основные",
            fields: [
              {
                type: "vueMultiSelect",
                model: "email",
                label: "Email для рассылки",
                required: true,
                placeholder: "Введите Email",
                hint:
                  "Можно выбрать как уже зарегистрированный Email, так и сторонний",
                validator: validators.required,
                selectOptions: {
                  multiple: true,
                  searchable: true,
                  internalSearch: false,
                  clearOnSelect: false,
                  closeOnSelect: false,
                  taggable: true,
                  tagPlaceholder: "Добавьте Email",
                  onNewTag(newTag, id, options, value) {
                    options.push(newTag);
                    value.push(newTag);
                  },
                  // eslint-disable-next-line no-unused-vars
                  onSearch: (searchQuery, id, options) => {
                    if (searchQuery.length === 0) {
                      options = [];
                    } else {
                      if (this.emailTimeout) {
                        clearTimeout(this.emailTimeout);
                      }
                      this.emailTimeout = setTimeout(() => {
                        this.loadFilteredUsers(
                          "usersForList",
                          searchQuery,
                          1,
                          30
                        ).then(data => {
                          options = data;
                        });
                      }, 1e3);
                    }
                  }
                },
                onChanged(model, newVal) {
                  model.email = newVal;
                },
                values: () => {
                  return this.usersForList;
                }
              },
              {
                type: "input",
                inputType: "text",
                model: "subject",
                label: "Тема рассылки",
                placeholder: "Подарочные серификаты",
                required: true,
                validator: validators.required,
                id: uuid.v1() + "subject"
              },
              {
                type: "select",
                label: "Вид",
                model: "id",
                required: true,
                values: () => {
                  return this.certs;
                },
                selectOptions: {
                  hideNoneSelectedText: true,
                  name: "length",
                  id: "id"
                },
                default: "1",
                validator: validators.required
              },
              {
                type: "vueMultiSelect",
                model: "type",
                label: "Тип",
                placeholder: "Горизонтальный или вертикальный",
                required: true,
                selectOptions: {
                  multiple: false,
                  searchable: true,
                  customLabel(label) {
                    switch (label) {
                      case "HORIZONTAL":
                        return "Горизонтальный";
                      case "VERTICAL":
                        return "Вертикальный";
                    }
                  }
                },
                values: ["HORIZONTAL", "VERTICAL"]
              },
              {
                type: "textArea",
                label: "Комментарий к сертификату",
                model: "description",
                required: true,
                hint: "Можно использовать HTML-теги",
                validator: validators.required,
                placeholder: "Лучшим друзьям!"
              }
            ]
          }
        ]
      },
      formOptions: {
        validateAfterLoad: false,
        validateAfterChanged: true,
        validateAsync: true
      }
    };
  },
  computed: {
    ...mapGetters(["certs", "usersForList"])
  },
  methods: {
    ...mapActions(["getCerts", "getFilteredUsers"]),
    loadFilteredUsers(type, search, page, size) {
      return this.getFilteredUsers({
        type,
        search,
        page,
        size
      });
    }
  },
  created() {
    this.getCerts();
  }
};
</script>

<style scoped></style>
