<template>
  <div :class="['b-slider-tags', isInner ? 'states-inner-tags' : '']">
    <router-link
      :to="{ name: 'MediaLibrary', params: { category: tag.id } }"
      v-for="tag in tags"
      v-bind:key="tag.id"
    >
      <span v-html="tag.title"></span>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "Tags",
  props: {
    tags: Array,
    isInner: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped></style>
